import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AppBarAndDrawer from "../AppBarAndDrawer/AppBarAndDrawer";
import AppHeader from "../Components/AppBar/AppBar";
import { useTheme } from "../theme";
import BlogContainer from "../Page/Blog/blogContainer";
import BlogList from "../Page/Blog/list";
import CourseContainer from "../Page/Course/courseContainer";
import CourseList from "../Page/Course/list";
import FacultyContainer from "../Page/Faculty/facultyContainer";
import FacultyList from "../Page/Faculty/list";
import NewsList from "../Page/News/list";
import NewsContainer from "../Page/News/newsContainer";
import PromoCodeForm from "../Page/PromoCode/form";
import PromoCodeList from "../Page/PromoCode/list";
import QuestionTagList from "../Page/QuestionTag/list";
import QuestionTagForm from "../Page/QuestionTag/form";
import ReferralCodeForm from "../Page/ReferralCode/form";
import ReferralCodeList from "../Page/ReferralCode/list";
import BundleList from "../Page/Bundle/list";
import BundleContainer from "../Page/Bundle/bundleContainer";
import PageNotFound from "../Page/PageNotFound";
import UserList from "../Page/User/list";
import UserContainer from "../Page/User/userContainer";
import OfferUserList from "../Page/invoice/list";
import OfferUserContainer from "../Page/invoice/offerContainer";
import OfferForm from "../Page/invoice/form";
interface props {}

const HomeRoutes: React.FC<props> = () => {
  const [currentTheme, setCurrentTheme] = useTheme();
  return (
    <div style={{ flex: 1 }}>
      {/* Dashboard */}
      <Switch>
        <Route exact path="/">
          <div>HOME</div>
        </Route>
        <Route exact path="/home">
          HOME
        </Route>
        {/* Course */}
        <Route path="/home/course" exact>
          <CourseList url="/home/course" />
        </Route>
        <Route path="/home/course/new" exact>
          <CourseContainer url="/home/course" />
        </Route>
        <Route path="/home/course/:id(\d+)" exact>
          <CourseContainer url="/home/course" />
        </Route>
        {/* BUNDLE */}
        <Route path="/home/bundle" exact>
          <BundleList url="/home/bundle" />
        </Route>
        <Route path="/home/bundle/new" exact>
          <BundleContainer url="/home/bundle" />
        </Route>
        <Route path="/home/bundle/:id(\d+)" exact>
          <BundleContainer url="/home/bundle" />
        </Route>
        {/* FACULTY */}
        <Route path="/home/faculty" exact>
          <FacultyList url="/home/faculty" />
        </Route>
        <Route path="/home/faculty/new" exact>
          <FacultyContainer url="/home/faculty/" />
        </Route>
        <Route path="/home/faculty/:id(\d+)">
          <FacultyContainer url="/home/faculty" />
        </Route>
        {/* NEWS */}
        <Route path="/home/news" exact>
          <NewsList url="/home/news" />
        </Route>
        <Route path="/home/news/new" exact>
          <NewsContainer url="/home/news" />
        </Route>
        <Route path="/home/news/:id(\d+)">
          <NewsContainer url="/home/news" />
        </Route>
        {/* BLOGS */}
        {/* <Route path="/home/blog">
                    <BlogPage url="/home/blog" />
                  </Route> */}
        <Route path="/home/blog" exact>
          <BlogList url="/home/blog" />
        </Route>
        <Route path="/home/blog/new" exact>
          <BlogContainer url="/home/blog" />
        </Route>
        <Route path="/home/blog/:id(\d+)">
          <BlogContainer url="/home/blog" />
        </Route>
        {/* REPORT */}
        <Route path="/home/report">REPORT</Route>
        {/* APPROVAL */}
        <Route path="/home/approval">APPROVAL</Route>
        {/* REFERRAL CODE */}
        <Route path="/home/referral" exact>
          <ReferralCodeList url="/home/referral" />
        </Route>
        <Route path="/home/referralCode" exact>
          <ReferralCodeList url="/home/referralCode" />
        </Route>
        <Route path="/home/referralCode/new" exact>
          <ReferralCodeForm url="/home/referralCode" />
        </Route>
        <Route path="/home/referralCode/:id(\d+)">
          <ReferralCodeForm url="/home/referralCode" />
        </Route>
        {/* PROMO CODE(COUPON CODE) */}
        <Route path="/home/promoCode" exact>
          <PromoCodeList url="/home/promoCode" />
        </Route>
        <Route path="/home/promoCode/new" exact>
          <PromoCodeForm url="/home/promoCode/new" />
        </Route>
        <Route path="/home/promoCode/:id(\d+)">
          <PromoCodeForm url="/home/promoCode" />
        </Route>
        {/* QUESTION TAG */}
        <Route path="/home/questionTag" exact>
          <QuestionTagList url="/home/questionTag" />
        </Route>
        <Route path="/home/questionTag/new" exact>
          <QuestionTagForm url="/home/questionTag" />
        </Route>
        <Route path="/home/questionTag/:id(\d+)">
          <QuestionTagForm url="/home/questionTag" />
        </Route>
        <Route path="/home/user" exact>
          <UserList url="/home/user" />
        </Route>
        <Route path="/home/user/new" exact>
          <UserContainer url="/home/user" />
        </Route>
        <Route path="/home/user/:id(\d+)">
          <UserContainer url="/home/user" />
        </Route>

        {/* Offer banner */}

        <Route path="/home/invoice" exact>
          <OfferForm url="/home/invoice" />
        </Route>
        <Route path="/home/invoice/new" exact>
          <OfferUserContainer url="/home/invoice" />
        </Route>
        <Route path="/home/invoice/:id(\d+)">
          <OfferUserContainer url="/home/invoice" />
        </Route>
        <Route exact={true} path="*" component={PageNotFound} />

        {/* <Redirect path="/home" /> */}
      </Switch>
    </div>
  );
};
export default HomeRoutes;
