import React, { useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import HeaderTab from "../../Components/Tab/HeaderTab";
import FooterButton from "../../Components/Footer/FooterButton";
import BundleForm from "./form";
import ListOfCourse from "./listOfCourse";
import UploadBundleImage from "./bundleImage";

interface Props {
  url?: any;
}

const BundleContainer: React.FC<Props> = () => {
  let [selectableTab, setSelectableTab] = useState(0);
  let [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (value) => {
    setActiveTab(value);
    setSelectableTab(value);
  };

  let tabs = [
    {
      label: "Bundle Form",
      value: 0,
    },
    {
      label: "Bundle Image",
      value: 1,
    },
    {
      label: "List Of Course",
      value: 2,
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <BundleForm />;
      case 1:
        return <UploadBundleImage/>
      case 2:
        return <ListOfCourse />;
      default:
        return <></>;
    }
  };

  return (
    <DivWrapper>
      <HeaderTab
        screen="bundle"
        tabs={tabs}
        activeTab={activeTab}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
      <div style={{ flex: 1 }}>{renderTabContent()}</div>
      <FooterButton
        screen="bundle"
        activeTab={activeTab}
        tabsLength={tabs?.length}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
    </DivWrapper>
  );
};

export default BundleContainer;
