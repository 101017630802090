import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import { SubmitBtn } from "../../Components/Card/Card";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import { enableHeaderTab } from "../../redux/action/configAction";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import {
  getInvoiceByCustomFilter,
  getInvoiceDetails,
  saveInvoice,
} from "../../redux/action/invoiceAction";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { getApiHeader } from "../../helper/getApiHeader";
import { blogLoader } from "../../redux/action/blogAction";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
  },
  input: {},
  submit: {
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  form: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
}));

interface props {
  url?: any;
  invoiceReducer?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
}
const initialState: any = {
  invoiceId: 0,

  startDate: null,
  endDate: null,
  isDisabled: "N",
};

const OfferForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);
  let [togleEvent, setEvent] = useState(0);
  let [editorState, setEditorState] = useState<any>(initialState);
  const [LoadingMessage, setLoadingMessage] = useState("");
  const [fetchLoader, setFetchSpinner] = useState(false);
  const { invoiceLoader } = useSelector((state: any) => state.loaderReducer);

  let { id } = useParams();
  const { invoiceDetails, currentPage } = useSelector(
    (state: props) => state.invoiceReducer
  );

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }
    addInvoiceData();
  };

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_INVOICE_DETAILS_BY_ID}`;
      dispatch(getInvoiceDetails(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      if (invoiceDetails && id) {
        let data = invoiceDetails;
        setInputField({
          invoiceId: id ? id : 0,
          startDate: data.startDate,
          endDate: data.endDate,
          isDisabled: data.isDisabled,
        });
      }
    }
  }, [id, invoiceDetails]);

  const validationSchema = yup.object({
    startDate: yup
      .string()
      .matches(/^[a-zA-Z0-9-]*$/, "Only letters, numbers, and - are allowed")
      .required("Please Enter Start Date!")
      .strict(false)
      .trim(),
  });

  const addInvoiceData = async () => {
    setLoading(true);
    setEvent(togleEvent + 1);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    if (!inputField.startDate || !inputField.endDate) {
      Snackbar.show("Please select both start and end dates.", "error");
      return;
    }
    const formattedEndDate = inputField.endDate
      ? format(inputField.endDate, "yyyy-MM-dd")
      : "";
    const formattedStartDate = inputField.endDate
      ? format(inputField.startDate, "yyyy-MM-dd")
      : "";
    const invoiceData = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    let uri = `${apiEndPoint.SAVE_INVOICE}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const headerData = getApiHeader();
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      setFetchSpinner(true);
      setLoadingMessage("Fetching data please wait for a moment...");
      const response = await fetch(API_URL + uri, {
        method: "post",
        headers: { ...headerData },
      });

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const contentDisposition = response.headers.get("content-disposition");
      const filename = "invoices.zip";
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      setFetchSpinner(false);
      setLoadingMessage("");
      Snackbar.show("Download failed:", "error");
    } finally {
      // Hide the fetch spinner
      setFetchSpinner(false);
      setLoadingMessage("");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputField,
    // validationSchema,
    onSubmit,
  });

  const handleInput = (name: any, value: any) => {
    setInputField((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };
  const classes = useStyles();

  const handleStartDateChange = (date: Date | null) => {
    setInputField((prevRange) => ({
      ...prevRange,
      startDate: date,
      endDate: null, // Reset the end date when the start date changes
    }));
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const startDate = inputField.startDate;
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate() + 0
      );

      if (date > endDate) {
        Snackbar.show("Date Range Should Be One Month.", "error");
        setInputField((prevRange) => ({
          ...prevRange,
          endDate: null,
        }));
      } else if (date < startDate) {
        Snackbar.show("please select greater than start Date.", "error");
        setInputField((prevRange) => ({
          ...prevRange,
          endDate: null,
        }));
      }else {
        setInputField((prevRange) => ({
          ...prevRange,
          endDate: date,
        }));
      }
    } else {
      setInputField((prevRange) => ({
        ...prevRange,
        endDate: null,
      }));
    }
  };

  const handleDisabled = (field: any, e: any) => {
    const bool = e.target.checked ? "Y" : "N";
    setInputField((prval) => {
      return {
        ...prval,
        [field]: bool,
      };
    });
  };

  const fetchOfferList = (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_INVOICE_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "startDate",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    dispatch(getInvoiceByCustomFilter(uri, args, searchText));
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };
  return (
    <div>
      <Grid className={classes.root}>
        <form>
          {/* <CustomSwitch
            field="isDisabled"
            handleStateChange={handleDisabled}
            checked={inputField?.isDisabled === "N" ? false : true}
            label="Disabled"
          /> */}
          <div style={{ marginTop: "70px" }}></div>
          <DatePicker
            fullWidth
            format="dd/MM/yyyy"
            label="Select Start Date"
            value={inputField.startDate}
            onChange={handleStartDateChange}
            error={formik.errors.startDate}
            helperText={formik.errors.startDate}
            maxDate={new Date()}
          />
          <div style={{ marginBottom: "30px" }}></div>
          <DatePicker
            fullWidth
            format="dd/MM/yyyy"
            label="Slect End Date"
            value={inputField.endDate}
            onChange={handleEndDateChange}
            error={formik.errors.endDate}
            helperText={formik.errors.endDate}
            maxDate={new Date()}
          />

          <SubmitBtn
            disabled={invoiceLoader || isLoading}
            disabledColor={invoiceLoader}
            style={{ marginTop: "20px", marginLeft: "30em" }}
            type="submit"
            onClick={addInvoiceData}
          >
            Submit
          </SubmitBtn>
          {fetchLoader && (
            <div
              style={{
                marginTop: "22px",
                marginLeft: "-6em",
                textAlign: "center",
                color: "green",
                fontSize: "1rem",
              }}
            >
              {LoadingMessage}
            </div>
          )}
          {/* {id ? (
            <Grid>
              <SubmitBtn
                disabled={formLoader}
                disabledColor={formLoader}
                onClick={formik.handleSubmit}
              >
                Update
              </SubmitBtn>
            </Grid>
          ) : null} */}
        </form>
      </Grid>
    </div>
  );
};
export default OfferForm;
