import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 20,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  root: {
    display: "flex",
    alignItems: "center",
  },

  input: {},
}));

interface Props {
  label: string;
  value: any;
  field?: string;
  handleState?: any;
  error?: any;
  helperText?: any;
  otherProps?: any;
  style?: any;
  variant?: any;
  className?: any;
  fullWidth?: any;
  margin?: any;
  required?: any;
  id: any;
  name: string;
  autoFocus?: any;
  disabled?: boolean;
  type?: any;
  multiline?: any;
  rows?: any;
  endAdornment?: any;
  onInput?: any;
  onBlur?: any;
}
const CustomInput: React.FC<Props> = (props) => {
  const classes = useStyles();
  let {
    label,
    value,
    error,
    helperText,
    otherProps,
    style,
    disabled,
    className,
    variant,
    fullWidth,
    margin,
    type,
    id,
    name,
    autoFocus,
    multiline,
    rows,
    endAdornment,
    onInput,
    onBlur,
    required = true,
  } = props;

  const handleState = (e: any) => {
    props.handleState(name, e.target.value);
  };
  return (
    <TextField
      variant={variant ? variant : "outlined"}
      className={className ? className : classes.input}
      fullWidth={fullWidth}
      margin={margin ? margin : "normal"}
      required={required}
      id={id}
      label={label}
      name={name}
      type={type}
      multiline={multiline}
      disabled={disabled}
      value={value}
      rows={rows}
      autoComplete="title"
      onChange={handleState}
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      style={style}
      onInput={onInput}
      onBlur={onBlur}
      InputProps={{
        endAdornment,
      }}
      {...otherProps}
    />
  );
};

export default CustomInput;
