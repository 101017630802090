export const routesArray = [
  {
    key: "HOME",
    title: "Home",
    icon: "Home",
    path: "/home",
  },
  {
    key: "COURSE",
    title: "Course",
    iconName: "Course",
    path: "/home/course",
  },
  {
    key: "FACULTY",
    title: "Faculty",
    iconName: "Faculty",
    path: "/home/faculty",
  },
  {
    key: "NEWS",
    title: "News",
    iconName: "News",
    path: "/home/news",
  },
  {
    key: "BLOG",
    title: "Blogs",
    iconName: "Blog",
    path: "/home/blog",
  },
  {
    key: "BUNDLE",
    title: "Bundles",
    iconName: "Bundle",
    path: "/home/bundle",
  },
  {
    key: "REFERRAL_CODE",
    title: "Referral Codes",
    iconName: "ReferralCode",
    path: "/home/referralCode",
  },
  {
    key: "PROMO_CODE",
    title: "Promo Codes",
    iconName: "PromoCode",
    path: "/home/promoCode",
  },
  {
    key: "INVOICE",
    title: "Invoice",
    iconName: "invoice",
    path: "/home/invoice",
  },
  {
    key: "QUESTION_TAG",
    title: "Question Tags",
    iconName: "QuestionTag",
    path: "/home/questionTag",
  },
  {
    key: "USER",
    title: "Users",
    iconName: "User",
    path: "/home/user",
  },
  {
    key: "REPORT",
    title: "Report",
    iconName: "Report",
    path: "/home/report",
  },
];
