import React from "react";
//import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { NextBtn } from "../Card/Card";

const useStyles = makeStyles((theme) => ({
  prebtn: {
    marginRight: "20px",
  },
}));
interface Props {
  screen: string;
  activeTab?: any;
  selectableTab?: any;
  handleChangeTab?: any;
  tabsLength?: any;
  configReducer?: any;
  courseReducer?: any;
}

const FooterButton: React.FC<Props> = (props) => {
  let { screen } = props;
  const classes = useStyles();
  let { activeTab, selectableTab, tabsLength } = props;
  const { overViewVideos } = useSelector((state: Props) => state.courseReducer);
  let enableTab = screen === "course" && !overViewVideos;

  let showPrevButton = activeTab > 0;
  let showNextButton = activeTab < tabsLength - 1;
  let clickable = selectableTab > activeTab;

  const handlePrevious = () => {
    props.handleChangeTab(activeTab - 1);
  };

  const handleNext = () => {
    props.handleChangeTab(activeTab + 1);
  };

  let nextButtonDisabled = enableTab && activeTab === 1;
  return (
    <div>
      <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}>
        {showPrevButton ? (
          // <Button

          //   // variant="contained"
          //   // font-weight="bold"
          //   color="primary"
          //   onClick={handlePrevious}
          //   className={classes.prebtn}
          // >
          //   Previous
          // </Button>
          <NextBtn onClick={handlePrevious}> Previous</NextBtn>
        ) : null}
        {showNextButton ? (
          // <Button
          //   // disabled={nextButtonDisabled}
          //   // variant="contained"
          //   // font-weight="bold"
          //   color={clickable ? "primary" : "secondary"}
          //   onClick={handleNext}
          // >
          //   Next
          // </Button>
          <NextBtn onClick={handleNext}> Next</NextBtn>
        ) : null}
      </div>
    </div>
  );
};
export default FooterButton;
