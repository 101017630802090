import React from "react";
import InputLabel from "@mui/material/InputLabel";

interface Props {
  label: string;
  style?: any;
}

const CustomLabel: React.FC<Props> = (props) => {
  let { label } = props;
  return (
    <InputLabel id="demo-simple-select-label" style={{ fontFamily: "Oxanium" }} {...props}>
      {label}
    </InputLabel>
  );
};

export default CustomLabel;
