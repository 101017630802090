import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ImagePicker from "../../Components/FilePicker/ImagePicker";
import { useParams } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitBtn, BtnSection } from "../../Components/Card/Card";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import { getbBundleById, uploadBundlePic } from "../../redux/action/courseAction";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  url?: any;
  courseReducer?: any;
}
const initialState: any = {
  bundleImagePath: "",
};

const UploadBundleImage: React.FC<Props> = () => {
  const Snackbar = useSnackbar();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);
  let [updateBundle, setUpdateBundle] = useState(false);
  let [imagePreview, setImagePreview] = useState<any>(null);
  let [imagePath, setImagePath] = useState(null);
  const classes = useStyles();
  let { bundleDetails } = useSelector((state: Props) => state.courseReducer);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateBundle) {
      createProfilePic();
    }
  };

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BUNDLE_BY_ID}?courseBundleId=${id}`;
      dispatch(getbBundleById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (bundleDetails && id) {
      let data = bundleDetails;
      setInputField({
        bundleImagePath: data.bundleImagePath,
      });
      setImagePreview(data?.bundleImagePath);
      let path = data?.bundleImagePath?.split("/");
      path = path ? path[path.length - 1] : "";
      setImagePath(path);
    }
  }, [bundleDetails, id]);
  const handleProfileImage = (e) => {
    setUpdateBundle(true);
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImagePath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputField({ ...inputField, bundleImagePath: formData });
    }
  };

  const createProfilePic = async () => {
    let bundleImagePath = inputField.bundleImagePath;

    if (bundleImagePath) {
      let uri = `${apiEndPoint.UPLOAD_BUNDLE_IMAGE}=${id}`;
      let response: any = await dispatch(uploadBundlePic(uri, bundleImagePath));
      let { status, result } = response;
      if (status) {
        Snackbar.show("Image Uploaded Successfully!", "success");
      } else {
        Snackbar.show(result, "error");
      }
    }
  };

  return (
    <div>
      <form>
        <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
          <div style={{ height: 300, width: "50%", display: "flex", flex: 1 }}>
            <ImagePicker
              handlePickImage={handleProfileImage}
              image={imagePreview}
              imagePath={imagePath}
              buttonName="Upload Bundle Pic"
            />
          </div>
        </Grid>
        <Grid>
          <BtnSection>
            <SubmitBtn onClick={handleSubmit}>Update</SubmitBtn>
          </BtnSection>
        </Grid>
      </form>
      <FormLoader visible={formLoader} />
    </div>
  );
};

export default UploadBundleImage;
