
 import "../Page/Faculty/form.css";

function PageNotFound() {
  return (
    <div className="error-page">
      <header className="error-header" style={{}}>
        <h1>404</h1>
        <h3>Not Found</h3>
        <p>The resource requested could not be found on this server!</p>
      </header>
    </div>
  );
}
export default PageNotFound;
