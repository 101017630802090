import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initFaculty() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_FACULTY,
    });
  };
}

export function saveFaculty(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_FACULTY,
      payload: data,
    };
  }
}

export function getFacultyById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyFormLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_FACULTY_BY_ID,
      payload: data,
    };
  }
}

export function getFacultyAll(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(facultyLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(facultyLoader(false));
        }
      } else {
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(facultyLoader(false));
        }
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.FACULTY_ALL,
      payload: data,
    };
  }
}
export function uploadFacultyProfilePic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(success(data));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD__FACULTY_PROFILE_PIC,
      payload: data,
    };
  }
}

export function uploadFacultyBannerPic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(success(response));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD__FACULTY_BANNER_PIC,
      payload: data,
    };
  }
}

export function getUserDetailsById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      dispatch(facultyFormLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_USER_DETAILS_BY_ID,
      payload: data,
    };
  }
}

export function saveUserDetails(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(facultyFormLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(facultyFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(facultyFormLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(facultyFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_USER_DETAILS,
      payload: data,
    };
  }
}

export function deleteFacultyById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_FACULTY_BY_ID,
      payload: data,
    };
  }
}

export function setFacultyList(data: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.SET_FACULTY_LIST,
      payload: data,
    });
  };
}

export function facultyLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FACULTY_LOADER,
      payload: flag,
    });
  };
}

export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
export function facultyFormLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
