import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import DataTable from "../../Components/Table/dataTable";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import {
  getReferralCodeAll,
  deleteReferralCodeById,
  initReferralCode,
  getReferralCodeByCustomFilter,
} from "../../redux/action/referralCodeAction";
import AlertMessage from "../../Components/Alert/AlertMessage";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import FormLoader from "../../Components/Loader/formLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
}));

interface props {
  url?: any;
  referralCodeReducer?: any;
  loaderReducer?: any;
}

const ReferralCodeList: React.FC<props> = (props) => {
  let { url } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  let [visibleAlert, setVisibleAlert] = useState(false);
  let [deleteId, setDeleteId] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<any>("");
  let { referralCodes, totalItems, currentPage } = useSelector((state: props) => state.referralCodeReducer);
  let { contentLoader } = useSelector((state: props) => state.loaderReducer);

  if (referralCodes && referralCodes.length) {
    referralCodes = referralCodes.map((item: any) => {
      return { ...item, id: item.tdcReferralCodeId };
    });
  }

  // useEffect(() => {
  //   let uri = `${apiEndPoint.GET_REFERRAL_CODE_ALL}`;
  //   dispatch(getReferralCodeAll(uri));
  // }, [dispatch]);

  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_REFERRAL_CODE_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getReferralCodeByCustomFilter(uri, args, searchText));
  };

  const initReferralFunction = async (flag) => {
    await dispatch(initReferralCode());
    dispatch(enableHeaderTab(flag));
  };

  const handleEdit = (Id: any) => {
    initReferralFunction(true);
    let Title = Id.title;
    dispatch(HeaderBreadCrumbTitle(Title));
    const refId = Id.tdcReferralCodeId;
    history.push(`${url}/${refId}`);
  };

  const handleDelete = (selectItem: any) => {
    setDeleteId(selectItem.tdcReferralCodeId);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handlePage = (page) => {
    getList(page, search);
    // setPage(page);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_NEWS_HERE";
    dispatch(deleteReferralCodeById(uri, deleteId));
    setVisibleAlert(false);
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete News"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 0.8,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 0.9,
      renderCell: (cellValues) => {
        return <span dangerouslySetInnerHTML={{ __html: cellValues.value }} style={{ maxHeight: "79px" }} />;
      },
    },
    {
      field: "referralCode",
      headerName: "Code",
      flex: 0.4,
    },
    {
      field: "disabled",
      headerName: "Disabled",
      flex: 0.4,
    },
  ];

  // let sliceData = referralCodes.slice(10 * page);
  const renderTable = () => {
    return (
      <div>
        <DataTable
          page={currentPage}
          data={referralCodes}
          totalItems={totalItems}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePage={handlePage}
        />
      </div>
    );
  };
  return (
    <>
      {contentLoader && <FormLoader visible={contentLoader} />}
      {!contentLoader && (
        <DivWrapper>
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.root}
          >
            <h1>Referral Codes</h1>
            <CustomizedDialogs
              customeIcon={"add"}
              page={"refferalCode"}
              title={"Add New Referral Code"}
              rowData={"null"}
              label="Referral Code"
            ></CustomizedDialogs>
          </Grid>
          {renderTable()}
          {renderAlert()}
        </DivWrapper>
      )}
    </>
  );
};

export default ReferralCodeList;
