import React from "react";
import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
interface Props {
  title: string;
  list: any;
  selectedList: any;
  handleCancel: any;
  handleConfirm: any;
  selectedCheckBox: any;
  courseIndex: any;
  handlePriority?: boolean;
}

const SelectionList: React.FC<Props> = (props) => {
  let { title, list, selectedList, handlePriority } = props;

  const handleConfirm = () => {
    props.handleConfirm();
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const selectedCheckBoxCourse = (event, item) => {
    props.selectedCheckBox(event, item);
  };
  const courseIndex = (event, item) => {
    props.courseIndex(event, item);
  };
  // if (list && list.length) {
  //   list.sort((a, b) => (a.courseIndex > b.courseIndex ? 1 : -1));
  // }
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ maxHeight: 300, flex: 1, padding: 5, overflowY: "scroll" }}>
              {list?.map((item: any, i) => (
                <div key={i} style={{ padding: 5 }}>
                  {handlePriority ? (
                    <input
                      type="number"
                      id={`priority-${i}`}
                      placeholder={"priority"}
                      onChange={(event) => courseIndex(event, item)}
                      value={item.checked ? item.courseIndex : null}
                    />
                  ) : null}
                  <input
                    type="checkbox"
                    id={`checkbox-${i}`}
                    checked={item.checked}
                    value={item.checkboxValue}
                    onChange={(event) => selectedCheckBoxCourse(event, item)}
                  />
                  <FormLabel htmlFor={`checkbox-${i}`}>{item.label}</FormLabel>
                </div>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        {selectedList?.length ? (
          <div style={{ marginLeft: 20 }}>{`Total ${selectedList.length} Item${
            selectedList.length > 1 ? "s" : ""
          } Selected`}</div>
        ) : null}
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectionList;
