import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import SearchInput from "../../Components/Input/SearchInput";

import {
  deletePromoCode,
  getPromoCodeAll,
  getPromoCodeByCustomFilter,
  setSelectedPromoCode,
} from "../../redux/action/promoCodeAction";
import DataTable from "../../Components/Table/dataTable";
import { Grid } from "@material-ui/core";
import { initPromoCode } from "../../redux/action/promoCodeAction";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import AlertMessage from "../../Components/Alert/AlertMessage";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import FormLoader from "../../Components/Loader/formLoader";
let searchTimer;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 50,
    marginLeft: 100,
    marginRight: 100,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  root: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",

    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
}));

interface Props {
  url?: any;
  promoCodeReducer?: any;
  loaderReducer?: any;
}

const PromoCodeList: React.FC<Props> = (props) => {
  const classes = useStyles();
  let { url } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<any>("");

  let { promoCodeList, promoCode, totalItems, currentPage } = useSelector((state: Props) => state.promoCodeReducer);
  let { promoCodeLoader } = useSelector((state: Props) => state.loaderReducer);

  if (promoCodeList && promoCodeList.length) {
    promoCodeList = promoCodeList.map((item) => {
      return { ...item, id: item.courseCouponId };
    });
  }
  
  // useEffect(() => {
  //   let uri = `${apiEndPoint.GET_PROMO_CODE_ALL}`;
  //   dispatch(getPromoCodeAll(uri));
  // }, [dispatch]);
  
  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };
  


  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_PROMO_CODE_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}`],
          },
        ],
      };
    }
    await dispatch(getPromoCodeByCustomFilter(uri, args, searchText));
  };

  const handleEdit = (selectedItem) => {
    handleInitPromoCode(true);
    let Title = selectedItem.couponTitle;
    dispatch(HeaderBreadCrumbTitle(Title));
    history.push(`${url}/${selectedItem.id}`);
  };

  const handlePage = (page) => {
    // setPage(page);
    getList(page, search);
  };

  const handleInitPromoCode = (flag) => {
    dispatch(initPromoCode());
    dispatch(enableHeaderTab(flag));
  };

  const handleDelete = (selectedItem) => {
    dispatch(setSelectedPromoCode(selectedItem));
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_PROMO_CODE_HERE";
    dispatch(deletePromoCode(uri, promoCode));
    setVisibleAlert(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "couponTitle",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "couponCode",
      headerName: "Code",
      flex: 0.5,
    },
    {
      field: "couponValue",
      headerName: "Value",
      flex: 0.5,
    },
    {
      field: "disabled",
      headerName: "Disabled",
      flex: 0.5,
    },
  ];
  
  // let sliceData = promoCodeList.slice(10 * page);

  const renderTable = () => {
    return (
      <DataTable
        page={currentPage}
        totalItems={totalItems}
        data={promoCodeList}
        columns={columns}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handlePage={handlePage}
      />
    );
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete Promo Code"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const renderNotFound = () => {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  };
  return (
    <>
      {promoCodeLoader && <FormLoader visible={promoCodeLoader} />}
      {!promoCodeLoader && (
        <DivWrapper>
          <Grid
            container
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.root}
          >
            <h1>Promo Codes</h1>
            <SearchInput   handleSearch={handleSearch} search={search} />
            <CustomizedDialogs
              customeIcon={"add"}
              page={"promoCode"}
              title={"Add New Promo Code"}
              rowData={null}
              label="Promo Code"
             
            ></CustomizedDialogs>
          </Grid>
          {promoCodeList && promoCodeList.length >= 1 ? renderTable() : renderNotFound()}
          {renderAlert()}
        </DivWrapper>
      )}
    </>
  );
};

export default PromoCodeList;
