import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useFormik } from "formik";
import CustomInput from "../../Components/Input/CustomInput";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import { USER_EMAIL_ID_LENGTH, USER_FIRST_NAME_LENGTH, USER_LAST_NAME_LENGTH } from "../../config/textLength";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import { getUserByCustomFilter, getUserById, saveUser } from "../../redux/action/b2bUserAction";
import { enableHeaderTab } from "../../redux/action/configAction";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import CustomPhoneInput from "../../Components/Input/CustomPhoneInput";
import FormLoader from "../../Components/Loader/formLoader";
import CustomSwitch from "../../Components/Switches/CustomSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
  },
  input: {
    marginTop: 30,
  },
  form: {
    width: "100%",
  },
  phoneInput: {
    margin: 0,
    position: "absolute",
    top: "-11px",
    fontSize: 12,
    zIndex: 1,
    marginLeft: 15,
    opacity: "100%",
    color: "#707071",
    backgroundColor: "#FFFF",
    padding: "0px 5px",
  },
  inputBorder: {
    "&:hover,&:focus": {
      border: "2px solid #01398c !important",
    },
  },
}));

interface props {
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  url?: any;
  userReducer?: any;
}

const initialState: any = {
  isDisabled: "N",
  tdcuserId: 0,
  firstName: "",
  lastName: "",
  emailId: "",
  password: "",
  phone: "",
  b2bflag: "N",
};

const UserForm: React.FC<props> = (props) => {
  const dispatch = useDispatch();
  const classes: any = useStyles();
  const Snackbar = useSnackbar();
  const history = useHistory();
  const [inputField, setInputField] = useState(initialState);
  const { user, currentPage } = useSelector((state: props) => state.userReducer);
  const [phoneError, setPhoneError] = useState("");
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let { id } = useParams();

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .strict(false)
      .matches(/^[a-zA-Z\s]+$/, "Numericals and Special Characters are not Allowed")
      .trim()
      .required("Please Enter First Name!")
      .test(
        "len",
        `Characters Length should  not be greater than ${USER_FIRST_NAME_LENGTH}`,
        (val: any) => val?.toString().length <= USER_FIRST_NAME_LENGTH
      ),
    lastName: yup
      .string()
      .strict(false)
      .matches(/^[a-zA-Z\s]+$/, "Numericals and Special Characters are not Allowed")
      .trim()
      .required("Please Enter Last Name!")
      .test(
        "len",
        `Characters Length should not be greater than ${USER_LAST_NAME_LENGTH}`,
        (val: any) => val?.toString().length <= USER_LAST_NAME_LENGTH
      ),
    emailId: yup
      .string()
      .strict(false)
      .trim()
      .email("Please Enter Valid Email Format!")
      .required("Please Enter Email!")
      .test(
        "len",
        `Characters Length should not be greater than ${USER_EMAIL_ID_LENGTH}`,
        (val: any) => val?.toString().length <= USER_EMAIL_ID_LENGTH
      ),
    password: yup.string().required("Please Enter Password!"),
  });

  const removeSpecialCharacterInPhone = (value) => {
    if (value) {
      return value
        .replace(/ /g, "")
        .replace(/-/g, "")
        .replace(/[\(\)']+/g, "");
    }
    return "";
  };

  const handlePhoneNumber = (target: any, regex: string, isValidPhone: any) => {
    let { name, value } = target;
    if (!name && !value) {
      return false;
    }

    setInputField((prevData) => {
      return { ...prevData, [name]: "+" + value };
    });

    value = removeSpecialCharacterInPhone(value);
    if (!new RegExp(regex).test(value)) {
      setPhoneError("Please Enter Valid Phone Number!");
    } else {
      setPhoneError("");
    }
  };

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }
    addUserData();
  };

  const addUserData = async () => {
    const userData = {
      tdcuserId: id ? id : 0,
      firstName: inputField.firstName,
      lastName: inputField.lastName,
      emailId: inputField.emailId,
      password: inputField.password,
      isDisabled: inputField.isDisabled,
      phone: inputField.phone,
      b2bflag: inputField.b2bflag,
    };

    if (id) {
      // update existing user
      let uri = `${apiEndPoint.UPDATE_USER}`;
      let response: any = await dispatch(saveUser(uri, userData));
      if (response.status) {
        if (id) {
          history.push("/home/user");
        } else {
          props.closeModal(false);
        }
        Snackbar.show("Update Successfully", "success");
        setTimeout(() => {
          if (!id) {
            if (currentPage == 0) {
              fetchUserList(currentPage);
            }
          }
          dispatch(enableHeaderTab(true));
        }, 500);
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    } else {
      // create new user

      let uri = `${apiEndPoint.SIGN_UP_USER}`;
      let response: any = await dispatch(saveUser(uri, userData));
      if (response.status) {
        if (id) {
          history.push("/home/user");
        } else {
          props.closeModal(false);
        }
        Snackbar.show("Save Successfully", "success");
        setTimeout(() => {
          if (!id) {
            if (currentPage == 0) {
              fetchUserList(currentPage);
            }
          }
          dispatch(enableHeaderTab(true));
        }, 500);
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    }
  };

  const fetchUserList = (pageNumber) => {
    let uri = `${apiEndPoint.GET_ALL_USERS}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };

    dispatch(getUserByCustomFilter(uri, args, ""));
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputField,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_USER_DETAILS}=${id}`;
      dispatch(getUserById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (user && user[0] && id) {
      let { emailId, firstName, isDisabled, lastName, phone, tdcuserId, b2bflag } = user[0];
      setInputField({
        isDisabled,
        tdcuserId,
        firstName,
        lastName,
        emailId,
        password: "*****",
        phone,
        b2bflag,
      });
    }
  }, [user, id]);

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
  }, [props.togleEvent, props.isModal]);

  const handleInput = (name: any, value: any) => {
    setInputField((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  const handleToggeled = (field: any, e: any) => {
    const bool = e.target.checked ? "Y" : "N";
    setInputField((prval) => {
      return {
        ...prval,
        [field]: bool,
      };
    });
  };

  return (
    <>
      {formLoader && <FormLoader visible={formLoader} />}
      <form>
        <div
          style={{
            flex: 1,
            flexDirection: "row",
            display: "grid",
            gap: 20,
            marginTop: 20,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
        >
          <CustomSwitch
            field="isDisabled"
            handleStateChange={handleToggeled}
            checked={inputField?.isDisabled === "N" ? false : true}
            label="Disabled"
          />
          <CustomSwitch
            field="b2bflag"
            handleStateChange={handleToggeled}
            checked={inputField?.b2bflag === "N" ? false : true}
            label="b2bflag"
          />
        </div>
        <CustomInput
          id="firstName"
          variant="outlined"
          fullWidth
          className={classes.input}
          label="Enter First Name"
          name="firstName"
          value={inputField.firstName}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.firstName}
          helperText={formik.errors.firstName}
        />

        <CustomInput
          id="lastName"
          variant="outlined"
          fullWidth
          className={classes.input}
          label="Enter Last Name"
          name="lastName"
          value={inputField.lastName}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.lastName}
          helperText={formik.errors.lastName}
        />

        <CustomInput
          id="emailId"
          variant="outlined"
          className={classes.input}
          disabled={id ? true : false}
          fullWidth
          label="Enter Email"
          name="emailId"
          value={inputField.emailId}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.emailId}
          helperText={formik.errors.emailId}
        />
        <CustomInput
          id="password"
          className={classes.input}
          fullWidth
          label="Enter Password"
          name="password"
          type={"password"}
          value={inputField.password}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.password}
          helperText={formik.errors.password}
        />
        <div style={{ position: "relative" }}>
          <label className={classes.phoneInput}>Phone Number *</label>
          <CustomPhoneInput handlePhoneInput={handlePhoneNumber} phoneNumber={inputField?.phone} name={"phone"} />
          <div style={{ color: "red", fontSize: 12 }}>{phoneError}</div>
        </div>
        {id ? (
          <BtnSection>
            <SubmitBtn onClick={formik.handleSubmit}>Update</SubmitBtn>
          </BtnSection>
        ) : null}
      </form>
    </>
  );
};

export default UserForm;
