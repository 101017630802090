import React from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import HeaderBreadCrumb from "./HeaderBreadCrumb";
import { userAuthentication } from "../../redux/action/authAction";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IconButton, MenuItem } from "@material-ui/core";
import { GridMenuIcon } from "@material-ui/data-grid";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
interface props {
  courseReducer?: string;
  isDrawerOpen?: boolean;
  handleDrawerOpen?: any;
}
const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    position: "absolute",
    right: 15,
    top: 58,
  },
}));

const AppHeader: React.FC<props> = (props) => {
  let { isDrawerOpen } = props;
  const { sectionTitle } = useSelector((state: any) => state.courseReducer);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDrawerOpen = () => {
    props.handleDrawerOpen();
  };

  const handleLogout = async () => {
    await dispatch(userAuthentication(true));
    history.push("/");
    localStorage.removeItem("userCredentials");
    window.location.reload();
  };

  return (
    <div style={{ display: "flex" }}>
      <header className="Navbar" style={{ paddingLeft: isDrawerOpen ? 240 : 10 }}>
        <div className="Toolbar">
          {!isDrawerOpen ? (
            <IconButton color="inherit" onClick={handleDrawerOpen}>
              <GridMenuIcon />
            </IconButton>
          ) : null}
          <div className="Logo">
            {" "}
            <span role="img" aria-label="logo">
              😏
            </span>{" "}
          </div>
          <div className="Title">TDC ADMIN</div>
          <IconButton onClick={() => handleLogout()}>
            <PowerSettingsNewIcon />
          </IconButton>
        </div>
        <HeaderBreadCrumb />
        <div className={classes.sectionHeader}>{sectionTitle}</div>
      </header>
    </div>
  );
};
export default AppHeader;
