import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface Props {
  field: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  handleStateChange: any;
}

const CustomSwitch: React.FC<Props> = (props) => {
  let { field, label, checked, disabled } = props;

  const handleChange = (event) => {
    props.handleStateChange(field, event);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        style={{ cursor: "pointer" }}
        disabled={disabled}
        control={
          <Switch
            onChange={(e) => {
              handleChange(e);
            }}
            checked={checked}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CustomSwitch;
