import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import * as d3 from "d3";
import Button from "@mui/material/Button";
import QuestionTable from "../../Components/FilePicker/questionTable";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionTagAll } from "../../redux/action/questionTagAction";
import { isValidCsv } from "../../utils/validationFileType";

interface Props {
  optionName?: any;
  questionTagReducer?: any;
  assessmentList?: any;
  handleEditQuestionForm?: any;
  uploadQuestionList?: any;
  scrollTop?: any;
}

const FinalAssesssment: React.FC<Props> = (props) => {
  let { questionTags } = useSelector((state: Props) => state.questionTagReducer);
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = useState<any>();
  const [rowDataList, setRowDataList] = useState<any>([]);
  const [mapQuestionsList, setMapQuestionsList] = useState<any>([]);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState({
    isError: false,
    errMsg: "",
  });

  useEffect(() => {
    let uri = `${apiEndPoint.GET_QUESTION_TAG_ALL}`;
    dispatch(getQuestionTagAll(uri));
  }, [dispatch]);

  useEffect(() => {
    if (props.assessmentList) {
      setMapQuestionsList(props.assessmentList);
    }
  }, [props.assessmentList]);

  const handleCsvFile = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      let isValid = isValidCsv(e.target.files[0].name);
      if (!isValid) {
        return false;
      }
      setError({
        isError: false,
        errMsg: "",
      });
      setFileName(e.target.files[0].name);
      setCsvFile(e.target.value);
      handlePickFile(e.target.files[0]);
    }
  };

  const handlePickFile = (csvFile) => {
    const files = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      let selectedCsv: any = [];
      const text = e?.target?.result;
      selectedCsv = d3.csvParse(text);
      let questLength = mapQuestionsList.length;
      let assignmentId = mapQuestionsList[0]?.courseAssignmentId;
      // let questionTagId = mapQuestionsList[0]?.questionTagId;
      let courseAssignmentId = assignmentId ? assignmentId : 0;
      const result = selectedCsv.map(function (item, i) {
        let questionTag: any = null;
        questionTags.forEach((element) => {
          if (element.tagName === item?.questionTagId) {
            questionTag = element.questionTagId;
          }
        });

        if (
          item.answer.trim().toLowerCase() === item.choiceA.trim().toLowerCase() ||
          item.answer.trim().toLowerCase() === item.choiceB.trim().toLowerCase() ||
          item.answer.trim().toLowerCase() === item.choiceC.trim().toLowerCase() ||
          item.answer.trim().toLowerCase() === item.choiceD.trim().toLowerCase()
        )
          if (
            item.questionDetails.trim().length &&
            item.choiceA.trim().length &&
            item.choiceB.trim().length
            // (item.choiceA.toUpperCase() === "TRUE" || item.choiceA.toUpperCase() === "FALSE")
          ) {
            return {
              ...item,
              questionTagId: questionTag ? questionTag : null,
              questionIndex: i + 1 + questLength,
              courseQuestionId: 0,
              courseAssignmentId: courseAssignmentId,
            };
          } else if (
            item.questionDetails.trim().length &&
            item.choiceA.trim().length &&
            item.choiceB.trim().length &&
            item.choiceC.trim().length &&
            item.choiceD.trim().length
          ) {
            return {
              ...item,
              questionTagId: questionTag ? questionTag : null,
              questionIndex: i + 1 + questLength,
              courseQuestionId: 0,
              courseAssignmentId: courseAssignmentId,
            };
          } else {
            setError({
              isError: true,
              errMsg: "All Fields are Required!",
            });
            return {
              ...item,
              questionTagId: questionTag ? questionTag : null,
              questionIndex: i + 1 + questLength,
              courseQuestionId: 0,
              courseAssignmentId: courseAssignmentId,
              error: true,
            };
          }
        else {
          setError({
            isError: true,
            errMsg: "Some Answers not matches with given Options",
          });
          return {
            ...item,
            questionTagId: questionTag ? questionTag : null,
            questionIndex: i + 1 + questLength,
            courseQuestionId: 0,
            // questionTagId: item.questionTagId ? item.questionTagId : null,
            courseAssignmentId: courseAssignmentId,
            error: true,
          };
        }
      });
      let updatedQuestionList: any = [...result];
      setRowDataList(updatedQuestionList);
    };
    reader.readAsText(files);
  };

  const handleEditQuestionForm = (item) => {
    props.scrollTop();
    props.handleEditQuestionForm(item);
  };

  const handleUploadCsv = (rowDataList) => {
    let newQuestionList = [...mapQuestionsList, ...rowDataList];
    setMapQuestionsList(newQuestionList);
    props.uploadQuestionList(rowDataList);
    setRowDataList([]);
  };

  const handleCancelCsv = (e) => {
    setRowDataList([]);
    setFileName("");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 10,
          marginTop: 15,
          border: "1px solid #ddd",
          flexDirection: "column",
        }}
      >
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
            marginTop: 5,
            border: "1px solid #ddd",
          }}
        >
          <Grid>
            <label> *Add CSV file: </label>

            <input
              style={{ color: "transparent" }}
              type="file"
              id="csvfile"
              accept=".csv,.xlsx,.xls"
              value={csvFile}
              onChange={(e) => {
                handleCsvFile(e);
              }}
            />
            {fileName && <h4>Selected File : {fileName}</h4>}
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }}>
          <hr />
          <QuestionTable
            questionList={rowDataList}
            handleUploadCsv={handleUploadCsv}
            handleCancelCsv={handleCancelCsv}
            handleError={error}
          />
        </Grid>
      </div>

      <Grid style={{ justifyContent: "flex-end" }}>
        {mapQuestionsList && mapQuestionsList.length > 0 ? (
          <h3>{`Total Questions Added : ${mapQuestionsList.length}`}</h3>
        ) : null}
      </Grid>

      {mapQuestionsList?.map((item) => {
        return (
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              marginTop: 15,
              border: "1px solid #ddd",
            }}
          >
            <span>{item.questionDetails}</span>
            <Grid>
              <Button variant="outlined" color="primary" onClick={() => handleEditQuestionForm(item)}>
                Edit
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default FinalAssesssment;
