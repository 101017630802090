import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import Grid from "@material-ui/core/Grid";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import { useParams, useHistory } from "react-router-dom";
import { enableHeaderTab } from "../../redux/action/configAction";

import CustomLabel from "../../Components/Label/CustomLabel";
import { MainButton } from "../../Components/Modal/CustomModal";
import CourseBundleTable from "../Course/components/CourseBundleTable";
import SelectionList from "../PromoCode/selectionList";
import {
  getAllBundlesByCustomFilter,
  getAllCourses,
  getAllBundles,
  saveBundleCourseList,
  getbBundleCourseById,
} from "../../redux/action/courseAction";

import useSnackbar from "../../Components/Snackbar/useSnackbar";

interface props {
  courseReducer?: any;
  ura?: any;
  closeModal?: any;
}

const ListOfCourse: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  let { id } = useParams();
  const dispatch = useDispatch();
  const [courseListVisible, setCourseListVisible] = useState(false);
  let { courseSelectionList } = useSelector((state: props) => state.courseReducer);
  let { getBundleCourseDetails } = useSelector((state: props) => state.courseReducer);

  const [courseList, setCourseList] = useState([]);
  const [tableData, setTableData] = useState([]);
  let [selectedCourses, setSelectedCourses] = useState<any>([]);
  let [selectedCoursesChecked, setSelectedCoursesChecked] = useState<any>([]);
  const [tempCourseList, setTempCourseList] = useState([]);
  let courseInput: any = useRef();
  const history = useHistory();

  useEffect(() => {
    getAllCoursesData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BUNDLE_COURSE_BY_ID}?courseBundleId=${id}`;
      dispatch(getbBundleCourseById(uri));
    }
  }, [id, dispatch]);

 
  useEffect(() => {
    let enbledCourseList = courseSelectionList.filter((item: any) => (item.disabled )== "N");

    setCourseList(courseSelectionList);
    setTempCourseList(enbledCourseList);
    let newTempList = courseSelectionList.filter((item: any) => (item.disabled )== "N");
    if (getBundleCourseDetails && getBundleCourseDetails.length > 0) {
      newTempList.forEach((element: any, index: any) => {
        getBundleCourseDetails?.forEach((currentCourseId: any) => {
        
          if (currentCourseId.courseId === element.courseId 
            && currentCourseId.isDisabled == "N") {
              newTempList[index].checked = true;
              newTempList[index].checkboxValue = "N";
              newTempList[index] = {
              ...newTempList[index],
              courseBundleItemId: currentCourseId.courseBundleItemId,
              courseIndex: currentCourseId.courseBundleIndex, 
              courseBundleIndex: currentCourseId.courseBundleIndex, 
            };
          }
        });
      });
    }
    setTimeout(() => {
      if (newTempList && newTempList.length) {
        newTempList.sort((a, b) => (a.courseIndex < b.courseIndex ? -1 : 1));
      }
      setTempCourseList(newTempList);
    }, 3000);
    let selectedCourse = newTempList.filter((item: any) => item.checked);
    let selectedCoursesChecked = newTempList.filter((item: any) => (item.checkboxValue )!= null);
  
    if (selectedCourse && selectedCourse.length) {
      selectedCourse.sort((a, b) => (a.courseIndex > b.courseIndex ? 1 : -1));
    }
    setSelectedCoursesChecked(selectedCoursesChecked);
    setTableData(selectedCourse);
  }, [courseSelectionList,getBundleCourseDetails]);

  
  const getAllCoursesData = () => {
    let uri = `${apiEndPoint.GET_ALL_COURSES}`;
    const args = {
      pageCriteria: {
        pageSize: 100,
        pageNum: 0,
      },
    };
    dispatch(getAllCourses(uri, args, ""));
  };

  const selectedCheckBoxCourse = (e: any, selectedItem: any) => {
    let newTempCourseList: any = [];
    newTempCourseList = tempCourseList.map((item: any, i) => {
      if (selectedItem.courseId === item.courseId) {
        let checkboxVal = "Y";
      if(e.target.checked){
        checkboxVal = "N";
      }
        return { ...item, checked: e.target.checked, checkboxValue: checkboxVal };
      }
      return item;
    });
    setTempCourseList(newTempCourseList);
    let selectedCourse = newTempCourseList.filter((item: any) => item.checked);
    let selectedCoursesChecked = newTempCourseList.filter((item: any) => (item.checkboxValue )!= null);
    setSelectedCourses(selectedCourse);
    setSelectedCoursesChecked(selectedCoursesChecked);
  };


  const courseIndex = (e: any, selectedItem: any) => {
    let newTempCourseList: any = [];
    newTempCourseList = tempCourseList.map((item: any, i) => {
    setTempCourseList(newTempCourseList);
      if (selectedItem.courseId === item.courseId ) {
        return { ...item,courseIndex: e.target.value };
      }
      return item;
    });
    setTempCourseList(newTempCourseList);
    let selectedCourse = newTempCourseList.filter((item: any) => item.checked);
    let selectedCoursesChecked = newTempCourseList.filter((item: any) => (item.checkboxValue )!= null);
    setSelectedCourses(selectedCourse);
    setSelectedCoursesChecked(selectedCoursesChecked);
  };

  const handleSaveCourseList = () => {
   if(selectedCourses.length == 0){
    selectedCourses = tempCourseList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourses);
   }
    setTableData(selectedCourses);
    handleCourseList(false);
    handleBlurInput();
  };

  const handleCancelCourseList = () => {
    let newTempList: any = [...tempCourseList];
    newTempList.forEach((element: any, index: any) => {
      element.checked = false;
      tableData?.forEach((currentCourseId: any) => {
        if (currentCourseId.courseId === element.courseId )  {
          newTempList[index].checked = true;
          newTempList[index] = {
            ...newTempList[index],
            courseBundleItemId: currentCourseId.courseBundleItemId,
          };
        }else{
          if(element.checked == true){
            newTempList[index].courseIndex = "";
          }
        }
      });
    });
    handleCourseList(false);
    
    let selectedCourses = newTempList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourses);
    setTempCourseList(tempCourseList);
    handleBlurInput();
  };
  const handleBlurInput = () => {
    setTimeout(() => {
      courseInput?.current?.blur();
      // bundleInput?.current?.blur();
    }, 100);
  };

  const handleCourseList = (flag: any) => {
    setCourseListVisible(flag);
    if (flag) {
      window.scroll({ top: 150, left: 0, behavior: "smooth" });
    }
  };

  //values for call save API
  let selectedCourseData = selectedCoursesChecked.map((elem) => {
    return {
      courseId: elem.courseId,
      courseBundleId: id,
      courseBundleItemId: elem.courseBundleItemId ? elem.courseBundleItemId : 0,
      coursePriceInBundle: 0,
      courseBundleIndex:elem.courseIndex,
      isDisabled:elem.checkboxValue,
    };
  });
  const fetchBundleList = () => {
    let uri = `${apiEndPoint.GET_ALL_BUNDLES}`;
    dispatch(getAllBundles(uri));
  };
  const onSubmit = () => {
    if (selectedCourseData && selectedCourseData.length > 0) {
      let uri = `${apiEndPoint.SAVE_BUNDLE_COURSE_LIST}`;
      let courseData = selectedCourseData;
      if (id) {
        courseData.forEach((item, iteration) => {
          dispatch(saveBundleCourseList(uri, item));
          if (iteration === courseData.length - 1) {
            Snackbar.show("Save Successfully", "success");
          }
          setTimeout(() => {
            history.push({ pathname: `/home/bundle` });
            if (!id) {
              fetchBundleList();
              props.closeModal(false);
            }
            dispatch(enableHeaderTab(true));
          }, 2000);
        });
      }
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const onUpdate = () => {
    if (selectedCourseData && selectedCourseData.length !== 0) {
      let uri = `${apiEndPoint.SAVE_BUNDLE_COURSE_LIST}`;
      let courseData = selectedCourseData;
      if (id) {
        courseData.forEach((item, iteration) => {
          dispatch(saveBundleCourseList(uri, item));
          if (iteration !== courseData.length - 1) {
            Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
          }
          setTimeout(() => {
            history.push({ pathname: `/home/bundle` });
            if (!id) {
              fetchBundleList();
              props.closeModal(false);
            }
            dispatch(enableHeaderTab(true));
          });
        });
      }
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <CustomLabel label="Course List" />
        <div style={{ flex: 1 }}>
          <div className="m-multi-select-checkbox">
            <div className="options-container" style={{ display: "flex", justifyContent: "flex-end" }}>
              <MainButton
                onClick={() => {
                  handleCourseList(true);
                }}
              >
                {" "}
                Add New Courses
              </MainButton>
              {courseListVisible && (
                <SelectionList
                  list={tempCourseList}
                  selectedList={selectedCourses}
                  title="Select Courses"
                  handleCancel={handleCancelCourseList}
                  handleConfirm={handleSaveCourseList}
                  selectedCheckBox={selectedCheckBoxCourse}
                  courseIndex={courseIndex}
                />
              )}
            </div>
          </div>
        </div>
      </div>
     
      <div style={{ marginTop: "30px" }}>
       
        <CourseBundleTable AllCourseList={tableData} />
        <Grid>
          <BtnSection>
            {/* <SubmitBtn onClick={() => onSubmit()}>Submit</SubmitBtn> */}
            <SubmitBtn onClick={() => onUpdate()}>Update</SubmitBtn>
          </BtnSection>
        </Grid>
      </div>
      
    </>
  );
};
export default ListOfCourse;
