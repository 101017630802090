import axios from "axios";
import { getApiHeader } from "../helper/getApiHeader";

const API_URL = process.env.REACT_APP_API_URL;

export default class Service {
  constructor() {}
  private static baseURL: any = API_URL;

  public static getAll<T>(url: string): any {
    let headerData = getApiHeader();
    let res = axios({
      method: 'get',
      url:API_URL + url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headerData,
      },
  })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  }

  public static get<T>(url: string, param: any): any {
    let headerData = getApiHeader();
    let res = axios({
      method: 'get',
      url:API_URL + url+param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headerData,
      },
  })
      .then((response) => {
        console.log("response"+JSON.stringify(response));
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  }
  public static delete(url: string, param: any): any {
    let headerData = getApiHeader();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        ...headerData,
      },
    };
    let res = axios
      .post(API_URL + url + param, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  }
  public static postApi<T>(url: string, obj?: T): any {
    let headerData = getApiHeader();
    const config = {
      headers: {
        "content-type": "application/json",
        ...headerData,
      },
    };
    return axios
      .post(API_URL + url, obj, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  public static async postApiMulti<T>(url: string, obj?: T) {
    let apiRes: any = null;
    let headerData = getApiHeader();
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          ...headerData,
        },
      };
      let response = await axios.post(API_URL + url, obj, config);
      apiRes = response;
      // return response;
      // return axios
      //   .post(API_URL + url, obj, config)
      //   .then((response) => {
      //     console.log(">>>>>>>>>",{ response });
      //     return response.data;
      //   })
      //   .catch(function (error) {
      //     return error;
      //   });
    } catch (e: any) {
      apiRes = e.response;
      // return { status: 500, data: e.response.data };
    } finally {
      return apiRes;
    }
  }

  public static updateApi<T>(url: string, param: any, obj: T): any {
    let headerData = getApiHeader();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        ...headerData,
      },
    };
    let res = axios
      .post(API_URL + url + param,config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  }
}
