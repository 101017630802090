import React from "react";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import { CssBaseline } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { BtnSection, SubmitBtn, CancelBtn } from "../../Components/Card/Card";
import {
  getQuestionTagAll,
  getQuestionTagByCustomFilter,
  getQuestionTagById,
  saveQuestionTag,
} from "../../redux/action/questionTagAction";
import CustomInput from "../../Components/Input/CustomInput";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    marginTop: "30px",
    width: "100%",
  },
  cancel: {
    backgroundColor: "red",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  submit: {
    backgroundColor: "blue",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
}));

interface props {
  questionTagReducer?: any;
  loaderReducer?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
  url?: any;
}

const TagForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  let { questionTag, currentPage } = useSelector((state: props) => state.questionTagReducer);
  let { formLoader } = useSelector((state: props) => state.loaderReducer);
  const [inputData, setInputData] = useState({
    tagName: "",
    questionTagId: "0",
  });

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_QUESTION_TAG_BY_ID}?questionTagId=${id}`;
      dispatch(getQuestionTagById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (questionTag && id) {
      tagById(questionTag);
    }
  }, [id, questionTag]);

  const fetchQuestionTagsList = async () => {
    let uri = `${apiEndPoint.GET_QUESTION_TAG_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: currentPage,
      },
    };
    await dispatch(getQuestionTagByCustomFilter(uri, args, ""));
  };

  const tagById = (questionTag) => {
    let name = questionTag[0].tagName;
    let id = questionTag[0].questionTagId;

    setInputData({
      tagName: name,
      questionTagId: id,
    });
  };

  const validationSchema = yup.object({
    tagName: yup
      .string()
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .required("Please Enter Tag Name!"),
  });

  const onSubmit = async () => {
    const { tagName, questionTagId } = inputData;
    let tagData = {
      tagName,
      questionTagId,
    };
    let uri = `${apiEndPoint.SAVE_QUESTION_TAG}`;
    let response: any = await dispatch(saveQuestionTag(uri, tagData));
    let { status, result } = response;
    if (status) {
      if (id) {
        history.push("/home/questionTag");
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");

      if (result.questionTagId > 0) {
        setInputData((preval) => {
          return {
            ...preval,
            questionTagId: result.questionTagId,
          };
        });
      }

      if (id) {
        // setTimeout(() => {
        //   history.push("/home/questionTag");
        // }, 500);
      } else if (!id) {
        setTimeout(() => {
          if (currentPage === 0) {
            fetchQuestionTagsList();
          }
        }, 500);
      }
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const handleInput = (field: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [field]: value,
      };
    });
  };

  const handleCancel = () => {
    history.push("/home/questionTag");
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <>
      <FormLoader visible={formLoader} />
      <DivWrapper>
        <Grid container xs={12} sm={12} md={12} className={classes.root} justify="center">
          <CssBaseline />

          <form style={{ width: "100%" }}>
            <CustomInput
              variant="outlined"
              className={classes.input}
              label="Tag Name"
              name="tagName"
              id="tagName"
              handleState={(field: any, value: any) => {
                handleInput(field, value);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              value={inputData.tagName}
              error={formik.errors.tagName}
              helperText={formik.errors.tagName}
            />
            <CustomInput
              variant="outlined"
              className={classes.input}
              id="questionTagId"
              disabled={true}
              style={{
                backgroundColor: " #e3e1e1 ",
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              label="Question Tag ID"
              handleState={(field: any, value: any) => {
                handleInput(field, value);
              }}
              value={inputData.questionTagId}
              name="tagId"
            />
            {id ? (
              <Grid>
                <BtnSection>
                  <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                    Update
                  </SubmitBtn>
                  <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
                </BtnSection>
              </Grid>
            ) : null}
          </form>
        </Grid>
      </DivWrapper>
    </>
  );
};

export default TagForm;
