export const isValidPdf = (fileName) => {
  // var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  var re = /(\.pdf)$/i;
  if (!re.exec(fileName)) {
    alert("File extension not supported!");
    return false;
  }
  return true;
};

export const isValidImage = (fileName) => {
  // var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  if (!re.exec(fileName)) {
    alert("File extension not supported!");
    return false;
  }
  return true;
};

export const isValidVideo = (fileName) => {
  // var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  var re = /(\.mp4|\.avi|\.mpg|\.m4v|\.webm)$/i;
  if (!re.exec(fileName)) {
    alert("File extension not supported!");
    return false;
  }
  return true;
};

export const isValidCsv = (fileName) => {
  console.log({fileName})
  // var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  var re = /(\.xls|\.csv|\.xlsx)$/i;
  if (!re.exec(fileName)) {
    alert("File extension not supported!");
    return false;
  }
  return true;
};
