import styled from "styled-components";

const Modal = styled.div`
  max-width: 500px;
  // background-color: white;
  position: fixed;
  top: 20%;
  z-index: 5;
  max-height: calc(100% - 200px);
  left: 55%;
  display: flex;
  flex-direction: column;
`;
export const ModalContent = styled.div`
  overflow: auto;
  min-height: 200px;
  padding: 0px 40px;
  padding-bottom: 80px;
`;
export const ModalFooter = styled.div`
  box-shadow: 0px -2px 10px 0px grey;
  height: 60px;
  display: flex;
  justify-content: center;
`;
export const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  width: 200px;
  cursor: pointer;
  background-color: blue;
`;
const ModalShadow = styled.div`
  position: fixed;
  width: 78%;

  margin-left: -20px;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
`;

export const MainButton = styled.button`
  color: #fff;
  // background-color: #2196f3;#042367
  background-color: #1058b3;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  font-weight: bold;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  border: 0;
  /* &:hover {
    background-color: #1976d2;
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  } */
`;
function ModalContainer({ setOpen, content }) {
  function close() {}

  return (
    <div style={{ overflowY: "scroll" }}>
      <ModalShadow onClick={close} />
      <Modal>
        <ModalContent>{content}</ModalContent>
      </Modal>
    </div>
  );
}

interface props {
  setOpen?: any;
  content?: any;
}

const ModalExample: React.FC<props> = (props) => {
  return <div>{props.setOpen && <ModalContainer {...props} setOpen={props.setOpen} content={props.content} />}</div>;
};
export default ModalExample;
