import React, { useState, useRef } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Edit } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import BlogForm from "../../Page/Blog/form";
import CourseDetailForm from "../../Page/Course/courseDetailform";
import SectionForm from "../../Page/Course/SectionForm";
import SubSectionForm from "../../Page/Course/SubSection";
import SubSectionContent from "../../Page/Course/subSectionContent";
import FacultyForm from "../../Page/Faculty/form";
import NewsForm from "../../Page/News/form";
import PromoCodeForm from "../../Page/PromoCode/form";
import OfferForm from "../../Page/invoice/form";
import TagForm from "../../Page/QuestionTag/form";
import ReferralCodeForm from "../../Page/ReferralCode/form";
import { resetArtifact } from "../../redux/action/courseAction";
import { useDispatch, useSelector } from "react-redux";
import { AddNewFormBtn, CancelBtn, SubmitBtn } from "../Card/Card";
import BundleForm from "../../Page/Bundle/form";
import UserForm from "../../Page/User/form";
import { useLocation } from 'react-router-dom';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const myRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let { customeIcon, page, title, rowData, label, data, itemIndex, sectionLength, parentData } = props;
  const { formLoader } = useSelector((state) => state.loaderReducer);
  const [pageName, setPage] = useState(page);
  let [togleEvent, setEvent] = useState(0);

  const executeScroll = () => {
    myRef.current.scrollTo(0, 0);
  };

  const handleCloseModal = (flag) => {
    setOpen(flag);
    setLoading(false);
  };
  const location = useLocation();
  const isInvoice = location.pathname === '/home/invoice';
  const customeForm = (param) => {
    switch (param) {
      case "content":
        return (
          <SubSectionContent
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
            rowData={rowData}
            data={data}
            itemIndex={itemIndex}
            executeScroll={executeScroll}
          ></SubSectionContent>
        );
      case "section":
        return (
          <SectionForm
            closeModal={handleClose}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            data={data}
            rowData={rowData}
            isModal={true}
            sectionLength={sectionLength}
          ></SectionForm>
        );
      case "sub-section":
        return (
          <SubSectionForm
            closeModal={handleClose}
            togleEvent={togleEvent}
            isModal={true}
            handleCloseModal={handleCloseModal}
            data={data}
            rowData={rowData}
            sectionLength={sectionLength}
            parentData={parentData}
          ></SubSectionForm>
        );
      case "course":
        return (
          <CourseDetailForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            rowData={null}
            isModal={true}
          ></CourseDetailForm>
        );
      case "News":
        return (
          <NewsForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></NewsForm>
        );
      case "refferalCode":
        return (
          <ReferralCodeForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></ReferralCodeForm>
        );
      case "promoCode":
        return (
          <PromoCodeForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></PromoCodeForm>
        );
        case "invoice":
          return (
            <OfferForm
              closeModal={setOpen}
              handleCloseModal={handleCloseModal}
              togleEvent={togleEvent}
              isModal={true}
            ></OfferForm>
          );
      case "questionTag":
        return (
          <TagForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></TagForm>
        );
      case "blog":
        return (
          <BlogForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></BlogForm>
        );
      case "faculty":
        return (
          <FacultyForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></FacultyForm>
        );
      case "bundle":
        return (
          <BundleForm
            closeModal={setOpen}
            handleCloseModal={handleCloseModal}
            togleEvent={togleEvent}
            isModal={true}
          ></BundleForm>
        );
        case "user":
          return (
            <UserForm
              closeModal={setOpen}
              handleCloseModal={handleCloseModal}
              togleEvent={togleEvent}
              isModal={true}
            ></UserForm>
          );

      default:
        return <div></div>;
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setPage(page);
    setOpen(true);
    setLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setEvent(0);
    dispatch(resetArtifact());
  };

  const formSubmit = () => {
    setLoading(true);
    setEvent(togleEvent + 1);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <div>
      {customeIcon === "edit" ? (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            handleClickOpen();
          }}
        >
          <Edit style={{ marginRight: 10 }} />
        </div>
      ) : customeIcon === "add" ? (
        // <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        //   {title} 0c7be0;
        // </Button>
        // <SubmitBtn onClick={handleClickOpen}>{title}</SubmitBtn>
        <AddNewFormBtn onClick={handleClickOpen}>{title}</AddNewFormBtn>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClickOpen();
          }}
        >
          <ControlPointIcon></ControlPointIcon>{" "}
        </div>
      )}
      <Dialog
        style={{ margin: 10 }}
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {page === "sub-section" ? "Sub Section" : label || "Form"}
        </DialogTitle>
        <DialogContent ref={myRef} dividers>
          {customeForm(pageName)}
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={formSubmit} color="primary">
            Submit
          </Button> */}
          {/* {isInvoice ? null : */}
            <SubmitBtn
            disabled={formLoader || isLoading}
            disabledColor={formLoader || isLoading}
            autoFocus
            onClick={formSubmit}
          >
            {" "}
            Submit
          </SubmitBtn>
          {/* } */}
         
          {/* <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          {/* {isInvoice ? null : */}
           <CancelBtn autoFocus onClick={handleClose}>
            {" "}
            Cancel{" "}
          </CancelBtn>
          {/* } */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
