import React from "react";
import { Drawer, List, ListItem, ListItemIcon } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import NewspaperIcon from "@mui/icons-material/Newspaper";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ArticleIcon from "@mui/icons-material/Article";
import QuizIcon from "@mui/icons-material/Quiz";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ReportIcon from "@mui/icons-material/Report";
import StyleIcon from "@mui/icons-material/Style";
import { ListItemText } from "@material-ui/core";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
interface Props {
  list: any;
}

const useStyles: any = makeStyles((theme) => ({
  drawerPaper: { width: "inherit" },
  link: { textDecoration: "none", color: "#000" },
}));

const DrawerList: React.FC<Props> = (props) => {
  let { list } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const renderIcon = (iconName) => {
    switch (iconName) {
      case "Home":
        return <HomeIcon />;
      case "Course":
        return <PlayLessonIcon />;
      case "Faculty":
        return <RecordVoiceOverIcon />;
      case "News":
        return <ArticleIcon />;
      case "Blog":
        return <MenuBookIcon />;
      case "Bundle":
        return <CardGiftcardIcon />;
      case "ReferralCode":
        return <StyleIcon />;
      case "PromoCode":
        return <LocalOfferIcon />;
      case "QuestionTag":
        return <QuizIcon />;
      case "Report":
        return <ReportIcon />;
        case "User":
          return <ManageAccountsOutlinedIcon />;
          case "invoice":
            return <FileCopyTwoToneIcon />;
      default:
        return <HomeIcon />;
    }
  };

  let listItemStyles = {
    backgroundColor: "#fff",
    paddingLeft: 5,
    padding: 10,
    marginTop: -8,
  };

  const handleRoute = (item) => {
    dispatch({
      type: `INIT_${item.key}_LIST`,
    });
    history.push(item.path);
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#fff" }}>
      <List>
        {list.map((item: any, i: any) => (
          <div key={i} style={listItemStyles}>
            <div onClick={() => handleRoute(item)} className={classes.link}>
              <ListItem button>
                <ListItemIcon>{renderIcon(item.iconName)}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};

export default DrawerList;
