import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../Components/Input/CustomInput";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getAllBundles, getAllCourses } from "../../redux/action/courseAction";
import {
  addPromoCodeMapList,
  getPromoCodeAll,
  getPromoCodeByCustomFilter,
  getPromoCodeMapAll,
  savePromoCode,
} from "../../redux/action/promoCodeAction";
import { getPromoCodeDetails } from "../../redux/action/promoCodeAction";
import { DivWrapper } from "../../Components/Card/Card";
import SelectionList from "./selectionList";
import CustomSelect from "../../Components/Select/CustomSelect";
import { applyOnOptions, priceCategory } from "../../config/constant";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { PROMOCODE_TITLE_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    marginTop: 30,
    margin: theme.spacing(0, 2, 2),
  },
}));

interface Props {
  courseReducer?: any;
  loaderReducer?: any;
  promoCodeReducer?: any;
  togleEvent?: any;
  isModal?: any;
  handleCloseModal?: any;
  url?: any;
  closeModal?: any;
}

const PromoCodeForm: React.FC<Props> = (props) => {
  const Snackbar = useSnackbar();
  const classes = useStyles();
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let [promoCodeData, setPromoCodeData] = useState<any>({
    courseCouponId: null,
    couponTitle: "",
    couponCategoryId: 2,
    couponCode: "",
    couponValue: "",
    disabled: "N",
    applyOnAllCourses: "N",
    applyOnAllBundles: "N",
    applyOnCourseOrBundle: "C",
    expiryInDays: "",
  });

  const [courseList, setCourseList] = useState([]);
  const [tempCourseList, setTempCourseList] = useState([]);
  const [bundleList, setBundleList] = useState([]);
  const [tempBundleList, setTempBundleList] = useState([]);
  const [courseListVisible, setCourseListVisible] = useState(false);
  const [bundleListVisible, setBundleListVisible] = useState(false);
  let [selectedCourses, setSelectedCourses] = useState<any>([]);
  let [selectedBundles, setSelectedBundles] = useState<any>([]);
  let { courseSelectionList, bundleSelectionList } = useSelector((state: Props) => state.courseReducer);
  let { promoCodeMapAll, promoCode, currentPage } = useSelector((state: Props) => state.promoCodeReducer);
  let { formLoader } = useSelector((state: Props) => state.loaderReducer);
  let courseInput: any = useRef();
  let bundleInput: any = useRef();
  let {
    courseCouponId,
    couponTitle,
    couponCategoryId,
    couponCode,
    couponValue,
    disabled,
    applyOnAllBundles,
    applyOnAllCourses,
    applyOnCourseOrBundle,
    expiryInDays,
    maxNoOfPurchases,
    noOfPurchases,
  } = promoCodeData;

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  const fetchPromocodeList = async () => {
    let uri = `${apiEndPoint.GET_PROMO_CODE_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: currentPage,
      },
    };
    await dispatch(getPromoCodeByCustomFilter(uri, args, ""));
  };

  useEffect(() => {
    if (id && promoCode) {
      setPromoCodeData(promoCode);
    }
  }, [id, promoCode]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_PROMO_CODE_DETAILS}=${id}`;
      dispatch(getPromoCodeDetails(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    getAllCoursesData();
    getAllBundlesData();
    // eslint-disable-next-line
  }, []);

  const getAllCoursesData = () => {
    let uri = `${apiEndPoint.GET_ALL_COURSES}`;
    const args = {
      pageCriteria: {
        pageSize: 100,
        pageNum: 0,
      },
    };
    dispatch(getAllCourses(uri, args, ""));
  };

  const getAllBundlesData = () => {
    let uri = `${apiEndPoint.GET_ALL_BUNDLES}`;
    dispatch(getAllBundles(uri));
  };

  useEffect(() => {
    setCourseList(courseSelectionList);
    setTempCourseList(courseSelectionList);
  }, [courseSelectionList]);

  useEffect(() => {
    setBundleList(bundleSelectionList);
    setTempBundleList(bundleSelectionList);
  }, [bundleSelectionList]);

  useEffect(() => {
    if (courseCouponId) {
      let uri = `${apiEndPoint.PROMO_CODE_MAP_BY_COUPON_CODE}=${courseCouponId}`;
      dispatch(getPromoCodeMapAll(uri));
    }
  }, [dispatch, courseCouponId]);

  useEffect(() => {
    try {
      if (promoCodeMapAll && promoCodeMapAll.length > 0) {
        let filteredPromoCode = [...promoCodeMapAll];
        let newTempCourseList: any = [...tempCourseList];
        let newTempBundleList: any = [...tempBundleList];
        if (courseCouponId) {
          const couponIdArray = filteredPromoCode.map((item) => {
            let { courseId, disabled, courseCouponMapId } = item;
            return { courseId, disabled, courseCouponMapId };
          });
          const bundleIdArray = filteredPromoCode.map((item) => {
            let { courseBundleId, disabled, courseCouponMapId } = item;
            return { courseBundleId, disabled, courseCouponMapId };
          });

          newTempCourseList.forEach((element: any, index: any) => {
            couponIdArray.forEach((couponItem: any) => {
              if (couponItem.courseId === element.courseId) {
                newTempCourseList[index].checked = couponItem.disabled !== "Y";
                newTempCourseList[index].disabled = couponItem.disabled;
                newTempCourseList[index] = {
                  ...newTempCourseList[index],
                  courseCouponMapId: couponItem?.courseCouponMapId,
                };
              }
            });
          });
          setTempCourseList(newTempCourseList);
          setCourseList(newTempCourseList);
          let selectedCourses = newTempCourseList.filter((item: any) => item.checked);
          setSelectedCourses(selectedCourses);

          newTempBundleList.forEach((element: any, index: any) => {
            bundleIdArray.forEach((bundleItem) => {
              if (bundleItem.courseBundleId === element.courseBundleId) {
                newTempBundleList[index].checked = bundleItem.disabled !== "Y";
                newTempBundleList[index].disabled = bundleItem.disabled;
                newTempBundleList[index] = {
                  ...newTempBundleList[index],
                  courseCouponMapId: bundleItem?.courseCouponMapId,
                };
              }
            });
          });
          setTempBundleList(newTempBundleList);
          setBundleList(newTempBundleList);
          let selectedBundles = newTempBundleList.filter((item: any) => item.checked);
          setSelectedBundles(selectedBundles);
        } else {
          setTempBundleList(newTempBundleList);
          setBundleList(newTempBundleList);
          setSelectedCourses([]);
          setTempCourseList(newTempCourseList);
          setCourseList(newTempCourseList);
          setSelectedBundles([]);
        }
      }
    } catch (e: any) {
      console.log(e.message);
    }
    // eslint-disable-next-line
  }, [promoCodeMapAll, tempCourseList?.length, tempBundleList.length, courseCouponId]);

  const validationSchema = yup.object({
    couponTitle: yup
      .string()
      .required("Please Enter Title!")
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .strict(false)
      .trim()
      .test(
        "len",
        `Characters Length should  not be greater than ${PROMOCODE_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= PROMOCODE_TITLE_LENGTH
      ),
    couponCode: yup
      .string()
      .matches(/^[a-zA-Z0-9-]*$/, "Only letters, numbers, and - are allowed")
      .required("Please Enter Promo Code!")
      .strict(false)
      .trim(),
    couponValue: yup
      .number()
      .required("Please Enter Price!")
      //.matches(/^(?<=\s|^)\d+(?=\s|$)/, "Please Enter Valid Price!")
      .when("couponCategoryId", {
        is: "1",
        then: yup.number().lessThan(100.01, `Price should not be greater than 100%`),
      }),

    couponCategoryId: yup.string().required("Please Select Price Category!"),
    applyOnCourseOrBundle: yup.string().required("Please Select Apply On!"),
    expiryInDays: yup.number().required("Please Enter Expiry Days!").typeError("Please Enter Only Numbers!"),
    maxNoOfPurchases: yup
    .number()
    .typeError("Please Enter Only Numbers!")
    .test(
      "len",
      `Value should  be greater than or equal to ${noOfPurchases}`,
      (val: any) => val >= noOfPurchases || val == null
    ),
    // .when("couponCategoryId", {
    //   is: "1",
    //   then: yup.number().moreThan(noOfPurchases, `Value should be greater than `+noOfPurchases),
    // }),
  });

  const onSubmit = async () => {
    onclickSubmit();
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: promoCodeData,
    validationSchema,
    onSubmit,
  });

  const onclickSubmit = async () => {
    try {
      let {
        courseCouponId,
        couponTitle,
        couponCategoryId,
        couponCode,
        couponValue,
        disabled,
        applyOnAllBundles,
        applyOnAllCourses,
        applyOnCourseOrBundle,
        expiryInDays,
        maxNoOfPurchases,
      } = promoCodeData;
      expiryInDays = Number(expiryInDays);
      let args = {
        courseCouponId,
        couponTitle,
        couponCategoryId,
        couponCode,
        couponValue,
        disabled,
        applyOnAllBundles,
        applyOnAllCourses,
        applyOnCourseOrBundle,
        expiryInDays,
        maxNoOfPurchases,
      };
      let uri = `${apiEndPoint.SAVE_PROMO_CODE}`;
      let response: any = await dispatch(savePromoCode(uri, args, courseCouponId));
      if (response.status) {
        if (id) {
          history.replace({ pathname: `/home/promoCode` });
        } else {
          props.closeModal(false);
        }
        Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");

        if (applyOnAllCourses !== "Y" && selectedCourses.length > 0 && applyOnCourseOrBundle === "C") {
          let uriMapPromoCode = `${apiEndPoint.ADD_PROMO_CODE_MAP_LIST}`;
          let args = selectedCourses.map((item: any, i: any) => {
            return {
              courseCouponId: response.result.courseCouponId,
              courseId: item.courseId,
              disabled: "N",
              courseCouponMapId: item?.courseCouponMapId ? item.courseCouponMapId : null,
            };
          });
          await dispatch(addPromoCodeMapList(uriMapPromoCode, args));
        }
        if (applyOnAllBundles !== "Y" && selectedBundles.length > 0 && applyOnCourseOrBundle === "B") {
          let uriMapPromoCode = `${apiEndPoint.ADD_PROMO_CODE_MAP_LIST}`;
          let args = selectedBundles.map((item: any, i: any) => {
            return {
              courseCouponId: response.result.courseCouponId,
              courseBundleId: item.courseBundleId,
              disabled: "N",
              courseCouponMapId: item?.courseCouponMapId ? item.courseCouponMapId : null,
            };
          });
          await dispatch(addPromoCodeMapList(uriMapPromoCode, args));
        }

        setTimeout(() => {
          if (!id) {
            if (currentPage === 0) {
              fetchPromocodeList();
            }
          }
        }, 500);
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const handleStateChange = (field: any, value: any) => {
    setPromoCodeData({ ...promoCodeData, [field]: value });
  };

  const handleSwitchState = (field: any, e: any) => {
    let value = e.target.checked ? "Y" : "N";
    let newPromoCodeData = { ...promoCodeData };
    newPromoCodeData = { ...promoCodeData, [field]: value };
    if (field === "applyOnAllCourses") {
      setCourseListVisible(false);
      setTempCourseList(courseList);
      let selectedCourses = courseList.filter((item: any) => item.checked);
      setSelectedCourses(selectedCourses);
      setPromoCodeData({ ...newPromoCodeData, applyOnAllBundles: "N" });
    }
    if (field === "applyOnAllBundles") {
      setBundleListVisible(false);
      setTempBundleList(bundleList);
      let selectedBundles = bundleList.filter((item: any) => item.checked);
      setSelectedBundles(selectedBundles);
      setPromoCodeData({ ...newPromoCodeData, applyOnAllCourses: "N" });
    }
    if (field === "disabled") {
      setPromoCodeData({ ...newPromoCodeData });
    }
  };

  const selectedCheckBoxCourse = (e: any, selectedItem: any) => {
    let newTempCourseList: any = [];
    newTempCourseList = tempCourseList.map((item: any, i) => {
      if (selectedItem.courseId === item.courseId) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setTempCourseList(newTempCourseList);
    let selectedCourse = newTempCourseList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourse);
  };

  const selectedCheckBoxBundle = (e: any, selectedItem: any) => {
    let newTempBundleList: any = [];
    newTempBundleList = tempBundleList.map((item: any, i) => {
      if (selectedItem.courseBundleId === item.courseBundleId) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setTempBundleList(newTempBundleList);
    let selectedCourse = newTempBundleList.filter((item: any) => item.checked);
    setSelectedBundles(selectedCourse);
  };

  const handleCourseList = (flag: any) => {
    setCourseListVisible(flag);
    if (flag) {
      window.scroll({ top: 150, left: 0, behavior: "smooth" });
    }
  };

  const handleBundleList = (flag: any) => {
    setBundleListVisible(flag);
    if (flag) {
      window.scroll({ top: 150, left: 0, behavior: "smooth" });
    }
  };

  const handleBlurInput = () => {
    setTimeout(() => {
      courseInput?.current?.blur();
      bundleInput?.current?.blur();
    }, 100);
  };

  const handleSaveCourseList = () => {
    setCourseList(tempCourseList);
    handleCourseList(false);
    handleBlurInput();
  };

  const handleSaveBundleList = () => {
    setBundleList(tempBundleList);
    handleBundleList(false);
    handleBlurInput();
  };

  const handleCancelCourseList = () => {
    handleCourseList(false);
    let selectedCourses = courseList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourses);
    setTempCourseList(courseList);
    handleBlurInput();
  };

  const handleCancelBundleList = () => {
    handleBundleList(false);
    let selectedCourses = bundleList.filter((item: any) => item.checked);
    setSelectedBundles(selectedCourses);
    setTempBundleList(bundleList);
    handleBlurInput();
  };
  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleStateChange(name, val.trim());
  };

  return (
    <DivWrapper>
      <FormLoader visible={formLoader} />
      <div className={classes.container}>
        <Grid justify="center">
          <CssBaseline />

          <form>
            <div style={{ marginTop: 15 }}>
              <CustomSwitch
                field="disabled"
                handleStateChange={handleSwitchState}
                checked={disabled === "Y"}
                label="Disabled"
              />
            </div>
            <CustomInput
              variant="outlined"
              className={classes.input}
              fullWidth
              id="couponTitle"
              label="Title"
              name="couponTitle"
              value={couponTitle}
              handleState={(field: any, value: any) => {
                handleStateChange(field, value);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              error={formik.errors.couponTitle}
              helperText={formik.errors.couponTitle}
              autoFocus
            />
            <CustomInput
              variant="outlined"
              className={classes.input}
              fullWidth
              id="couponCode"
              label="Promo Code"
              name="couponCode"
              value={couponCode}
              handleState={(field: any, value: any) => {
                handleStateChange(field, value);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              error={formik.errors.couponCode}
              helperText={formik.errors.couponCode}
              autoFocus
            />
            <CustomInput
              style={{ width: "100%" }}
              variant="outlined"
              className={classes.input}
              fullWidth
              id="couponValue"
              label="Price"
              name="couponValue"
              value={couponValue}
              handleState={(field: any, value: any) => {
                handleStateChange(field, value);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              error={formik.errors.couponValue}
              helperText={formik.errors.couponValue}
              autoFocus
            />
            <CustomInput
              style={{ width: "100%" }}
              variant="outlined"
              className={classes.input}
              fullWidth
              id="expiryInDays"
              label="Expiry In Days"
              name="expiryInDays"
              value={expiryInDays}
              onBlur={(e) => {
                handleBlur(e);
              }}
              handleState={(field: any, value: any) => {
                handleStateChange(field, value);
              }}
              error={formik.errors.expiryInDays}
              helperText={formik.errors.expiryInDays}
              autoFocus
            />
            
            <CustomInput
              style={{ width: "100%" }}
              variant="outlined"
              className={classes.input}
              fullWidth
              id="maxNoOfPurchases"
              label="Max No Of Purchases"
              name="maxNoOfPurchases"
              value={maxNoOfPurchases}
              onBlur={(e) => {
                handleBlur(e);
              }}
              handleState={(field: any, value: any) => {
                handleStateChange(field, value);
              }}
              error={formik.errors.maxNoOfPurchases}
              helperText={formik.errors.maxNoOfPurchases}
              autoFocus
            />
            <div>Total purchases with this promo code : {noOfPurchases}</div>

            <div
              style={{
                display: "grid",
                gap: 20,
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "auto",
                marginTop: "32px",
              }}
              className="cmn-grd"
            >
              <CustomSelect
                field="couponCategoryId"
                selectedValue={couponCategoryId}
                label="Price Category"
                data={priceCategory}
                handleStateChange={handleStateChange}
              />
              <CustomSelect
                field="applyOnCourseOrBundle"
                selectedValue={applyOnCourseOrBundle}
                label="Apply Promo Code On"
                data={applyOnOptions}
                handleStateChange={handleStateChange}
              />
            </div>
            {applyOnCourseOrBundle === "C" ? (
              <div style={{ display: "grid", gap: 20, gridTemplate: "40px / 1fr 3fr", marginTop: "32px" }}>
                <CustomSwitch
                  field="applyOnAllCourses"
                  disabled={applyOnCourseOrBundle === "B"}
                  handleStateChange={handleSwitchState}
                  checked={applyOnAllCourses === "Y"}
                  label="For All Courses"
                />
                <div style={{ flex: 1 }}>
                  <div className="m-multi-select-checkbox">
                    <div className="options-container">
                      <input
                        ref={courseInput}
                        style={{
                          width: "100%",
                          height: 50,
                          borderRadius: 5,
                          borderWidth: 0.5,
                          borderColor: "#ddd",
                          outline: "none",
                          fontSize: 16,
                          fontFamily: "Oxanium",
                        }}
                        disabled={applyOnAllCourses === "Y" || applyOnCourseOrBundle === "B"}
                        type="text"
                        placeholder="Select Courses"
                        value={
                          applyOnAllCourses === "Y"
                            ? `All Courses Selected (Total ${tempCourseList.length})`
                            : selectedCourses.length === 0
                            ? ""
                            : `${selectedCourses.length} Course${selectedCourses.length > 1 ? "s" : ""} Selected`
                        }
                        onFocus={() => handleCourseList(true)}
                      />
                      {courseListVisible && (
                        <SelectionList
                          list={tempCourseList}
                          selectedList={selectedCourses}
                          title="Select Courses"
                          handleCancel={handleCancelCourseList}
                          handleConfirm={handleSaveCourseList}
                          selectedCheckBox={selectedCheckBoxCourse}
                          courseIndex={""}
                          handlePriority={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {applyOnCourseOrBundle === "B" ? (
              <div style={{ display: "grid", gap: 20, gridTemplate: "40px / 1fr 3fr", marginTop: "32px" }}>
                <CustomSwitch
                  field="applyOnAllBundles"
                  disabled={applyOnCourseOrBundle === "C"}
                  handleStateChange={handleSwitchState}
                  checked={applyOnAllBundles === "Y"}
                  label="For All Bundles"
                />
                <div style={{ flex: 1 }}>
                  <div className="m-multi-select-checkbox">
                    <div className="options-container">
                      <input
                        ref={bundleInput}
                        style={{
                          width: "100%",
                          height: 50,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#ddd",
                          outline: "none",
                          fontSize: 16,
                          fontFamily: "Oxanium",
                        }}
                        disabled={applyOnAllBundles === "Y" || applyOnCourseOrBundle === "C"}
                        type="text"
                        placeholder="Select Bundles"
                        className="m-search-box m-search-focus"
                        onFocus={() => handleBundleList(true)}
                        value={
                          applyOnAllBundles === "Y"
                            ? `All Bundles Selected (Total ${tempBundleList.length})`
                            : selectedBundles.length === 0
                            ? ""
                            : `${selectedBundles.length} Bundle${selectedBundles.length > 1 ? "s" : ""} Selected`
                        }
                      />
                      {bundleListVisible ? (
                        <SelectionList
                          list={tempBundleList}
                          selectedList={selectedBundles}
                          title="Select Bundles"
                          handleCancel={handleCancelBundleList}
                          handleConfirm={handleSaveBundleList}
                          selectedCheckBox={selectedCheckBoxBundle}
                          courseIndex={""}
                          handlePriority={true}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {id ? (
              <>
                <BtnSection>
                  <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                    UPDATE
                  </SubmitBtn>
                </BtnSection>
              </>
            ) : null}
          </form>
        </Grid>
      </div>
    </DivWrapper>
  );
};

export default PromoCodeForm;
