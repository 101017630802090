import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, CssBaseline, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../Components/Input/CustomInput";
import CustomSelect from "../../Components/Select/CustomSelect";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getQuestionTagAll } from "../../redux/action/questionTagAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "20px",
  },
  input: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    fontSize: 12,
    fontFamily: "Oxanium",
    marginLeft: 30,
    marginTop: -10,
    fontWeight: 500,
  },
}));

interface props {
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
  handleSaveQuestion?: any;
  handleCancelQuestion?: any;
  handleEditQuestion?: any;
  editedQuestion?: any;
  questionTagReducer?: any;
}

const AssessmentForm: React.FC<props> = (props) => {
  let { editedQuestion } = props;
  let { questionTags } = useSelector((state: props) => state.questionTagReducer);

  const dispatch = useDispatch();
  const classes = useStyles();
  const initialState: any = {
    questionDetails: "",
    questionTagId: "",
    answer: "",
    choiceA: "",
    choiceB: "",
    choiceC: "",
    choiceD: "",
  };
  const [inputData, setInputData] = useState(initialState);
  const validationSchema = yup.object({
    questionDetails: yup
      .string()
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .required("Please Enter Question Details"),
    choiceA: yup.string().required("Please Enter Choice A"),
    choiceB: yup.string().required("Please Enter Choice B"),
    choiceC: yup.string(),
    choiceD: yup.string(),
    answer: yup
      .string()
      .required("Please Enter Answer")
      // .oneOf(
      //   [yup.ref("choiceA"), yup.ref("choiceB"), yup.ref("choiceC"), yup.ref("choiceD"), null],
      //   "Does not match with choiceA,choiceB,choiceC,choiceD!"
      // ),
      .test("", "Answer Does not match with choiceA, choiceB, choiceC, choiceD!", (item) =>
        item !== undefined
          ? item?.trim().toLowerCase() == inputData.choiceA?.trim().toLowerCase() ||
            item?.trim().toLowerCase() == inputData.choiceB?.trim().toLowerCase() ||
            item?.trim().toLowerCase() == inputData.choiceC?.trim().toLowerCase() ||
            item?.trim().toLowerCase() == inputData.choiceD?.trim().toLowerCase()
          : true
      ),
  });

  useEffect(() => {
    getAllTags();
  }, []);

  const getAllTags = async () => {
    let uri = `${apiEndPoint.GET_QUESTION_TAG_ALL}`;
    await dispatch(getQuestionTagAll(uri));
  };

  useEffect(() => {
    if (editedQuestion) {
      setInputData(editedQuestion);
    }
  }, [editedQuestion]);

  const onSubmit = async () => {
    if (editedQuestion) {
      props.handleEditQuestion(inputData);
    } else {
      props.handleSaveQuestion(inputData);
    }
    setInputData(initialState);
  };

  const handleCancel = () => {
    props.handleCancelQuestion();
    setInputData(initialState);
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const handleInput = (field: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [field]: value,
      };
    });
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };
  return (
    <div>
      <Grid container xs={12} sm={12} md={12} className={classes.root} justify="center">
        <CssBaseline />
        <form style={{ width: "100%" }}>
          <CustomInput
            variant="outlined"
            className={classes.input}
            label="Question Details"
            name="questionDetails"
            id="questionDetails"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.questionDetails}
            error={formik.errors.questionDetails}
            helperText={formik.errors.questionDetails}
          />
          {questionTags.length > 0 ? (
            <CustomSelect
              field="questionTagId"
              selectedValue={inputData.questionTagId}
              label="question Tag"
              data={questionTags}
              handleStateChange={(field: any, value: any) => handleInput(field, value)}
            />
          ) : null}
          <CustomInput
            variant="outlined"
            className={classes.input}
            label="A"
            name="choiceA"
            id="choiceA"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.choiceA}
            error={formik.errors.choiceA}
            helperText={formik.errors.choiceA}
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            label="B"
            name="choiceB"
            id="choiceB"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.choiceB}
            error={formik.errors.choiceB}
            helperText={formik.errors.choiceB}
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            required={false}
            label="C"
            name="choiceC"
            id="choiceC"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.choiceC}
            // error={formik.errors.choiceC}
            // helperText={formik.errors.choiceC}
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            required={false}
            label="D"
            name="choiceD"
            id="choiceD"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.choiceD}
            // error={formik.errors.choiceD}
            // helperText={formik.errors.choiceD}
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            label="Answer"
            name="answer"
            id="answer"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.answer}
            error={formik.errors.answer}
            helperText={formik.errors.answer}
          />

          <Grid>
            <Button variant="contained" color="primary" onClick={formik.handleSubmit} style={{ marginRight: "20px" }}>
              {editedQuestion ? "Update" : "Add"} Question
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default AssessmentForm;
