import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import DataTable from "../../Components/Table/dataTable";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getAllBundlesByCustomFilter, initBundle, deleteBundleById } from "../../redux/action/courseAction";
import AlertMessage from "../../Components/Alert/AlertMessage";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import FormLoader from "../../Components/Loader/formLoader";

interface props {
  url?: any;
  courseReducer?: any;
  loaderReducer?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: "24px",
  },
}));

const BundleList: React.FC<props> = (props) => {
  const classes = useStyles();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [page, setPage] = useState(0);

  let { bundleSelectionList } = useSelector((state: any) => state.courseReducer);
  const { courseLoader } = useSelector((state: any) => state.loaderReducer);
  if (bundleSelectionList && bundleSelectionList.length) {
    bundleSelectionList = bundleSelectionList.map((item) => {
      return { ...item, id: item.courseBundleId };
    });
  }

  const dispatch = useDispatch();
  let { url } = props;
  const history = useHistory();

  const handleEdit = (Id) => {
    handleIniBundle(true);
    let BundleName = Id.bundleName;
    dispatch(HeaderBreadCrumbTitle(BundleName));
    let courseBundleId = Id.courseBundleId;
    history.push(`${url}/${courseBundleId}`);
  };
  const handleIniBundle = async (flag) => {
    await dispatch(initBundle());
    dispatch(enableHeaderTab(flag));
  };

  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_NEWS_HERE";
    dispatch(deleteBundleById(uri, deleteId));
    setVisibleAlert(false);
  };
  const handlePage = (page) => {
    setPage(page);
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete Bundle"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "bundleName",
      headerName: "Bundle Name",
      flex: 1,
    },
    {
      field: "actualPrice",
      headerName: "Actual Price",
      flex: 0.5,
    },
    {
      field: "bundlePrice",
      headerName: "Bundle Price",
      flex: 0.5,
    },
    
    {
      field: "details",
      headerName: "Details",
      flex: 0.5,
      renderCell: (cellValues) => {
        return <span dangerouslySetInnerHTML={{ __html: cellValues.value }} style={{ maxHeight: "79px" }} />;
      },
    },
  ];

  useEffect(() => {
    let uri = `${apiEndPoint.GET_ALL_BUNDLES}`;
    dispatch(getAllBundlesByCustomFilter(uri));
  }, [dispatch]);
  let sliceData = bundleSelectionList?.slice(10 * page);

  const renderTable = () => {
    return (
      <div>
        <DataTable
          page={page}
          data={sliceData}
          totalItems={bundleSelectionList?.length ? bundleSelectionList.length : 0}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePage={handlePage}
        />
      </div>
    );
  };

  return (
    <>
      {courseLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={courseLoader} />
        </div>
      )}
      {!courseLoader && (
        <DivWrapper>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <h1>Bundles</h1>
            <Grid style={{ margin: 24 }}>
              <CustomizedDialogs
                customeIcon={"add"}
                page={"bundle"}
                title={"Add New Bundle"}
                rowData={"null"}
                label="Bundle"
              ></CustomizedDialogs>
            </Grid>
          </div>
          {renderTable()}
          {renderAlert()}
        </DivWrapper>
      )}
    </>
  );
};

export default BundleList;
