import React, { useEffect, useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import { useHistory } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import { deleteFacultyById, getFacultyAll, initFaculty } from "../../redux/action/facultyAction";
import DataTable from "../../Components/Table/dataTable";
import { makeStyles } from "@material-ui/core/styles";
import SearchInput from "../../Components/Input/SearchInput";
import AlertMessage from "../../Components/Alert/AlertMessage";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import FormLoader from "../../Components/Loader/formLoader";
let searchTimer;

interface props {
  url?: any;
  facultyReducer?: any;
  loaderReducer?: any;
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
}));

const FacultyList: React.FC<props> = (props) => {
  let { url } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  let { facultyLoader, formLoader } = useSelector((state: props) => state.loaderReducer);
  let { facultyList, totalItems, currentPage } = useSelector((state: props) => state.facultyReducer);

  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_FACULTY_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "facultyFirstName",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getFacultyAll(uri, args, searchText));
  };

  if (facultyList && facultyList.length) {
    facultyList = facultyList.map((item) => {
      if (item.hasFeatured) {
        return { ...item, id: item.tdcuserId };
      } else {
        return {
          ...item,
          ["hasFeatured"]: "N",
          id: item.tdcuserId,
        };
      }
    });
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "label",
      headerName: "full Name",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.4,
    },
    {
      field: "isDisabled",
      headerName: "Disabled",
      flex: 0.4,
    },
    {
      field: "hasFeatured",
      headerName: " Featured",
      flex: 0.4,
      width: 130,
    },
  ];

  const renderTable = () => {
    return (
      <DataTable
        page={currentPage}
        totalItems={totalItems}
        data={facultyList}
        columns={columns}
        handleEdit={handleEdit}
        handlePage={handlePage}
        handleDelete={handleDelete}
      />
    );
  };

  const initFacultyFunction = async (flag) => {
    await dispatch(initFaculty());
    dispatch(enableHeaderTab(flag));
  };

  const handleEdit = (Id) => {
    initFacultyFunction(true);
    let Title = Id.label;
    dispatch(HeaderBreadCrumbTitle(Title));
    let tdcuserId = Id.tdcuserId;
    history.push(`${url}/${tdcuserId}`);
  };
  const handlePage = (page, search) => {
    getList(page, search);
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };
  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_FACULTY_HERE";
    dispatch(deleteFacultyById(uri, deleteId));
    setVisibleAlert(false);
  };
  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete Faculty"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const renderNotFound = () => {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  };

  return (
    <div>
      {facultyLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={facultyLoader} />
        </div>
      )}
      {!facultyLoader && (
        <DivWrapper>
          <FormLoader visible={formLoader} />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <h1>Faculties</h1>
            <SearchInput handleSearch={handleSearch} search={search} />
            <CustomizedDialogs
              customeIcon={"add"}
              page={"faculty"}
              title={"Add New Faculty"}
              rowData={"null"}
              label="Faculty"
            ></CustomizedDialogs>
          </div>
          {facultyList && facultyList.length >= 1 ? renderTable() : renderNotFound()}
          {renderAlert()}
        </DivWrapper>
      )}
    </div>
  );
};

export default FacultyList;
