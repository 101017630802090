import { reduxConstants } from "./../../constant/Redux";

const initialState = {
  promoCode: null,
  promoCodeList: [],
  promoCodeMapAll: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function promoCodeReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_PROMO_CODE:
      return {
        ...state,
        promoCode: null,
      };
    case reduxConstants.PROMO_CODE_ALL:
      return {
        ...state,
        promoCodeList: action.payload,
      };

    case reduxConstants.ALL_PROMO_CODE_LIST: {
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        promoCodeList: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };
    }

    case reduxConstants.SAVE_PROMO_CODE: {
      let { promoCodeList } = state;
      return {
        ...state,
        promoCodeList: [...promoCodeList, action.payload[0]],
      };
    }
    case reduxConstants.UPDATE_PROMO_CODE: {
      let { promoCodeList, promoCode }: any = state;
      promoCodeList = promoCodeList.map((item, i) => {
        if (item.courseCouponId === promoCode.courseCouponId) {
          return action.payload[0];
        } else {
          return item;
        }
      });
      return {
        ...state,
        promoCode: action.payload[0],
        promoCodeList,
      };
    }

    case reduxConstants.DELETE_PROMO_CODE: {
      let { promoCodeList }: any = state;
      let newPromoCodeList: any = [];
      promoCodeList.forEach((item, i) => {
        if (item.courseCouponId !== action.payload.courseCouponId) {
          newPromoCodeList = [...newPromoCodeList, item];
        }
      });
      return {
        ...state,
        promoCode: action.payload[0],
        promoCodeList: newPromoCodeList,
      };
    }

    case reduxConstants.PROMO_CODE_MAP_ALL:
      return {
        ...state,
        promoCodeMapAll: action.payload,
      };
    case reduxConstants.PROMO_CODE_DETAILS:
      return {
        ...state,
        promoCode: action.payload[0],
      };
    case reduxConstants.SET_SELECTED_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    default:
      return state;
  }
}
