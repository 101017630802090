import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomAccordion } from "../../Components/Accordion/CustomAccordion";
import CustomLabel from "../../Components/Label/CustomLabel";
import FormLoader from "../../Components/Loader/formLoader";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getCourseSectionList } from "../../redux/action/courseAction";

interface Props {
  courseReducer?: any;
  loaderReducer?: any;
}

const CourseContent: React.FC<Props> = (props) => {
  const [active, setActive] = React.useState(0);
  const [activeSubSection, setActiveSubSection] = React.useState(0);
  const handleclick = (index) => {
    if (active === index) {
      setActive(0);
    } else {
      setActive(index);
    }
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const { courseSectionList } = useSelector(
    (state: Props) => state.courseReducer
  );
  let { sectionLoader } = useSelector((state: any) => state.loaderReducer);

  useEffect(() => {
    if (id > 0) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_LIST}=${id}`;
      dispatch(getCourseSectionList(uri));
    }
  }, [id, dispatch]);

  const handleclickSubSection = (index) => {
    if (index === activeSubSection) {
      setActiveSubSection(0);
    } else {
      setActiveSubSection(index);
    }
  };

  useEffect(() => {
    setActiveSubSection(0);
  }, [active]);

  return (
    <>
      {!courseSectionList ? <CustomLabel label="No Sections Added!" /> : null}
      <FormLoader visible={sectionLoader} />
      {!sectionLoader && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              margin: "20px 0",
            }}
          >
            <CustomizedDialogs
              customeIcon={"add"}
              page={"section"}
              title={"Add Section"}
              rowData={null}
              data={null}
              label="Section"
              sectionLength={courseSectionList?.length}
              style={{
                color: "#fff!important",
                backgroundColor: "#2196f3!important",
              }}
            ></CustomizedDialogs>
          </div>
          {courseSectionList?.map((item, index) => {
            return (
              <>
                <CustomAccordion
                  id={index + 1}
                  active={active}
                  handleclick={handleclick}
                  title={item?.courseSection?.sectionTitle}
                  page="sub-section"
                  iconName={"add"}
                  btnTitle="Add Sub Section"
                  rowData={item?.courseSection}
                  label="Section"
                  data={item}
                  itemIndex={index}
                  parentData={item}
                  sectionLength={item?.childCourseSection?.length}
                >
                  {item?.childCourseSection?.map((eachItem, i) => {
                    var iconType = "";

                    if (
                      (eachItem &&
                        eachItem.courseArtifactList &&
                        eachItem.courseArtifactList[0] &&
                        eachItem.courseArtifactList[0].path &&
                        eachItem.courseArtifactList[0].path.split(".").pop() ==
                          "jpeg") ||
                      (eachItem &&
                        eachItem.courseArtifactList &&
                        eachItem.courseArtifactList[0] &&
                        eachItem.courseArtifactList[0].path &&
                        eachItem.courseArtifactList[0].path.split(".").pop() ==
                          "png")
                    ) {
                      iconType = "image";
                    } else if (
                      eachItem &&
                      eachItem.courseArtifactList &&
                      eachItem.courseArtifactList[0] &&
                      eachItem.courseArtifactList[0].path &&
                      eachItem.courseArtifactList[0].path.split(".").pop() ==
                        "mp4"
                    ) {
                      iconType = "video";
                    } else if (
                      eachItem &&
                      eachItem.courseArtifactList &&
                      eachItem.courseArtifactList[0] &&
                      eachItem.courseArtifactList[0].path &&
                      eachItem.courseArtifactList[0].path.split(".").pop() ==
                        "pdf"
                    ) {
                      iconType = "pdf";
                    } else if (
                      eachItem.courseAssignment && eachItem.courseAssignment.isaFinalAssessment == "N"
                    ) {
                      iconType = "exercise";
                    } else if (
                      eachItem.courseAssignment && eachItem.courseAssignment.isaFinalAssessment ==  "Y" 
                    ) {
                      iconType = "csv";
                    }
                     else {
                      iconType = "";
                    }

                    return (
                      <CustomAccordion
                        id={i + 1}
                        active={activeSubSection}
                        handleclick={handleclickSubSection}
                        title={eachItem?.courseSection?.sectionTitle}
                        page="content"
                        iconName={"add"}
                        btnTitle="Add Content"
                        rowData={eachItem.courseSection}
                        label="Sub Section"
                        data={eachItem}
                        parentData={item}
                        itemIndex={i}
                        sectionLength={item?.childCourseSection?.length}
                        extType={iconType}
                      ></CustomAccordion>
                    );
                  })}
                </CustomAccordion>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CourseContent;
