import { reduxConstants } from "../../constant/Redux";

const initialState = {
  userList: [],
  user: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  purchasedCourses: [],
  purchasedBundles: [],
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_USER: {
      return {
        ...state,
        user: null,
        purchasedCourses:[],
        purchasedBundles: [],
      };
    }
    case reduxConstants.INIT_USER_LIST: {
      return {
        ...state,
        currentPage: 0,
      };
    }

    case reduxConstants.SAVE_USER:
      return {
        ...state,
        userList: [...state.userList, action.payload[0]],
      };

    case reduxConstants.ALL_USER_LIST:
      let userData = action.payload[0]?.dtoResults;
      if (userData && userData.length > 0) {
        userData = userData.map((element: any) => {
          return {
            ...element,
            fullName: `${element.firstName} ${element.lastName}`,
            value: element.tdcuserId,
          };
        });
      }

      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        userList: userData,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };

    case reduxConstants.USER_BY_ID:
      return {
        ...state,
        user: action.payload,
      };

    case reduxConstants.B2B_GET_PURCHASED_DATA:
      let TempPurchasedCourses = action.payload.filter((item) => item.bundleId == -1);
      let TempPurchasedBundles = action.payload.filter((item) => item.courseId == -1);
      return {
        ...state,
        purchasedCourses: TempPurchasedCourses,
        purchasedBundles: TempPurchasedBundles,
      };

    default:
      return state;
  }
}
