import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getCourseSectionList, saveCourseSection } from "../../redux/action/courseAction";
import { COURSE_SECTION_SECTION_TITLE_LENGTH, COURSE_SECTION_SECTION_DETAILS_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingRight: "20px",
  },

  input: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  viewLabel: {
    fontSize: 15,
    fontFamily: "Oxanium,cursive",
    fontWeight: "bold",
  },
}));
interface props {
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
  sectionLength?: any;
  data?: any;
  type?: any;
}

const SectionForm: React.FC<props> = (props) => {
  let { togleEvent, isModal, rowData, sectionLength, type } = props;
  const Snackbar = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  let { id } = useParams();

  const initialState: any = {
    allowPublicView: rowData?.allowPublicView ? rowData.allowPublicView : null,
    assignmentId: rowData?.assignmentId ? rowData.assignmentId : 0,
    courseId: id ? Number(id) : null,
    courseSectionId: rowData?.courseSectionId ? rowData.courseSectionId : 0,
    parentSectionId: rowData?.parentSectionId ? rowData.parentSectionId : 0,
    sectionDetails: rowData?.sectionDetails ? rowData.sectionDetails : "",
    sectionDurationHours: rowData?.sectionDurationHours ? rowData.sectionDurationHours : null,
    sectionDurationMinutes: rowData?.sectionDurationMinutes ? rowData.sectionDurationMinutes : null,
    sectionIndex: rowData?.sectionIndex ? rowData.sectionIndex : 0,
    sectionTitle: rowData?.sectionTitle ? rowData.sectionTitle : "",
  };
  const [inputData, setInputData] = useState(initialState);
  let [editorState, setEditorState] = useState<any>(initialState);

  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  const validationSchema = yup.object({
    sectionTitle: yup
      .string()
      .required("Please Enter Title!")
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .test(
        "len",
        `Characters Length should be less than ${COURSE_SECTION_SECTION_TITLE_LENGTH}`,
        (val: any) => val?.toString().length < COURSE_SECTION_SECTION_TITLE_LENGTH
      ),
    sectionDetails: yup
      .string()
      .required("Please Enter Description!")
      .test(
        "len",
        `Characters Length should be less than ${COURSE_SECTION_SECTION_DETAILS_LENGTH}`,
        (val: any) => val?.toString().length < COURSE_SECTION_SECTION_DETAILS_LENGTH
      ),
  });

  useEffect(() => {
    if (rowData) {
      setInputData(rowData);
    }
  }, [rowData]);

  useEffect(() => {
    if (togleEvent !== 0 && isModal) {
      formik.handleSubmit();
    } // eslint-disable-next-line
  }, [togleEvent, isModal]);

  const onSubmit = async () => {
    addSection();
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const addSection = async () => {
    let uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
    let response: any = await dispatch(saveCourseSection(uri, inputData, ""));
    if (response.status) {
      props.closeModal();
      Snackbar.show(rowData?.courseSectionId ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        let uri = `${apiEndPoint.GET_COURSE_SECTION_LIST}=${id}`;
        dispatch(getCourseSectionList(uri));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const handleInput = (field: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [field]: value,
      };
    });
  };

  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };
  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };
  return (
    <div>
      <FormLoader visible={formLoader} />
      <Grid container xs={12} sm={12} md={12} className={classes.root} justify="center">
        <CssBaseline />
        <form
          style={{
            pointerEvents: type === "view" ? "none" : "auto",
            width: "100%",
            background: "#fff",
            padding: "10px",
          }}
        >
          {type === "view" && (
            <>
              <ul>
                <li>
                  {" "}
                  <label className={classes.viewLabel}>Section Title:</label>{" "}
                  <p dangerouslySetInnerHTML={{ __html: inputData.sectionTitle }} />
                </li>
                <hr />

                <li>
                  {" "}
                  <label className={classes.viewLabel}>Section Description:</label>{" "}
                  <p dangerouslySetInnerHTML={{ __html: inputData.sectionDetails }} />
                </li>
              </ul>
            </>
          )}

          {type !== "view" && (
            <>
              <CustomInput
                variant="outlined"
                className={classes.input}
                label=" Title"
                name="sectionTitle"
                id="title"
                handleState={(field: any, value: any) => {
                  handleInput(field, value);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                value={inputData.sectionTitle}
                error={formik.errors.sectionTitle}
                helperText={formik.errors.sectionTitle}
              />
              <>
                <EditorInput
                  title="Enter Description *"
                  field="sectionDetails"
                  value={inputData.sectionDetails}
                  onBlur={() => handleBlurEditor("sectionDetails")}
                  errorFound={formik.errors.sectionDetails}
                  handleDataChange={handleEditorState}
                />
                {formik.errors.sectionDetails ? (
                  <div className={classes.textDanger}>{formik.errors.sectionDetails}</div>
                ) : null}
              </>
            </>
          )}
        </form>
      </Grid>
    </div>
  );
};

export default SectionForm;
