import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { saveUserDetails, getUserDetailsById, getFacultyAll, initFaculty } from "../../redux/action/facultyAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { SubmitBtn } from "../../Components/Card/Card";
import { USER_DETAILS_ABOUT_ME_LENGTH, USER_DETAILS_DESIGNATION_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import enAU from "date-fns/esm/locale/en-AU/index.js";
import { enableHeaderTab } from "../../redux/action/configAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
}));

interface props {
  facultyReducer?: any;
}

const FacultyDetails: React.FC<props> = () => {
  const Snackbar = useSnackbar();

  const history = useHistory();
  const dispatch = useDispatch();
  const classes: any = useStyles();
  let { id } = useParams();
  const initialState: any = {
    designation: "",
    aboutme: "",
    tdcUserId: id,
    tdcUserdetailId: 0,
  };
  const [facultyDetails, setFacultyDetails] = useState(initialState);
  let [editorState, setEditorState] = useState<any>(initialState);

  let { facultyabout, currentPage } = useSelector((state: props) => state.facultyReducer);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  const validationSchema = yup.object({
    designation: yup
      .string()
      .required("Please Enter Designation!")
      .strict(false)
      // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .trim()
      .test(
        "len",
        `Characters Length should be less than ${USER_DETAILS_DESIGNATION_LENGTH}`,
        (val: any) => val?.toString().length <= USER_DETAILS_DESIGNATION_LENGTH
      ),
    aboutme: yup
      .string()
      .required("Please Enter AboutMe!")
      .test(
        "len",
        `HTML Content of Short Details should not be greater than ${USER_DETAILS_ABOUT_ME_LENGTH} characters`,
        (val: any) => val?.toString().length <= USER_DETAILS_ABOUT_ME_LENGTH
      ),
  });

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }
    addFacultyDetails();
  };

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_USER_DETAILS_BY_ID}?userId=${id}`;
      dispatch(getUserDetailsById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    initFacultyFunction(true);
    if (facultyabout && id) {
      setFacultyDetails({
        tdcUserId: id ? id : 0,
        designation: facultyabout?.designation ? facultyabout?.designation : "",
        aboutme: facultyabout?.aboutme ? facultyabout?.aboutme : " ",
        tdcUserdetailId: facultyabout.tdcUserdetailId,
      });
    }
  }, [facultyabout, id]);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: facultyDetails,
    validationSchema,
    onSubmit,
  });

  const fetchFacultyList = (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_FACULTY_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "firstName",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }

    dispatch(getFacultyAll(uri, args, ""));
  };
  const addFacultyDetails = async () => {
    const addDetails = {
      tdcUserId: id ? id : 0,
      designation: facultyDetails?.designation,
      aboutme: facultyDetails?.aboutme,
      tdcUserdetailId: facultyDetails.tdcUserdetailId,
    };

    let uri = `${apiEndPoint.SAVE_USER_DETAILS}`;
    let response: any = await dispatch(saveUserDetails(uri, addDetails));
    if (response.status) {
      history.push("/home/faculty");
      Snackbar.show("Updated Successfully", "success");
      // setTimeout(() => {
      //   fetchFacultyList(currentPage, "");
      // }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };
  const handleInput = (name: any, value: any) => {
    setFacultyDetails((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...facultyDetails, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setFacultyDetails({ ...facultyDetails, ...editorState, ...newEditorState });
  };

  const initFacultyFunction = async (flag) => {
    await dispatch(initFaculty());
    dispatch(enableHeaderTab(flag));
  };
  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <div>
      <FormLoader visible={formLoader} />
      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        {/* {renderSnackbar("Save Successfully", successAlert, "success")}
        {renderSnackbar("Something Went Wrong", errorAlert, "error")} */}
        <form>
          <CustomInput
            id="designation"
            variant="outlined"
            fullWidth
            label="Faculty designation"
            name="designation"
            value={facultyDetails.designation}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.designation}
            helperText={formik.errors.designation}
            autoFocus
          />
          <EditorInput
            title="About me *"
            field="aboutme"
            value={facultyDetails.aboutme}
            onBlur={() => handleBlurEditor("aboutme")}
            errorFound={formik.errors.aboutme}
            handleDataChange={handleEditorState}
          />
          {formik.errors.aboutme ? <div className={classes.textDanger}>{formik.errors.aboutme}</div> : null}
          {id ? (
            <Grid>
              <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                Update
              </SubmitBtn>
            </Grid>
          ) : (
            <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
              Submit
            </SubmitBtn>
          )}
        </form>
      </Grid>
    </div>
  );
};

export default FacultyDetails;
