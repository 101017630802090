import React from "react";
import { Breadcrumbs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
  configReducer?: any;
}

const HeaderBreadCrumb: React.FC<Props> = (props) => {
  const location = useLocation();
  const { headerBreadCrumbTitle } = useSelector((state: any) => state.configReducer);

  let routeArray = location?.pathname?.split("/");
  let moduleRoute = "";
  let moduleName = "";
  let internalPageRoute = "";
  let internalPageName = "";
  if (routeArray && routeArray.length > 0) {
    moduleRoute = `${routeArray[1]}`;
  }
  if (routeArray && routeArray.length > 2) {
    moduleName = routeArray[2].charAt(0).toUpperCase() + routeArray[2].slice(1);
    moduleRoute = `/home/${routeArray[2]}`;
  }
  if (routeArray && routeArray.length > 3) {
    internalPageName = headerBreadCrumbTitle?.charAt(0).toUpperCase() + headerBreadCrumbTitle?.slice(1);
    //internalPageRoute = `${moduleRoute}/${routeArray[3]}`;
    internalPageRoute = `${moduleRoute}/${headerBreadCrumbTitle}`;
  }

  return (
    <div
      style={{
        marginBottom: "15px",
        marginLeft: "20px",
      }}
    >
      <Breadcrumbs aria-label="breadcrumb" className="brdcrmb">
        {routeArray && routeArray.length > 2 ? (
          <Link underline="hover" color="inherit" to="/home">
            Home
          </Link>
        ) : null}
        {moduleName ? (
          <Link underline="hover" color="inherit" to={`${moduleRoute}`}>
            {moduleName}
          </Link>
        ) : null}
        {internalPageName ? (
          // <Link underline="hover" color="inherit" to={`${internalPageRoute}`}>
          <div>{internalPageName}</div>
        ) : // </Link>
        null}
      </Breadcrumbs>
    </div>
  );
};

export default HeaderBreadCrumb;
