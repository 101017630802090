import { reduxConstants } from "../../constant/Redux";

const initialState = {
  referralCodeById: null,
  generatedReferralCode: null,
  referralCodes: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function referralCodeReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_REFERRAL_CODE: {
      return {
        ...state,
        referralCodeById: null,
      };
    }
    case reduxConstants.REFERRAL_CODE_ALL:
      return {
        ...state,
        referralCodes: action.payload,
      };

    case reduxConstants.ALL_REFERRAL_CODE_LIST: {
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        referralCodes: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };
    }
    case reduxConstants.REFERRAL_CODE_BY_ID:
      return {
        ...state,
        referralCodeById: action.payload,
      };

    case reduxConstants.GEN_REFERRAL_CODE:
      return {
        ...state,
        generatedReferralCode: action.payload,
      };

    case reduxConstants.DELETE_REFERRAL_CODE_BY_ID: {
      let { referralCodes }: any = state;
      let newReferralCodes: any = [];
      referralCodes.forEach((item, i) => {
        if (item.tdcReferralCodeId !== action.payload) {
          newReferralCodes = [...newReferralCodes, item];
        }
      });
      return {
        ...state,
        referralCodes: newReferralCodes,
      };
    }
    default:
      return state;
  }
}
