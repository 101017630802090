import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormLabel } from "@material-ui/core";
import profile from "../../assets/img/profile.png";

interface Props {
  handlePickImage: any;
  image: any;
  imagePath: any;
  buttonName?: any;
}

const useStyles = makeStyles(() => ({
  btnWrapper: {
    position: "relative",
    overflow: "hidden",
    textAlign: "center",
  },
  btn: {
    border: "2px solid gray",
    color: "gray",
    backgroundColor: "white",
    padding: "8px 20px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const ImagePicker: React.FC<Props> = (props) => {
  let { image, imagePath, buttonName }: any = props;
  const classes = useStyles();
  let fileRef: any = React.createRef();

  const upload = (e) => {
    e.preventDefault();
    fileRef?.current.click();
  };

  const fileSelectHandler = (e) => {
    props.handlePickImage(e);
  };

  return (
    <div className={classes.btnWrapper}>
      <div>
        <input
          accept="image/*"
          ref={fileRef}
          id="pickImageInput"
          type="file"
          name="myfile"
          hidden
          onChange={fileSelectHandler}
        />
        <button id="pickImageButton" className={classes.btn} onClick={upload}>
          {buttonName ? buttonName : " Upload an Image"}
        </button>
      </div>
      {image ? (
        <Grid style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
          <FormLabel>Attached File: {imagePath}</FormLabel>
          <img src={image} alt="" height="350" width="100%" style={{ marginTop: 20 }} />
        </Grid>
      ) : (
        <img src={profile} alt="" height="300" width="80%" style={{ marginTop: 20 }} />
      )}
    </div>
  );
};

export default ImagePicker;
