import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import DataTable from "../../Components/Table/dataTable";
import { getBlogsByCustomFilter, initBlog, deleteBlogById } from "../../redux/action/blogAction";
import { DivWrapper } from "../../Components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import SearchInput from "../../Components/Input/SearchInput";
import AlertMessage from "../../Components/Alert/AlertMessage";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import FormLoader from "../../Components/Loader/formLoader";
let searchTimer;

interface props {
  url?: any;
  blogReducer?: any;
  loaderReducer?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
}));

const BlogList: React.FC<props> = (props) => {
  let { url } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [search, setSearch] = useState<any>("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  let { blogList, totalItems, currentPage } = useSelector((state: props) => state.blogReducer);
  const { blogLoader, formLoader } = useSelector((state: any) => state.loaderReducer);

  if (blogList && blogList.length) {
    blogList = blogList.map((item) => {
      return { ...item, id: item.tdcblogId };
    });
  }

  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_BLOGS_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getBlogsByCustomFilter(uri, args, searchText));
  };
  const initBlogFunction = async (flag) => {
    await dispatch(initBlog());
    dispatch(enableHeaderTab(flag));
  };

  const handleEdit = (Id) => {
    initBlogFunction(true);
    let Title = Id.title;
    dispatch(HeaderBreadCrumbTitle(Title));
    let tdcblogId = Id.tdcblogId;
    history.push(`${url}/${tdcblogId}`);
  };
  const handlePage = (page, search) => {
    getList(page, search);
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };

  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_NEWS_HERE";
    dispatch(deleteBlogById(uri, deleteId));
    setVisibleAlert(false);
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete Blog"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "shortDetails",
      headerName: "Short Details",
      flex: 1,
      renderCell: (cellValues) => {
        return <span dangerouslySetInnerHTML={{ __html: cellValues.value }} style={{ maxHeight: "79px" }} />;
      },
    },
  ];
  const renderTable = () => {
    return (
      <DataTable
        data={blogList}
        columns={columns}
        page={currentPage}
        totalItems={totalItems}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handlePage={handlePage}
        loading={blogLoader}
      />
    );
  };
  const renderNotFound = () => {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  };
  return (
    <div>
      {blogLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={blogLoader} />
        </div>
      )}
      {!blogLoader && (
        <DivWrapper>
          <FormLoader visible={formLoader} />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <h1>Blogs</h1>
            <SearchInput handleSearch={handleSearch} search={search} />
            <CustomizedDialogs
              customeIcon={"add"}
              page={"blog"}
              title={"Add New Blog"}
              rowData={"null"}
              label="Blog"
            ></CustomizedDialogs>
          </div>
          {blogList && blogList.length >= 1 ? renderTable() : renderNotFound()}
          {renderAlert()}
        </DivWrapper>
      )}
    </div>
  );
};

export default BlogList;
