import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initPromoCode() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_PROMO_CODE,
    });
  };
}

export function getPromoCodeAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(loader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(loader(false));
        dispatch(success(data));
      } else {
        dispatch(loader(false));
      }
    } catch (e: any) {
      dispatch(loader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.PROMO_CODE_ALL,
      payload: data,
    };
  }
}

export function savePromoCode(uri: any, args: any, id: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        if (id) {
          dispatch(successEdit(data));
        }
        dispatch(successAdd(data));
        dispatch(formLoader(false));
        return { status: true, result: data[0] };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(loader(false));
      return { status: false, result: e.message };
    }
  };
  function successAdd(data: any) {
    return {
      type: reduxConstants.SAVE_PROMO_CODE,
      payload: data,
    };
  }
  function successEdit(data: any) {
    return {
      type: reduxConstants.UPDATE_PROMO_CODE,
      payload: data,
    };
  }
}

export function saveMapPromoCode(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        return { status: true, result: data[0] };
      } else {
        dispatch(loader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_PROMO_CODE_MAP,
      payload: data,
    };
  }
}

export function getPromoCodeMapAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(loader(true));
      const { httpCode, data, error } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(loader(false));
        return { status: true, result: data };
      } else {
        dispatch(loader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(loader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.PROMO_CODE_MAP_ALL,
      payload: data,
    };
  }
}

export function addPromoCodeMapList(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        return { status: true, result: data };
      } else {
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ADD_PROMO_CODE_MAP_LIST,
      payload: data,
    };
  }
}

export function getPromoCodeDetails(uri) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
      dispatch(loader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.PROMO_CODE_DETAILS,
      payload: data,
    };
  }
}

export function getPromoCodeByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(loader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(loader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(loader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(loader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ALL_PROMO_CODE_LIST,
      payload: data,
    };
  }
}

export function loader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.PROMO_CODE_LOADER,
      payload: flag,
    });
  };
}

export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}


export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}

export function deletePromoCode(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_PROMO_CODE,
      payload: data,
    };
  }
}

export function setSelectedPromoCode(item: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.SET_SELECTED_PROMO_CODE,
      payload: item,
    });
  };
}
