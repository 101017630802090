import React, { useEffect, useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import { useHistory } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuestionTag,
  getQuestionTagAll,
  getQuestionTagByCustomFilter,
  initQuestionTag,
} from "../../redux/action/questionTagAction";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../Components/Table/dataTable";
import { Grid } from "@material-ui/core";
import AlertMessage from "../../Components/Alert/AlertMessage";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import FormLoader from "../../Components/Loader/formLoader";
import { HeaderBreadCrumbTitle } from "../../redux/action/configAction";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(2, 2, 2),
  },
}));
interface props {
  url?: any;
  questionTagReducer?: any;
  loaderReducer?: any;
}
const TagList: React.FC<props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<any>("");
  let { questionTags, totalItems, currentPage } = useSelector((state: props) => state.questionTagReducer);
  let { questionTagLoader } = useSelector((state: props) => state.loaderReducer);
  if (questionTags && questionTags.length) {
    questionTags = questionTags.map((data: any) => {
      return { ...data, id: data.questionTagId };
    });
  }

  let { url } = props;

  // useEffect(() => {
  //   let uri = `${apiEndPoint.GET_QUESTION_TAG_ALL}`;
  //   dispatch(getQuestionTagAll(uri));
  // }, [dispatch]);

  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_QUESTION_TAG_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getQuestionTagByCustomFilter(uri, args, searchText));
  };

  let columns = [
    {
      field: "questionTagId",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "tagName",
      headerName: "Tag Name",
      flex: 2,
    },
  ];
  const handlePage = (page) => {
    // setPage(page);
    getList(page, search);
  };

  const handleEdit = (id) => {
    initTag();
    let Title = id.label;
    dispatch(HeaderBreadCrumbTitle(Title));
    let tagId = id.questionTagId;
    history.push(`${url}/${tagId}`);
  };

  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };
  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let id = deleteId;
    let uri = `${apiEndPoint.DELETE_QUESTION_TAG}?questionTagId=${id}`;
    dispatch(deleteQuestionTag(uri));
    setVisibleAlert(false);
  };
  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete  QuestionTag"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const initTag = () => {
    dispatch(initQuestionTag());
  };

  // let sliceData = questionTags.slice(10 * page);

  return (
    <>
      {questionTagLoader && <FormLoader visible={questionTagLoader} />}
      {!questionTagLoader && (
        <DivWrapper>
          <Grid
            container
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <h1>Question Tags</h1>
            <CustomizedDialogs
              customeIcon={"add"}
              page={"questionTag"}
              title={"Add New Question Tag"}
              rowData={"null"}
              label="Question Tag"
            ></CustomizedDialogs>
          </Grid>

          <DataTable
            page={currentPage}
            totalItems={totalItems}
            data={questionTags}
            columns={columns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handlePage={handlePage}
          />

          {renderAlert()}
        </DivWrapper>
      )}
    </>
  );
};

export default TagList;
