import React from "react";
import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog } from "@material-ui/core";

interface Props {
  title: string;
  message: string;
  visibleAlert: boolean;
  handleCancelAlert: any;
  handleConfirmAlert: any;
}
const AlertMessage: React.FC<Props> = (props) => {
  let { title, message } = props;

  const handleCancelAlert = () => {
    props.handleCancelAlert();
  };

  const handleConfirmAlert = () => {
    props.handleConfirmAlert();
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCancelAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAlert}>Cancel</Button>
          <Button onClick={handleConfirmAlert} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertMessage;
