import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import DataTable from "../../Components/Table/dataTable";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getNewsAll, initNews, deleteNewsById } from "../../redux/action/newsAction";
import SearchInput from "../../Components/Input/SearchInput";
import AlertMessage from "../../Components/Alert/AlertMessage";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import FormLoader from "../../Components/Loader/formLoader";
let searchTimer;

interface props {
  url?: any;
  newsReducer?: any;
  loaderReducer?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: "24px",
  },
}));

const NewsList: React.FC<props> = (props) => {
  const [search, setSearch] = useState<any>("");
  const classes = useStyles();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  let { newsList, totalItems, currentPage } = useSelector((state: any) => state.newsReducer);
  const { newsLoader, formLoader } = useSelector((state: any) => state.loaderReducer);
  if (newsList && newsList.length) {
    newsList = newsList.map((item) => {
      return { ...item, id: item.tdcNewsId };
    });
  }

  const dispatch = useDispatch();
  let { url } = props;
  const history = useHistory();

  const initNewsBlog = async (flag) => {
    await dispatch(initNews());
    dispatch(enableHeaderTab(flag));
  };

  const handleEdit = (Id) => {
    initNewsBlog(true);
    let Title = Id.title;
    dispatch(HeaderBreadCrumbTitle(Title));
    let tdcNewsId = Id.tdcNewsId;
    history.push(`${url}/${tdcNewsId}`);
  };

  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_NEWS_HERE";
    dispatch(deleteNewsById(uri, deleteId));
    setVisibleAlert(false);
  };
  const handlePage = (page) => {
    getList(page, search);
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete News"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },

    {
      field: "shortDetails",
      headerName: "Short Details",
      flex: 1,
      renderCell: (cellValues) => {
        return <span dangerouslySetInnerHTML={{ __html: cellValues.value }} style={{ maxHeight: "79px" }} />;
      },
    },
  ];

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_NEWS_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getNewsAll(uri, args, searchText));
  };

  useEffect(() => {
    getList(currentPage, "");
    // eslint-disable-next-line
  }, [currentPage]);
  const renderTable = () => {
    return (
      <DataTable
        page={currentPage}
        totalItems={totalItems}
        data={newsList}
        columns={columns}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handlePage={handlePage}
        loading={newsLoader}
      />
    );
  };
  const renderNotFound = () => {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  };
  return (
    <>
      {newsLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={newsLoader} />
        </div>
      )}
      {!newsLoader && (
        <DivWrapper>
          <FormLoader visible={formLoader} />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <h1>News</h1>
            <SearchInput handleSearch={handleSearch} search={search} />
            <Grid style={{ margin: 24 }}>
              <CustomizedDialogs
                customeIcon={"add"}
                page={"News"}
                title={"Add New News"}
                rowData={"null"}
                label="News"
              ></CustomizedDialogs>
            </Grid>
          </div>
          {newsList && newsList.length >= 1 ? renderTable() : renderNotFound()}
          {renderAlert()}
        </DivWrapper>
      )}
    </>
  );
};

export default NewsList;
