import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import * as yup from "yup";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getNewsAll, saveNews } from "../../redux/action/newsAction";
import { getNewsDetailsById } from "../../redux/action/newsAction";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { enableHeaderTab } from "../../redux/action/configAction";
import { NEWS_TITLE_LENGTH, NEWS_SHORT_DETAILS_LENGTH, NEWS_DETAILS_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  input: {
    margin: "0",
  },
  form: {
    width: "100%",
  },
  fdataimg: {
    paddingTop: "40px",
    paddingBottom: "15px",
  },
  profile: {
    padding: theme.spacing(50, 14, 50, 14),
  },
  cancel: {
    backgroundColor: "red",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  submit: {
    backgroundColor: "blue",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  disable: {
    marginBottom: 10,
  },
}));

interface props {
  url?: any;
  newsReducer?: any;
  togleEvent?: any;
  isModal?: any;
  closeModal?: any;
}

const initialState: any = {
  title: "",
  shortDetails: "",
  details: "",
  picture: "",
  disabled: "N",
};

const NewsForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  let [inputData, setInputData] = useState<any>(initialState);
  let [editorState, setEditorState] = useState<any>(initialState);
  let { id } = useParams();
  let { newsDetails, currentPage } = useSelector((state: props) => state.newsReducer);
  let { formLoader } = useSelector((state: any) => state.loaderReducer);

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("Please Enter Tilte!")
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .test(
        "len",
        `Characters Length should  not be greater than ${NEWS_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= NEWS_TITLE_LENGTH
      ),
    shortDetails: yup
      .string()
      .required("Please Enter Short Details!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of Short Details should not be greater than ${NEWS_SHORT_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= NEWS_SHORT_DETAILS_LENGTH
      ),
    details: yup
      .string()
      .required("Please Enter Details!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of Details should not be greater than ${NEWS_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= NEWS_DETAILS_LENGTH
      ),
  });

  const fetchNewsList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_NEWS_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    await dispatch(getNewsAll(uri, args, searchText));
  };

  const onSubmit = async () => {
    let { title, shortDetails, details, disabled } = inputData;
    const addNewsData = {
      tdcNewsId: id ? Number(id) : 0,
      title,
      shortDetails,
      details,
      isDisabled: disabled,
      picture: inputData.picture ? inputData.picture : "",
    };
    let uri = `${apiEndPoint.SAVE_NEWS}`;
    let response: any = await dispatch(saveNews(uri, addNewsData));
    let { status } = response;
    if (status) {
      if (id) {
        history.push({ pathname: `/home/news` });
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        if (!id) {
          if (currentPage == 0) {
            fetchNewsList(currentPage, "");
          }
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_NEWS_BY_ID}?newsId=${id}`;
      dispatch(getNewsDetailsById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && newsDetails) {
      let { shortDetails, title, details, isDisabled, picture } = newsDetails;

      setInputData({
        tdcNewsId: id ? id : 0,
        title,
        shortDetails,
        details,
        disabled: isDisabled,
        picture,
      });
    }
  }, [id, newsDetails]);

  const handleInput = (name: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleDisabled = (field, event) => {
    let { checked } = event.target;
    setInputData((preval) => {
      return {
        ...preval,
        [field]: checked === true ? "Y" : "N",
      };
    });
  };

  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <>
      {formLoader && <FormLoader visible={formLoader} />}
      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <div className={classes.disable}>
            <CustomSwitch
              field="disabled"
              handleStateChange={handleDisabled}
              checked={inputData.disabled === "Y"}
              label="Disabled"
            />
          </div>
          <CustomInput
            variant="outlined"
            className={classes.input}
            fullWidth
            label="News Title"
            name="title"
            id="title"
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputData.title}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            error={formik.errors.title}
            helperText={formik.errors.title}
            autoFocus
          />
          <>
            <EditorInput
              title="Enter Short Details *"
              field="shortDetails"
              value={inputData.shortDetails}
              onBlur={() => handleBlurEditor("shortDetails")}
              errorFound={formik.errors.shortDetails}
              handleDataChange={handleEditorState}
            />
            {formik.errors.shortDetails ? <div className={classes.textDanger}>{formik.errors.shortDetails}</div> : null}
          </>
          <>
            <EditorInput
              title="Enter All Details *"
              field="details"
              value={inputData.details}
              onBlur={() => handleBlurEditor("details")}
              errorFound={formik.errors.details}
              handleDataChange={handleEditorState}
            />
            {formik.errors.details ? <div className={classes.textDanger}>{formik.errors.details}</div> : null}
          </>
          {id ? (
            <Grid>
              <BtnSection>
                <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                  Update
                </SubmitBtn>
              </BtnSection>
            </Grid>
          ) : null}
        </form>
      </Grid>
    </>
  );
};
export default NewsForm;
