import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ImagePicker from "../../Components/FilePicker/ImagePicker";
import { useParams } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitBtn, BtnSection } from "../../Components/Card/Card";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import { getCourseById, uploadCoursePic } from "../../redux/action/courseAction";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  url?: any;
  courseReducer?: any;
}
const initialState: any = {
  courseImagePath: "",
};

const UploadCourseImage: React.FC<Props> = () => {
  const Snackbar = useSnackbar();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);
  let [updateCourse, setUpdateCourse] = useState(false);
  let [imagePreview, setImagePreview] = useState<any>(null);
  let [imagePath, setImagePath] = useState(null);
  const classes = useStyles();
  let { detailCourse,course } = useSelector((state: Props) => state.courseReducer);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  let courseDetails: any = detailCourse && detailCourse.length > 0 ? detailCourse[0] : null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateCourse) {
      createProfilePic();
    }
  };

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_COURSE_BY_ID}=${id}`;
      dispatch(getCourseById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (courseDetails && id) {
      let data = courseDetails.course;
      setInputField({
        courseImagePath: data.courseCardImage,
      });
      setImagePreview(data?.courseCardImage);
      let path = data?.courseCardImage?.split("/");
      path = path ? path[path.length - 1] : "";
      setImagePath(path);
    }
  }, [courseDetails, id]);
  const handleProfileImage = (e) => {
    setUpdateCourse(true);
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImagePath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputField({ ...inputField, courseImagePath: formData });
    }
  };

  const createProfilePic = async () => {
    let courseImagePath = inputField.courseImagePath;

    if (courseImagePath) {
      let uri = `${apiEndPoint.UPLOAD_COURSE_IMAGE}=${id}`;
      let response: any = await dispatch(uploadCoursePic(uri, courseImagePath));
      let { status, result } = response;
      if (status) {
        Snackbar.show("Image Uploaded Successfully!", "success");
      } else {
        Snackbar.show(result, "error");
      }
    }
  };

  return (
    <div>
      <form>
        <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
          <div style={{ height: 300, width: "50%", display: "flex", flex: 1 }}>
            <ImagePicker
              handlePickImage={handleProfileImage}
              image={imagePreview}
              imagePath={imagePath}
              buttonName="Upload Course Pic"
            />
          </div>
        </Grid>
        <Grid>
          <BtnSection>
            <SubmitBtn onClick={handleSubmit}>Update</SubmitBtn>
          </BtnSection>
        </Grid>
      </form>
      <FormLoader visible={formLoader} />
    </div>
  );
};

export default UploadCourseImage;
