import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { getBlogDetails, getBlogsByCustomFilter, saveBlog } from "../../redux/action/blogAction";
import { SubmitBtn } from "../../Components/Card/Card";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import { enableHeaderTab } from "../../redux/action/configAction";
import { BLOG_TITLE_LENGTH, BLOG_SHORT_DETAILS_LENGTH, BLOG_DETAILS_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
  },
  input: {},
  submit: {
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  form: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
}));

interface props {
  url?: any;
  blogReducer?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
}
const initialState: any = {
  title: "",
  shortDetails: "",
  details: "",
  isDisabled: "N",
  picture: "",
};

const BlogForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const history = useHistory();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);

  let [editorState, setEditorState] = useState<any>(initialState);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  let { id } = useParams();
  const { blogDetails, currentPage } = useSelector((state: props) => state.blogReducer);

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }
    addBlogData();
  };

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BLOG_DETAILS}=${id}`;
      dispatch(getBlogDetails(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      if (blogDetails && id) {
        let data = blogDetails;
        setInputField({
          tdcblogId: id ? id : 0,
          title: data.title,
          shortDetails: data.shortDetails,
          details: data.details,
          isDisabled: data.isDisabled,
          picture: data.picture ? data.picture : "",
        });
      }
    }
  }, [id, blogDetails]);

  const validationSchema = yup.object({
    title: yup
      .string()
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .required("Please Enter Tilte!")
      .test(
        "len",
        `Characters Length should not be greater than ${BLOG_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= BLOG_TITLE_LENGTH
      ),
    shortDetails: yup
      .string()
      .required("Please Enter Short Details!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of Short Details should not be greater than ${BLOG_SHORT_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= BLOG_SHORT_DETAILS_LENGTH
      ),
    details: yup.string().required("Please Enter Details!"),
    // .strict(false)
    // .trim()
    // .test(
    //   "len",
    //   `HTML Content of Details should not be greater than ${BLOG_DETAILS_LENGTH} characters`,
    //   (val: any) => val?.toString().length <= BLOG_DETAILS_LENGTH
    // )
  });

  const addBlogData = async () => {
    const blogData = {
      tdcblogId: id ? id : 0,
      title: inputField.title,
      shortDetails: inputField.shortDetails,
      details: inputField.details,
      isDisabled: inputField.isDisabled,
      picture: inputField.picture,
    };

    let uri = `${apiEndPoint.SAVE_BLOG}`;
    let response: any = await dispatch(saveBlog(uri, blogData));
    if (response.status) {
      if (id) {
        history.push("/home/blog");
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        if (!id) {
          if (currentPage == 0) {
            fetchBlogList(currentPage, "");
          }
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputField,
    validationSchema,
    onSubmit,
  });

  const handleInput = (name: any, value: any) => {
    setInputField((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };
  const classes = useStyles();

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputField({ ...inputField, ...editorState, ...newEditorState });
  };
  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputField, [field]: value });
  };

  const handleDisabled = (field: any, e: any) => {
    const bool = e.target.checked ? "Y" : "N";
    setInputField((prval) => {
      return {
        ...prval,
        [field]: bool,
      };
    });
  };

  const fetchBlogList = (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_BLOGS_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    dispatch(getBlogsByCustomFilter(uri, args, searchText));
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };
  return (
    <div>
      <FormLoader visible={formLoader} />
      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        <form>
          <CustomSwitch
            field="isDisabled"
            handleStateChange={handleDisabled}
            checked={inputField?.isDisabled === "N" ? false : true}
            label="Disabled"
          />
          <CustomInput
            field="title"
            id="title"
            variant="outlined"
            required
            fullWidth
            className={classes.input}
            name="title"
            label="Enter Title"
            type="text"
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            value={inputField.title}
            error={formik.errors.title}
            helperText={formik.errors.title}
          />
          <EditorInput
            title="Enter Short Details *"
            field="shortDetails"
            value={inputField.shortDetails}
            onBlur={() => handleBlurEditor("shortDetails")}
            errorFound={formik.errors.shortDetails}
            handleDataChange={handleEditorState}
          />
          {formik.errors.shortDetails ? <div className={classes.textDanger}>{formik.errors.shortDetails}</div> : null}

          <EditorInput
            title="Enter All Details *"
            field="details"
            value={inputField.details}
            onBlur={() => handleBlurEditor("details")}
            errorFound={formik.errors.details}
            handleDataChange={handleEditorState}
          />
          {formik.errors.details ? <div className={classes.textDanger}>{formik.errors.details}</div> : null}
          {id ? (
            <Grid>
              <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                Update
              </SubmitBtn>
            </Grid>
          ) : null}
        </form>
      </Grid>
    </div>
  );
};
export default BlogForm;
