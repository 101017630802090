import { reduxConstants } from "../../constant/Redux";

const initialState = {
  invoiceList: null,
  startDate: null,
  invoiceDetails: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function invoiceReducer(state = initialState, action: any) {
  const { invoiceList }: any = state;
  switch (action.type) {
    case reduxConstants.INIT_INVOICE: {
      return {
        ...state,
        offerDetails: null,
      };
    }
    case reduxConstants.INIT_INVOICE_LIST: {
      return {
        ...state,
        currentPage: 0,
      };
    }
    case reduxConstants.ALL_INVOICE_LIST:
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        invoiceList: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };

    case reduxConstants.SAVE_INVOICE:
      return {
        ...state,
        invoiceList: [...invoiceList, action.payload[0]],
      };

    case reduxConstants.INVOICE_DETAILS_BY_ID:
      return {
        ...state,
        invoiceList: [...invoiceList, action.payload[0]],
      };
    case reduxConstants.INVOICE_DETAILS_BY_ID:
      return {
        ...state,
        invoiceDetails: action.payload[0],
      };

    case reduxConstants.INVOICE_DETAILS_BY_ID: {
      let { invoiceList }: any = state;
      let newInvoiceList: any = [];
      invoiceList.forEach((item, i) => {
        if (item.invoiceId !== action.payload) {
          newInvoiceList = [...newInvoiceList, item];
        }
      });
      return {
        ...state,
        invoiceDetails: action.payload[0],
        invoiceList: newInvoiceList,
      };
    }

    default:
      return state;
  }
}
