import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormLabel } from "@material-ui/core";
import FileUploadIcon from "@mui/icons-material/FileUpload";

interface Props {
  handlePickImage: any;
  image: any;
  artifactFile: any;
  disabled: any;
}

const useStyles = makeStyles(() => ({
  btnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
  },
  btn: {
    border: "2px solid gray",
    color: "gray",
    // backgroundColor:"white",
    padding: "8px 20px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: "bold",
    width: "300px",
  },
}));

const ContentImagePicker: React.FC<Props> = (props) => {
  let { image, artifactFile, disabled }: any = props;
  const [editedFile, setEditedFile] = useState<any>(false);

  const classes = useStyles();
  let fileRef: any = React.createRef();

  const upload = (e) => {
    e.preventDefault();
    fileRef?.current.click();
  };

  const fileSelectHandler = (e) => {
    setEditedFile(true);
    props.handlePickImage(e);
  };

  return (
    <div className={classes.btnWrapper}>
      {!artifactFile ? (
        <div>
          <input
            accept="image/*"
            ref={fileRef}
            disabled={disabled}
            id="pickImageInput"
            type="file"
            name="myfile"
            hidden
            onChange={fileSelectHandler}
          />

          <button
            id="pickImageButton"
            className={classes.btn}
            onClick={upload}
            style={{ backgroundColor: disabled ? "#E8E8E8" : "white" }}
          >
            Choose Image{" "}
            <span>
              <FileUploadIcon></FileUploadIcon>
            </span>
          </button>
        </div>
      ) : null}

      <Grid style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
        {artifactFile && !editedFile ? (
          <div>
            <FormLabel>Attached File:</FormLabel>
            <img src={artifactFile} alt="" height="400" width="100%" style={{ marginTop: 3 }} />
          </div>
        ) : (
          <>
            {image && (
              <div>
                <FormLabel>Attached File:</FormLabel>
                <img src={image} alt="" height="400" width="100%" style={{ marginTop: 3 }} />
              </div>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default ContentImagePicker;
