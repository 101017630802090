import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function getAllCourses(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(courseLoader(true));
      }
      const { httpCode, data } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(courseLoader(false));
        }
        return { status: true };
      } else {
        dispatch(courseLoader(false));
        return { status: true };
      }
    } catch (e: any) {
      dispatch(courseLoader(false));
      console.log(e.message);
      return { status: false };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_ALL_COURSES,
      payload: data,
    };
  }
}

export function saveCourse(uri: any, arg: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, apiMessage, data, errors } = await Services.postApi(uri, arg);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data[0]));
        dispatch(contentLoader(false));
        return { status: "success", result: data[0] };
      } else {
        dispatch(formLoader(false));
        return { status: apiMessage, result: errors };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
      return { status: e.message, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE,
      payload: data,
    };
  }
}

export function getAllBundles(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
      } else {
        dispatch(formLoader(false));
        dispatch(contentLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_ALL_BUNDLES,
      payload: data,
    };
  }
}

export function getAllBundlesByCustomFilter(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseLoader(false));
        return { status: true };
      } else {
        dispatch(courseLoader(false));
        return { status: true };
      }
    } catch (e: any) {
      dispatch(courseLoader(false));
      console.log(e.message);
      return { status: false };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_ALL_BUNDLES,
      payload: data,
    };
  }
}

export function saveBundle(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_BUNDLE,
      payload: data,
    };
  }
}
export function saveBundleCourseList(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_BUNDLE_COURSE_LIST,
      payload: data,
    };
  }
}
export function getbBundleById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);

      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_BUNDLE_BY_ID,
      payload: data,
    };
  }
}
export function getbBundleCourseById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);

      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_BUNDLE_COURSE_BY_ID,
      payload: data,
    };
  }
}

export function uploadBundlePic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else if (status === 404) {
        dispatch(formLoader(false));
        return { status: false, result: "REST API 404: Bad URI, or Missing Resource!" };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
}
export function uploadCoursePic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else if (status === 404) {
        dispatch(formLoader(false));
        return { status: false, result: "REST API 404: Bad URI, or Missing Resource!" };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
}

export function getCourseCategoryAll(uri: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
      } else {
        dispatch(contentLoader(false));
      }
    } catch (e: any) {
      dispatch(contentLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.COURSE_CATEGORY_ALL,
      payload: data,
    };
  }
}

export function getCourseLevelAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(contentLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(contentLoader(false));
      } else {
        dispatch(contentLoader(false));
      }
    } catch (e: any) {
      dispatch(contentLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.COURSE_LEVEL_ALL,
      payload: data,
    };
  }
}

export function getCourseValidityAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(contentLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(contentLoader(false));
      } else {
        dispatch(contentLoader(false));
      }
    } catch (e: any) {
      dispatch(contentLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.COURSE_VALIDITY_ALL,
      payload: data,
    };
  }
}

export function getCourseById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
      } else {
        dispatch(formLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.COURSE_BY_ID,
      payload: data,
    };
  }
}

export function initCourse() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_COURSE,
    });
  };
}

export function initBundle() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_BUNDLE,
    });
  };
}

export function initSubsection() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_COURSE_SUB_SECTION,
    });
  };
}

export function getCourseSectionList(uri: any) {
  return async (dispatch: any) => {
    dispatch(sectionLoader(true));
    const { httpCode, data } = await Services.getAll(uri);
    if (httpCode === 200) {
      dispatch(success(data));
      dispatch(sectionLoader(false));
    } else {
      dispatch(sectionLoader(false));
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.COURSE_SECTION_LIST_ALL,
      payload: data,
    };
  }
}

export function saveCourseSection(uri: any, args: any, args2: any) {
  return async (dispatch: any) => {
    try {
      if (!args2) {
        dispatch(formLoader(true));
      } else {
        dispatch(formLoader(false));
      }
      const { httpCode, data } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(formLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE_SECTION,
      payload: data,
    };
  }
}

export function getCourseSection(uri: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        return { status: true, result: data };
      } else {
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      return { status: false, result: null };
    }
  };
  function success(data) {
    return {
      type: reduxConstants.GET_COURSE_SECTION_DETAILS,
      payload: data,
    };
  }
}

export function saveCourseAssignment(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      const { httpCode, data } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE_ASSIGNMENT,
      payload: data,
    };
  }
}

export function saveCourseQuestion(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      // dispatch(courseMediaLoader(true));
      const { httpCode, data } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        // dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        // dispatch(courseMediaLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      // dispatch(courseMediaLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE_QUESTIONS,
      payload: data,
    };
  }
}
export function getAllCourseOverViewVideo(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      let { httpCode, data } = await Services.postApi(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(contentLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GET_COURSE_OVERVIEW_VIDEO,
      payload: data,
    };
  }
}

export function uploadCourseOverViewVideo(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      let response: any = await Services.postApiMulti(uri, args);
      let { httpCode, data, apiMessage } = response?.data;
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: apiMessage };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD_COURSE_OVERVIEW_VIDEO,
      payload: data,
    };
  }
}

export function uploadCourseOverViewLanguageVideo(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));

      let response: any = await Services.postApiMulti(uri, args);
      let { httpCode, data, apiMessage } = response?.data;
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: apiMessage };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD_COURSE_OVERVIEW_VIDEO,
      payload: data,
    };
  }
}
export function uploadCourseSubSectionVideo(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { httpCode, data, apiMessage } = response?.data;
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: apiMessage };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD_COURSE_SUB_SECTION_VIDEO,
      payload: data,
    };
  }
}
export function uploadCourseSubSectionLanguageVideo(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { httpCode, data, apiMessage } = response?.data;
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: apiMessage };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO,
      payload: data,
    };
  }
}
export function uploadCourseSectionImage(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE_SECTION_IMAGE,
      payload: data,
    };
  }
}

export function uploadCourseSectionPdf(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(courseMediaLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(success(data));
        dispatch(courseMediaLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(courseMediaLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(courseMediaLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_COURSE_SECTION_PDF,
      payload: data,
    };
  }
}

export function getCourseSectionArtifact(uri: any) {
  return async (dispatch: any) => {
    try {
      const data = await Services.postApi(uri);
      if (data) {
        dispatch(success(data));
        dispatch(artifactLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(artifactLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(artifactLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data) {
    return {
      type: reduxConstants.COURSE_SECTION_ARTIFACT,
      payload: data,
    };
  }
}

export function getCourseSectionArtifactByParent(uri: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data } = await Services.postApi(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(artifactLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(artifactLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(artifactLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data) {
    return {
      type: reduxConstants.COURSE_SECTION_ARTIFACT_BY_PARENT,
      payload: data,
    };
  }
}

export function deleteCourseById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_COURSE_BY_ID,
      payload: data,
    };
  }
}

export function deleteBundleById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_BUNDLE_BY_ID,
      payload: data,
    };
  }
}

export function failure(error: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.COURSE_ERROR,
      error,
    });
  };
}

// export function courseLoader(flag: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: reduxLoader.COURSE_LOADER,
//       payload: flag,
//     });
//   };
// }
export function courseLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.COURSE_LOADER,
      payload: flag,
    });
  };
}

export function contentLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.CONTENT_LOADER,
      payload: flag,
    });
  };
}
export function courseMediaLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.COURSE_MEDIA_LOADER,
      payload: flag,
    });
  };
}
export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
export function sectionLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SECTION_LOADER,
      payload: flag,
    });
  };
}

export function questionFormLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.QUESTION_LOADER,
      payload: flag,
    });
  };
}

export function resetArtifact() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_ARTIFACT,
    });
  };
}

export function artifactLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.ARTIFACT_LOADER,
      payload: flag,
    });
  };
}

export function courseSectionTitle(args: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.SECTION_TITLE,
      payload: args,
    });
  };
}
export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
