import { reduxConstants } from "../../constant/Redux";

const initialState = {
  headerTab: true,
  headerBreadCrumbTitle: null,
};

export default function configReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.ENABLE_HEADER_TAB: {
      return {
        ...state,
        headerTab: action.payload,
      };
    }
    case reduxConstants.HEADERBREADCRUMB_TITLE:
      return {
        ...state,
        headerBreadCrumbTitle: action.payload,
        // headerBreadCrumbTitle: action.payload[0]?.dtoResults,
      };
    default:
      return {
        ...state,
      };
  }
}
