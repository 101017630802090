import { reduxConstants } from "../../constant/Redux";

const initialState = {
  facultyList: [],
  faculties: [],
  faculty: null,
  facultydetail: null,
  facultyabout: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function facultyReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_FACULTY: {
      return {
        ...state,
        faculty: null,
      };
    }
    case reduxConstants.INIT_FACULTY_LIST: {
      return {
        ...state,
        currentPage: 0,
      };
    }
    case reduxConstants.FACULTY_ALL:
      let facultyData = action.payload[0]?.dtoResults;
      if (facultyData && facultyData.length > 0) {
        facultyData = facultyData.map((element: any) => {
          return {
            ...element,
            label: `${element.firstName} ${element.lastName}`,
            value: element.tdcuserId,
          };
        });
      }

      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        faculties: facultyData,
        facultyList: facultyData,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };

    case reduxConstants.SAVE_FACULTY: {
      let { facultyList } = state;
      return {
        ...state,
        facultyList: [...facultyList, action.payload[0]],
      };
    }

    case reduxConstants.GET_FACULTY_BY_ID:
      return {
        ...state,
        faculty: action.payload[0],
      };

    case reduxConstants.GET_USER_DETAILS_BY_ID:
      const lastElement = action.payload[action.payload.length - 1];
      return {
        ...state,
        facultyabout: lastElement,
      };

    case reduxConstants.SAVE_USER_DETAILS: {
      return {
        ...state,
        facultyabout: action.payload[0],
      };
    }

    case reduxConstants.DELETE_FACULTY_BY_ID: {
      let { facultyList }: any = state;
      let newFacultyList: any = [];
      facultyList.forEach((item, i) => {
        if (item.tdcuserId !== action.payload) {
          newFacultyList = [...newFacultyList, item];
        }
      });
      return {
        ...state,
        faculty: action.payload[0],
        facultyList: newFacultyList,
      };
    }

    default:
      return state;
  }
}
