import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import VideoPicker from "../../Components/FilePicker/VideoPicker";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import {
  getAllCourseOverViewVideo,
  resetArtifact,
  uploadCourseOverViewLanguageVideo,
  uploadCourseOverViewVideo,
} from "../../redux/action/courseAction";
import Service from "../../service/Services";
import MediaLoader from "../../Components/Loader/mediaLoader";
import CustomLabel from "../../Components/Label/CustomLabel";
import ModalExample, { MainButton } from "../../Components/Modal/CustomModal";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "57%",
  transform: "translate(-50%, -30%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

interface Props {
  parentId?: any;
  courseReducer?: any;
}

const CourseOverViewVideo: React.FC<Props> = ({ parentId }) => {
  const Snackbar = useSnackbar();

  const dispatch = useDispatch();
  let { id } = useParams();
  let courseId = id;
  const [rowData, setRowData]: any = useState({
    selectedType: null,
    selectedLan: null,
  });

  // const [artifactLanguageAll, setArtifactLanguageAll] = useState([
  //   {
  //     typeId: 1,
  //     typeName: "Low",
  //   },
  //   {
  //     typeId: 2,
  //     typeName: "Medium",
  //   },
  //   {
  //     typeId: 3,
  //     typeName: "High",
  //   },
  // ]);
  const [allLanguages, setAllLanguages] = useState([
    {
      typeId: 1,
      typeName: "English",
    },
    {
      typeId: 2,
      typeName: "Hindi",
    },
    {
      typeId: 3,
      typeName: "Marathi",
    },
  ]);
  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [currentParentId, setCurrentParentId] = useState<any>(null);
  const [videoPath, setVideoPath]: any = React.useState();
  const [videoPreview, setVideoPreview]: any = React.useState();
  const { overViewVideos, detailCourse } = useSelector((state: Props) => state.courseReducer);
  const { courseMediaLoader } = useSelector((state: any) => state.loaderReducer);

  let uploadedStatus = overViewVideos && overViewVideos.length > 0;

  let tempParentId: any =parentId? parentId: null;

  useEffect(() => {
    setCurrentParentId(tempParentId);
  }, [tempParentId]);

  useEffect(() => {
    fetchOverVideoList(tempParentId);
  }, [tempParentId]);
  useEffect(() => {
    //   Service.postApi("​​/content/artifactFormatAll").then((res) => {
    //     if (res.httpCode === 200) {
    //       const result = res.data;
    //       setArtifactLanguageAll(result);
    //     }
    //   });
    Service.postApi("/content/artifactLanguageAll").then((res) => {
      if (res.httpCode === 200) {
        const result = res.data;
        setAllLanguages(result);
      }
    });
  }, []);

  // useEffect(() => {
  //   Service.postApi(
  //     `/content/getCourseSectionArtifactByParent?parentArtifactId=${parentId}`
  //   ).then((res) => {
  //     if (res.httpCode === 200) {
  //       let result = res.data;
  //       if (result && result.length > 0) {
  //         result = result.filter((item) => item.formatType == 1);
  //       }
  //       setAllVideos(result);
  //     }
  //   });
  // }, [allVideos?.length]);

  const fetchOverVideoList = (id) => {
    if (id) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT_BY_PARENT}=${id}`;
      dispatch(getAllCourseOverViewVideo(uri));
    }
  };

  // useEffect(() => {
  //   fetchOverVideoList(parentId);
  // }, [parentId, overViewVideos?.length]);

  const handleVideoSelect = (e: any) => {
    try {
      if (e.target?.files && e.target?.files[0]) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        let file = e.target.files[0];
        setVideoPreview(e.target.files[0]);
        setVideoPath(file.name);
        setSelectedVideo(formData);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  const handleUpload = async () => {
    if (!rowData.selectedLan && !rowData.selectedType) {
      // alert("Please Select Language First");
      Snackbar.show("Please Select Language First!", "error");

      return false;
    } else if (!selectedVideo) {
      // alert("Please Select Video!");
      Snackbar.show("Please Select Video!", "error");
      return false;
    } else if (rowData.selectedLan === 1) {
      if (uploadedStatus) {
        // alert("English Video already Uploaded!");
        Snackbar.show("English Video already Uploaded!", "error");

        return false;
      }
      let languageId = rowData.selectedLan;
      let uri = `${apiEndPoint.UPLOAD_COURSE_OVERVIEW_VIDEO}=${courseId}&languageId=${languageId}`;
      let response: any = await dispatch(uploadCourseOverViewVideo(uri, selectedVideo));
      let { status, result } = response;
      if (status) {
        setOpen(false);
      }
      setCurrentParentId(result[0]?.parentTdcartifactId);
      // let parentArtifactId =
      //   detailCourse && detailCourse.length > 0 ? detailCourse[0]?.tdcArtifact?.tdcartifactId : null;
      fetchOverVideoList(result[0]?.parentTdcartifactId);
      setVideoPreview(null);
      setRowData({ ...rowData, selectedLan: null });
      setVideoPath("");
    } else {
      let existingLanguage: any = [];
      overViewVideos?.forEach((item: any) => {
        if (item.languageId === rowData.selectedLan) {
          existingLanguage = [...existingLanguage, item];
          return existingLanguage;
        }
      });
      if (existingLanguage && existingLanguage.length > 0) {
        // alert(" Please choose another language. A video for this language already exists.");
        Snackbar.show("Please choose another language. A video for this language already exists.!", "error");

        return false;
      }
      // let parentArtifactId =
      //   detailCourse && detailCourse.length > 0 ? detailCourse[0]?.tdcArtifact?.tdcartifactId : null;
      if (!currentParentId) {
        // alert("Please Choose English Language video first");
        Snackbar.show("Please Choose English Language video first!", "error");
      } else {
        if (currentParentId) {
          let languageId = rowData.selectedLan;
          let uri = `${apiEndPoint.UPLOAD_COURSE_OVERVIEW_LANGUAGE_VIDEO}=${courseId}&languageId=${languageId}&parentArtifactId=${currentParentId}`;
          let responseVideo: any = await dispatch(uploadCourseOverViewLanguageVideo(uri, selectedVideo));
          fetchOverVideoList(currentParentId);
          setVideoPreview(null);
          setVideoPath("");
          setRowData({ ...rowData, selectedLan: null });
          if (responseVideo?.status) {
            setOpen(false);
          }
        }
      }
    }
  };

  const handleChangeLanguage = (value) => {
    let existingLanguage: any = [];
    allLanguages.forEach((item: any) => {
      if (item.languageId === value) {
        existingLanguage = [...existingLanguage, item];
        return existingLanguage;
      }
    });
    if (existingLanguage && existingLanguage.length > 0) {
      // alert("Please Choose another language, This Language Video already exist.");
      Snackbar.show("Please Choose another language, This Language Video already exist.", "error");

      return false;
    }
    setRowData({ ...rowData, selectedLan: value });
  };

  // const handleChangeFormatType = (value) => {
  //   if (!rowData.selectedLan) {
  //     alert("Please Select Language First");
  //     return false;
  //   }
  //   let existingLanguage = overViewVideos.filter((item: any, i: any) => {
  //     if (item.languageId == rowData.selectedLan && item.formatType == value) {
  //       return item;
  //     }
  //   });
  //   if (existingLanguage && existingLanguage.length > 0) {
  //     alert("Please Choose another Type, This Type  already exist.");
  //     return false;
  //   }
  //   setRowData({ ...rowData, selectedType: value });
  // };

  const handleCancel = () => {
    setOpen(false);
  };

  const renderChildren = () => {
    let title = uploadedStatus ? "Dubbed Videos" : "English Video (Default)";
    let allLangs: any = uploadedStatus ? allLanguages.slice(1) : [...allLanguages];
    return (
      <div>
        <Box sx={style} style={{ display: "flex", flexDirection: "column" }}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Divider light style={{ marginBottom: 10 }} />
          <div style={{ width: "100%", marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" style={{ marginTop: 20 }}></InputLabel>
              <Select
                id="demo-simple-select"
                value={rowData.selectedLan}
                placeholder="Please language"
                onChange={(event) => {
                  handleChangeLanguage(event.target.value);
                }}
              >
                {allLangs?.map((item, i): any => (
                  <MenuItem
                    value={item?.typeId}
                    style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
                  >
                    {item.typeName}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ marginTop: 20 }}></div>
              {/* <InputLabel id="demo-simple-select-label1"></InputLabel>
              <Select
                //  labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={rowData.selectedType}
                label="TYPE"
                placeholder="Please "
                onChange={(event) => {
                  handleChangeFormatType(event.target.value);
                }}
              >
                {artifactLanguageAll?.map((item, i): any => (
                  <MenuItem value={item?.typeId}>{item.typeName}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <VideoPicker handlePickImage={(e) => handleVideoSelect(e)} video={videoPreview} videoPath={videoPath} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#043969",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={handleUpload}
            >
              Upload
            </Button>
            <Button
              onClick={handleCancel}
              style={{
                fontWeight: "bold",
                backgroundColor: "#455a64",
                color: "#fff",
                marginLeft: 20,
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
        <MediaLoader visible={courseMediaLoader} />
      </div>
    );
  };

  return (
    <div>
      {!overViewVideos ? (
        <div style={{ padding: 10 }}>
          <CustomLabel label="No Videos Added!" />
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0",
        }}
      >
        <MainButton
          onClick={() => {
            setOpen(true);
          }}
        >
          Add Video
        </MainButton>
      </div>

      <ModalExample content={renderChildren()} setOpen={open} />

      <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px" }}>
        {overViewVideos?.map((item) => {
          return (
            <>
              {/* <FormLoader visible={courseMediaLoader} /> */}

              <MediaCard data={item} />
            </>
          );
        })}
      </Box>
    </div>
  );
};

export default CourseOverViewVideo;

export function MediaCard(props) {
  let { data } = props;
  let langArray = ["English", "Hindi", "Marathi"];
  let { path, name, languageId } = data;

  return (
    <Grid item>
      <Card>
        <CardMedia component="video" controls image={path} />
        <CardContent style={{ fontSize: 12, fontFamily: "Oxanium" }}>
          <p style={{ fontWeight: "bold" }}>{name}</p>
          <br />
          <p style={{ marginTop: -10, marginBottom: -10, fontFamily: "cursive" }}>{langArray[languageId - 1]}</p>
        </CardContent>
      </Card>
    </Grid>
  );
}
