import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
interface props {
  message: string;
  isOpen: boolean;
  type: string;
}

const SnackbarAlert: React.FC<props> = (props) => {
  let { message, isOpen, type } = props;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      <Snackbar open={isOpen} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default SnackbarAlert;
