import { CircularProgress } from "@material-ui/core";
import React from "react";

const FormLoader=({ visible })=>{
  if (visible) {
    return (
      <div>
        <CircularProgress
          color="inherit"
          style={{ position: "fixed", top: "50%", left: "50%", right: "50%", bottom: "50%", zIndex: 9999, }}
        />
      </div>
    );
  }
  return <></>;
}

export default FormLoader;
