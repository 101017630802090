import React, { useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import HeaderTab from "../../Components/Tab/HeaderTab";
import FooterButton from "../../Components/Footer/FooterButton";
import OfferForm from "./form";

interface Props {
  url?: any;
}

const OfferContainer: React.FC<Props> = () => {
  let [selectableTab, setSelectableTab] = useState(0);
  let [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (value) => {
    setActiveTab(value);
    setSelectableTab(value);
  };

  let tabs = [
    {
      label: "Offer Form",
      value: 0,
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <OfferForm />;
      default:
        return <></>;
    }
  };
  return (
    <DivWrapper>
      <HeaderTab
        screen="invoice"
        tabs={tabs}
        activeTab={activeTab}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
      <div style={{ flex: 1, marginTop: 20 }}>{renderTabContent()}</div>
      <FooterButton
        screen="invoice"
        tabsLength={tabs?.length}
        activeTab={activeTab}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
    </DivWrapper>
  );
};

export default OfferContainer;
