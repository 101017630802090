import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initBlog() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_BLOG,
    });
  };
}

export function getBlogDetails(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(blogFormLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(blogFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(blogFormLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(blogFormLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.BLOG_DETAILS_BY_ID,
      payload: data,
    };
  }
}

export function saveBlog(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(blogFormLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(blogFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(blogFormLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(blogFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_BLOG,
      payload: data,
    };
  }
}

export function uploadBlogPic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(blogFormLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(success(data));
        dispatch(blogFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(blogFormLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(blogFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.UPLOAD_BLOG_PIC,
      payload: data,
    };
  }
}

export function getBlogsByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(blogLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(blogLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(blogLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(blogLoader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ALL_BLOG_LIST,
      payload: data,
    };
  }
}
export function deleteBlogById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_BLOG_BY_ID,
      payload: data,
    };
  }
}

export function blogLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.BLOG_LOADER,
      payload: flag,
    });
  };
}
export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
export function blogFormLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
