// On Course
export const COURSE_SHORT_DETAILS_LENGTH = 1000;
export const COURSE_COURSE_DETAILS_LENGTH = 5000;
export const COURSE_WHAT_YOU_LEARN_LENGTH = 5000;
export const COURSE_WHO_IS_THIS_FOR_LENGTH = 5000;
export const COURSE_TITLE_LENGTH = 150;
export const MERITTO_COURSE_TITLE_LENGTH = 150;

export const COURSE_Pre_Book_Course_Lesson_LENGTH = 150;
// On Course Bundle
export const COURSE_BUNDLE_BUNDLE_NAME_LENGTH = 200;
export const COURSE_BUNDLE_DETAILS_LENGTH = 5000;
// On Course PROMOCODE
export const PROMOCODE_TITLE_LENGTH = 200;

// On Course Question MODEL
export const COURSE_QUESTION_QUESTION_DETAILS_LENGTH = 500;
export const COURSE_QUESTION_CHOICE_A_LENGTH = 250;
export const COURSE_QUESTION_CHOICE_B_LENGTH = 250;
export const COURSE_QUESTION_CHOICE_C_LENGTH = 250;
export const COURSE_QUESTION_CHOICE_D_LENGTH = 250;
export const COURSE_QUESTION_ANSWER_LENGTH = 250;

// On add Section
export const COURSE_SECTION_SECTION_TITLE_LENGTH = 150;
export const COURSE_SECTION_SECTION_DETAILS_LENGTH = 2000;
// On add Section
export const COURSE_SUB_SECTION_TITLE_LENGTH = 150;
export const COURSE_SUB_SECTION_DETAILS_LENGTH = 2000;

//OFFER BANNER LENGTH
export const OFFER_HEAD_LENGTH = 150;
export const OFFER_BUTTON_LENGTH = 150;
export const OFFER_MESSAGE_LENGTH = 2000;
export const OFFER_BUTTON_URL_LENGTH = 2000;
//BLOG FORM LENGTH
export const BLOG_TITLE_LENGTH = 150;
export const BLOG_SHORT_DETAILS_LENGTH = 2000;
export const BLOG_PICTURE_LENGTH = 300;
export const BLOG_DETAILS_LENGTH = 10000;

//NEWS FORM LENGTH
export const NEWS_TITLE_LENGTH = 150;
export const NEWS_SHORT_DETAILS_LENGTH = 400;
export const NEWS_PICTURE_LENGTH = 300;
export const NEWS_DETAILS_LENGTH = 10000;

// On User
export const USER_EMAIL_ID_LENGTH = 100;
export const USER_FIRST_NAME_LENGTH = 50;
export const USER_LAST_NAME_LENGTH = 50;

// On User Details
export const USER_DETAILS_ABOUT_ME_LENGTH = 4000;
export const USER_DETAILS_DESIGNATION_LENGTH = 50;

// On User Address
export const USER_ADDRESS_ADDRESS1_LENGTH = 50;
export const USER_ADDRESS_ADDRESS2_LENGTH = 50;
export const USER_ADDRESS_AREA_LENGTH = 50;
export const USER_ADDRESS_CITY_LENGTH = 30;
export const USER_ADDRESS_STATE_LENGTH = 30;
export const USER_ADDRESS_COUNTRY_LENGTH = 50;
export const USER_ADDRESS_PINCODE_LENGTH = 10;
