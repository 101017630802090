import React, { useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import FooterButton from "../../Components/Footer/FooterButton";
import HeaderTab from "../../Components/Tab/HeaderTab";
import CourseContent from "./courseContent";
import CourseDetailForm from "./courseDetailform";
import OverViewVideo from "./overViewVideo";
import UploadCourseImage from "./courseImage";

interface Props {
  url?:any;
}

const CourseContainer: React.FC<Props> = () => {
  let [selectableTab, setSelectableTab] = useState(0);
  let [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (value) => {
    setActiveTab(value);
    setSelectableTab(value);
  };

  let tabs = [
    {
      label: "Course Details",
      value: 0,
    },
    {
      label: "Overview Video",
      value: 1,
    },
    {
      label: "Course Content",
      value: 2,
    },
    {
      label: "Course Image",
      value: 3,
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <CourseDetailForm />;
      case 1:
        return <OverViewVideo />;
      case 2:
        return <CourseContent />;
      case 3:
        return <UploadCourseImage />;
      default:
        return <></>;
    }
  };

  return (
    <DivWrapper>
      <HeaderTab screen="course" tabs={tabs} activeTab={activeTab} selectableTab={selectableTab} handleChangeTab={handleChangeTab} />
      <div style={{ flex: 1 }}>{renderTabContent()}</div>
      <FooterButton
      screen="course"
        activeTab={activeTab}
        tabsLength={tabs?.length}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
    </DivWrapper>
  );
};

export default CourseContainer;
