import { reduxConstants } from "../../constant/Redux";

export function enableHeaderTab(flag) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.ENABLE_HEADER_TAB,
      payload: flag,
    });
  };
}
export function HeaderBreadCrumbTitle(args: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.HEADERBREADCRUMB_TITLE,
      payload: args,
    });
  };
}
