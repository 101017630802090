import React, { useState } from "react";
import { DivWrapper } from "../../Components/Card/Card";
import HeaderTab from "../../Components/Tab/HeaderTab";
import FooterButton from "../../Components/Footer/FooterButton";
import BlogForm from "./form";
import UploadPicture from "./picture";

interface Props {
  url?:any;
}

const BlogContainer: React.FC<Props> = () => {
  let [selectableTab, setSelectableTab] = useState(0);
  let [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (value) => {
    setActiveTab(value);
    setSelectableTab(value);
  };

  let tabs = [
    {
      label: "Blog Form",
      value: 0,
    },
    {
      label: "Upload Profile Pic ",
      value: 1,
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <BlogForm />;
      case 1:
        return <UploadPicture />;
      default:
        return <></>;
    }
  };
  return (
    <DivWrapper>
      <HeaderTab screen="blog" tabs={tabs} activeTab={activeTab} selectableTab={selectableTab} handleChangeTab={handleChangeTab} />
      <div style={{ flex: 1, marginTop: 20 }}>{renderTabContent()}</div>
      <FooterButton
        screen="blog"
        tabsLength={tabs?.length}
        activeTab={activeTab}
        selectableTab={selectableTab}
        handleChangeTab={handleChangeTab}
      />
    </DivWrapper>
  );
};

export default BlogContainer;
