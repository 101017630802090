import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ImagePicker from "../../Components/FilePicker/ImagePicker";
import { useParams, useHistory } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { getFacultyById, uploadFacultyBannerPic, uploadFacultyProfilePic } from "../../redux/action/facultyAction";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

interface Props {
  url?: any;
  facultyReducer?: any;
}
const initialState: any = {
  emailId: "",
  profilePic: "",
  bannerPic: "",
};

const UploadPictures: React.FC<Props> = (props) => {
  const Snackbar = useSnackbar();

  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [inputField, setInputField] = useState(initialState);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [updateBannerPic, setUpdateBannerPic] = useState(false);
  let [imagePreview, setImagePreview] = useState<any>(null);
  let [bannerPreview, setBannerPreview] = useState<any>(null);
  let [imagePath, setImagePath] = useState(null);
  let [bannerPath, setBannerPath] = useState(null);

  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  let { faculty } = useSelector((state: Props) => state.facultyReducer);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_FACULTY_BY_ID}?userId=${id}`;
      dispatch(getFacultyById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    let data = faculty;
    if (faculty && id) {
      setInputField({
        tdcuserId: id ? id : 0,
        emailId: data.emailId,
        profilePic: data.profilePic,
        bannerPic: data.bannerPic,
      });
      setImagePreview(data?.profilePic);
      if (data.profilePic) {
        let path = data?.profilePic?.split("/");
        path = path ? path[path.length - 1] : "";
        setImagePath(path);
      }
      setBannerPreview(data?.bannerPic);
      if (data.bannerPic) {
        let banner = data?.bannerPic?.split("/");
        banner = banner ? banner[banner.length - 1] : "";
        setBannerPath(banner);
      }
    }
  }, [faculty, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (updateProfile) {
      createFacultyProfilePic(inputField.emailId);
    }
    if (updateBannerPic) {
      createFacultyBannerPic(inputField.emailId);
    }
  };
  const createFacultyProfilePic = async (emailId) => {
    let profilePic = inputField.profilePic;
    if (profilePic) {
      let uri = `${apiEndPoint.UPLOAD__FACULTY_PROFILE_PIC}?emailId=${emailId}`;
      let response: any = await dispatch(uploadFacultyProfilePic(uri, profilePic));
      if (response.status) {
        Snackbar.show("Uploaded Profile Image Successfully  ", "success");
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    }
  };
  const createFacultyBannerPic = async (emailId) => {
    let bannerPic = inputField.bannerPic;
    if (bannerPic) {
      let uri = `${apiEndPoint.UPLOAD__FACULTY_BANNER_PIC}?emailId=${emailId}`;
      let response: any = await dispatch(uploadFacultyBannerPic(uri, bannerPic));
      if (response.status) {
        setTimeout(() => {
          Snackbar.show("Uploaded BannerImage Successfully  ", "success");
        }, 500);

        setTimeout(() => {
          history.push("/home/faculty");
        }, 2000);
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    }
  };

  const handleFacultyProfilePic = (e) => {
    setUpdateProfile(true);
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImagePath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputField({ ...inputField, profilePic: formData });
    }
  };

  const handleFacultyBannerPic = (e) => {
    setUpdateBannerPic(true);
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      setBannerPreview(URL.createObjectURL(e.target.files[0]));
      setBannerPath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputField({ ...inputField, bannerPic: formData });
    }
  };

  return (
    <div>
      <form>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px", marginTop: "50px" }}>
          <div style={{}}>
            <ImagePicker
              handlePickImage={handleFacultyProfilePic}
              image={imagePreview}
              imagePath={imagePath}
              buttonName="Upload Profile Pic"
            />
          </div>

          <div style={{}}>
            <ImagePicker
              handlePickImage={handleFacultyBannerPic}
              image={bannerPreview}
              imagePath={bannerPath}
              buttonName="Upload Banner Pic"
            />
          </div>
        </div>

        <Grid>
          <BtnSection>
            <SubmitBtn onClick={handleSubmit}>Update</SubmitBtn>
          </BtnSection>
        </Grid>
      </form>
      <FormLoader visible={formLoader} />
    </div>
  );
};

export default UploadPictures;
