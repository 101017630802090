import { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";

type AllProps = {
  questionList?: any;
  handleUploadCsv?: any;
  handleCancelCsv?: any;
  handleError?: any;
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    overflowY: "auto",
  },
  table: {
    minWidth: 650,
    border: "1px solid!important",
    "& th": {
      border: "1px solid!important",
      textAlign: "center",
    },
    "& td": {
      border: "1px solid black !important",
      textAlign: "center",
    },
  },
  tblData: {
    "& th": {},
    "& td": {},
    "&:td:last-child": {
      borderRight: "none",
    },
  },
  tblHead: {
    "& th": {},
  },
}));
const QuestionTable: FunctionComponent<AllProps> = (props) => {
  let { questionList, handleError } = props;
  const classes = useStyles();

  if (questionList && questionList.length) {
    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "6fr 1fr 1fr",
            gridGap: "20px",
            padding: "14px 0",
            gridTemplateRows: "35px",
          }}
        >
          <h3>{`Preview of Selected File(Total Questions: ${questionList.length})`}</h3>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              props.handleCancelCsv(e);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            disabled={handleError.isError ? true : false}
            color="primary"
            onClick={(e) => {
              props.handleUploadCsv(questionList);
            }}
          >
            Upload CSV
          </Button>
        </div>

        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tblHead}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell width="400">Questions</TableCell>
              <TableCell>Option A:</TableCell>
              <TableCell>Option B:</TableCell>
              <TableCell>Option C:</TableCell>
              <TableCell>Option D:</TableCell>
              <TableCell>Answer:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tblData}>
            {questionList?.map((d, idx) => (
              <TableRow>
                <TableCell>{d.questionIndex}</TableCell>
                {d.questionDetails.length ? (
                  <TableCell width="400">{d.questionDetails}</TableCell>
                ) : (
                  <TableCell style={{ color: "red" }} width="400">
                    Question should not Empty !
                  </TableCell>
                )}
                {d.choiceA ? (
                  <TableCell>{d.choiceA}</TableCell>
                ) : (
                  <TableCell style={{ color: "red" }}>All Fields are Required!</TableCell>
                )}
                {d.choiceB ? (
                  <TableCell>{d.choiceB}</TableCell>
                ) : (
                  <TableCell style={{ color: "red" }}>All Fields are Required!</TableCell>
                )}
                <TableCell>{d.choiceC}</TableCell>
                <TableCell>{d.choiceD}</TableCell>
                {d.answer ? (
                  <TableCell style={{ color: d.error ? "red" : "" }}>{d.answer}</TableCell>
                ) : (
                  <TableCell style={{ color: "red" }}>All Fields are Required!</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      {handleError.errMsg ? <div style={{ color: "red" }}>{handleError.errMsg}</div> : null}
      </div>
    );
  }
  return (
    <Grid style={{ justifyContent: "center" }}>
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}></div>
    </Grid>
  );
};
export default QuestionTable;
