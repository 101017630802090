import styled, { css } from "styled-components";
export const CardWrapper = styled.div`
  overflow: hidden;

  width: 100%;
  font-family: Quicksand, arial, sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
`;

export const DivWrapper = styled.div`
  margin: 20px;
  background-color: white !important;
  padding: 4% 2% 2% 2%;
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: center;
`;

export const CardHeading = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const CardBody = styled.div`
  padding-right: 32px;
  padding-left: 32px;
`;

export const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;

export const CardInput = styled.input`
  padding: 7px 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ddd;
  border-left: 0;
  transition: border-bottom-color 0.25s ease-in;

  &:focus {
    border-bottom-color: #e5195f;
    outline: 0;
  }
`;

export const CardIcon = styled.span`
  color: #666;
  cursor: pointer;
  opacity: 0.25;
  transition: opacity 0.25s ease-in;

  &:hover {
    opacity: 0.95;
  }

  ${(props) =>
    props.big &&
    css`
      font-size: 26px;
    `}

  ${(props) =>
    props.eye &&
    css`
      position: absolute;
      top: 8px;
      right: 0;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 14px;
    `}
`;

export const CardOptionsNote = styled.small`
  padding-top: 8px;
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
`;

export const CardOptions = styled.ul`
  padding: 0;
  margin: 16px 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style-type: none;
`;

export const CardOptionsItem = styled.li`
  &:nth-of-type(n + 2) {
    margin-left: 16px;
  }
`;

export const CardButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #e5195f;
  border: 0;
  border-radius: 35px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
`;

export const CardLink = styled.a`
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  color: #aaa;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: color 0.25s ease-in;

  &:hover {
    color: #777;
  }
`;

export const TwoInput = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
`;

export const MainContr = styled.div`
  flex: 1;
  margin: 50px;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  justify-content: center;
`;

export const MainHeading = styled.div`
  text-align: center;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Oxanium", cursive;
  line-height: 1.43;
`;

export const BtnSection = styled.div`
  margin-top: 40px;
`;

export const SubmitBtn = styled.button`
  margin-right: 20px;
  color: ${(props) => (props.disabledColor ? "gray" : "#fff")};
  // background-color: #2196f3;#043969
  background-color: ${(props) => (props.disabledColor ? "#cccccc" : "#043969")};
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-weight: bold;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  border: 0;
  &:hover {
    background-color: ${(props) => (props.disabledColor ? "#cccccc" : "#1976d2")};
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  }
`;
export const AddNewFormBtn = styled.button`
  margin-right: 20px;
  color: #fff;
  background-color: #0c7be0;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-weight: bold;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  border: 0;
  &:hover {
    background-color: #1976d2;
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  }
`;
export const NextBtn = styled.button`
  margin-right: 20px;
  color: #fff;
  background-color: #455a64;

  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-weight: bold;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  border: 0;
  &:hover {
    background-color: #78909c;
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  }
`;

export const CancelBtn = styled.button`
  color: #fff;
  background-color: #455a64;

  /* color: #e82112; */
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  font-weight: bold;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  &:hover {
    background-color: #78909c;
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  }
`;

export const contPrview = styled.div`
  margin-top: 5px;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  min-height: 250px;
  width: 100%;
`;

export const SignInBtn = styled.button`
  margin-right: 20px;
  color: #fff;
  background-color: #2196f3;
  padding: 6px 16px;
  font-size: 0.875rem;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1)  0ms;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgb (0 0 0 / 20%),  0px 2px 2px 0px rgb (0 0 0 / 14%),  0px 1px 5px 0px rgb (0 0 0 / 12%);
  border: 0;
  &:hover {
    background-color: #1976d2;
    box-shadow: 0px 2px 4px -1px rgb (0 0 0 / 20%),  0px 4px 5px 0px rgb (0 0 0 / 14%),
       0px 1px 10px 0px rgb (0 0 0 / 12%);
  }
`;
