import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initNews() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_NEWS,
    });
  };
}

export function getNewsDetailsById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);

      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.NEWS_BY_ID,
      payload: data,
    };
  }
}

export function saveNews(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_NEWS,
      payload: data,
    };
  }
}

export function uploadNewsPic(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const response: any = await Services.postApiMulti(uri, args);
      let { status, data } = response;
      if (status === 200) {
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: data };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };
}

export function getNewsAll(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(newsLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);

      if (httpCode === 200) {
        dispatch(success(data));

        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(newsLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(newsLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(newsLoader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.NEWS_ALL,
      payload: data,
    };
  }
}

export function deleteNewsById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_NEWS_BY_ID,
      payload: data,
    };
  }
}
export function newsLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.NEWS_LOADER,
      payload: flag,
    });
  };
}

export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}

export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
