import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initInvoice() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_INVOICE,
    });
  };
}

export function getInvoiceDetails(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(invoiceFormLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(invoiceFormLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(invoiceFormLoader(false));
        return { status: false, result: null };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(invoiceFormLoader(false));
      return { status: false, result: null };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.INVOICE_DETAILS_BY_ID,
      payload: data,
    };
  }
}

export function saveInvoice(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(invoiceFormLoader(true));
      const response = await Services.postApi(uri,"");
      return { status: false, result: response };
      // if (httpCode === 200) {
  
      //   dispatch(success(data));
      //   dispatch(invoiceFormLoader(false));
      //   return { status: true, result: data };
      // } else {
      //   dispatch(invoiceFormLoader(false));
      //   return { status: false, result: error };
      // }
    } catch (e: any) {
      console.log(e.message);
      dispatch(invoiceFormLoader(false));
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_INVOICE,
      payload: data,
    };
  }
}


export function getInvoiceByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(invoiceLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(invoiceLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(invoiceLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(invoiceLoader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ALL_INVOICE_LIST,
      payload: data,
    };
  }
}
export function deleteInvoiceById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_INVOICE_BY_ID,
      payload: data,
    };
  }
}

export function invoiceLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.OFFER_BANNER_LOADER,
      payload: flag,
    });
  };
}
export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
export function invoiceFormLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
