import { reduxConstants } from "../../constant/Redux";

const initialState = {
  newsList: [],
  newsDetails: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function newsReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_NEWS: {
      return {
        ...state,
        newsDetails: null,
      };
    }
    case reduxConstants.INIT_NEWS_LIST: {
      return {
        ...state,
        currentPage: 0,
      };
    }
    case reduxConstants.SAVE_NEWS: {
      let { newsList } = state;
      return {
        ...state,
        newsList: [...newsList, action.payload[0]],
      };
    }
    case reduxConstants.NEWS_BY_ID:
      return {
        ...state,
        newsDetails: action.payload[0],
      };

    case reduxConstants.NEWS_ALL:
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        newsList: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };

    case reduxConstants.DELETE_NEWS_BY_ID: {
      let { newsList }: any = state;
      let newNewsList: any = [];
      newsList.forEach((item, i) => {
        if (item.tdcNewsId !== action.payload) {
          newNewsList = [...newNewsList, item];
        }
      });
      return {
        ...state,
        newsList: newNewsList,
      };
    }

    default:
      return state;
  }
}
