import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import CustomLabel from "../../../Components/Label/CustomLabel";
const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 630,
    height: "fit-content",
    border: "1px solid!important",
    "& th": {
      border: "1px solid!important",
      textAlign: "center",
    },
    "& td": {
      border: "1px solid!important",
      //   textAlign: "center",
      innerHeight: "fit-content",
    },
  },
  tblData: {
    "& th": {},
    "& td": {},
    "&:td:last-child": {
      borderRight: "none",
    },
  },
  tblHead: {
    "& th": {},
  },
}));

interface props {
  dataList: any;
  listTitle: string;
}

const TablePreview: React.FC<props> = (props) => {
  let { dataList, listTitle } = props;
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    let newDataList = [];
    newDataList = dataList?.filter((d, idx) => {
      if (d.checked) {
        return d;
      }
    });

    setTableData(newDataList);
  }, [dataList]);

  const classes = useStyles();
  return (
    <div>
      {tableData.length ? (
        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tblHead}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{listTitle}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tblData}>
            {tableData?.map((d, idx) => (
              <TableRow key={idx}>
                <TableCell>{listTitle === "Selected Courses" ? d.courseId : d.courseBundleId} </TableCell>
                {d ? <TableCell>{listTitle === "Selected Courses" ? d?.title : d?.bundleName}</TableCell> : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : listTitle === "Selected Courses" ? (
        <CustomLabel label="No Courses Available!" />
      ) : (
        <CustomLabel label="No Bundles Available!" />
      )}
    </div>
  );
};

export default TablePreview;
