import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initQuestionTag() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_QUESTION_TAG,
    });
  };
}

export function getQuestionTagAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(questionTagLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(questionTagLoader(false));
        dispatch(success(data));
      } else {
        dispatch(questionTagLoader(false));
      }
    } catch (e: any) {
      dispatch(questionTagLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.QUESTION_TAG_ALL,
      payload: data,
    };
  }
}

export function saveQuestionTag(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
        return { status: true, result: data[0] };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_QUESTION_TAG,
      payload: data,
    };
  }
}

export function getQuestionTagById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
      } else {
        dispatch(formLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      dispatch(questionTagLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.QUESTION_TAG_BY_ID,
      payload: data,
    };
  }
}

export function deleteQuestionTag(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(questionTagLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(questionTagLoader(false));
        dispatch(success(data[0]));
      } else {
        dispatch(questionTagLoader(false));
      }
    } catch (e: any) {
      dispatch(questionTagLoader(false));
      console.log(e.message);
    }
  };
  function success(item: any) {
    return {
      type: reduxConstants.DELETE_QUESTION_TAG,
      payload: item,
    };
  }
}

export function getQuestionTagByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(questionTagLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(questionTagLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(questionTagLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(questionTagLoader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ALL_QUESTION_TAG_LIST,
      payload: data,
    };
  }
}

export function questionTagLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.QUESTION_TAG_LOADER,
      payload: flag,
    });
  };
}

export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}

export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
