import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import React, { useEffect, useState } from "react";

import { convertToHTML } from "draft-convert";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "./input.css";
import { FormLabel } from "@material-ui/core";

interface Props {
  title: string;
  value: string;
  field: string;
  errorFound: boolean;
  onBlur: any;
  handleDataChange: any;
}

const EditorInput: React.FC<Props> = (props) => {
  let { title, value, field, errorFound } = props;

  let myValue = null;

  const sampleMarkup = value ? `<div>${value}</div>` : ``;

  const blocksFromHTML = convertFromHTML(sampleMarkup);

  myValue = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(myValue));

  useEffect(() => {
    let text = convertToHTML(editorState.getCurrentContent());
    props.handleDataChange(field, text);
    // eslint-disable-next-line
  }, [editorState]);

  useEffect(() => {
    if (sampleMarkup) {
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(sampleMarkup))));
    }
  }, [sampleMarkup]);

  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <FormLabel
        key={String(errorFound)}
        htmlFor={`${field}`}
        style={{ color: `${errorFound ? "#f44444" : "primary"}` }}
      >
        {title}
      </FormLabel>
      <div
        className="dtls-innr"
        key={value}
        style={{
          marginTop: 5,
          border: `1px solid ${errorFound ? "#f44444" : "#d8dbe0"}`,
          borderRadius: 5,
          minHeight: "250px",
          width: "100%",
        }}
      >
        <Editor onBlur={props.onBlur} editorState={editorState} onEditorStateChange={setEditorState} />
      </div>
    </div>
  );
};

export default EditorInput;
