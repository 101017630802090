import { reduxConstants } from "../../constant/Redux";

const initialState = {
  blogList: null,
  blogDetails: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function blogReducer(state = initialState, action: any) {
  const { blogList }: any = state;
  switch (action.type) {
    case reduxConstants.INIT_BLOG: {
      return {
        ...state,
        blogDetails: null,
      };
    }
    case reduxConstants.INIT_BLOG_LIST: {
      return {
        ...state,
        currentPage: 0,
      };
    }
    case reduxConstants.ALL_BLOG_LIST:
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        blogList: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };

    case reduxConstants.SAVE_BLOG:
      return {
        ...state,
        blogList: [...blogList, action.payload[0]],
      };

    case reduxConstants.UPDATE_BLOG:
      return {
        ...state,
        blogList: [...blogList, action.payload[0]],
      };
    case reduxConstants.BLOG_DETAILS_BY_ID:
      return {
        ...state,
        blogDetails: action.payload[0],
      };

    case reduxConstants.DELETE_BLOG_BY_ID: {
      let { blogList }: any = state;
      let newBlogList: any = [];
      blogList.forEach((item, i) => {
        if (item.tdcblogId !== action.payload) {
          newBlogList = [...newBlogList, item];
        }
      });
      return {
        ...state,
        blogDetails: action.payload[0],
        blogList: newBlogList,
      };
    }

    default:
      return state;
  }
}
