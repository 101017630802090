import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    border: "1px solid!important",
    "& th": {
      border: "1px solid!important",
      textAlign: "center",
    },
    "& td": {
      border: "1px solid!important",
      textAlign: "center",
    },
  },
  tblData: {
    "& th": {},
    "& td": {},
    "&:td:last-child": {
      borderRight: "none",
    },
  },
  tblHead: {
    "& th": {},
  },
}));

interface Props {
  AllCourseList?: any;
  courseReducer?: any;
}

const CourseBundleTable: React.FC<Props> = (props) => {
  let { courseSelectionList } = useSelector((state: Props) => state.courseReducer);

  const classes = useStyles();
  let { AllCourseList } = props;
  return (
    <>
      <Table className={classes.table} aria-label="caption table">
        <TableHead className={classes.tblHead}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell width="400">Course Name</TableCell>

            <TableCell>Faculty Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Level</TableCell>
            <TableCell>priority</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tblData}>
          {AllCourseList?.map((d, idx) => (
            <TableRow key={idx}>
              <TableCell>{d.courseId}</TableCell>
              <TableCell width="400">{d.label}</TableCell>

              <TableCell>{d.fullname}</TableCell>
              <TableCell>{d.categoryName}</TableCell>
              <TableCell>{d.levelName}</TableCell>
              <TableCell>{d.courseIndex}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default CourseBundleTable;
