import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TiDocument, TiImage, TiVideo, TiBook} from 'react-icons/ti';
import { MdNotes } from "react-icons/md";
import { AiFillCheckSquare } from "react-icons/ai";
interface Props {
  field: any;
  label: any;
  data: any;
  selectedValue: any;
  handleStateChange: any;
  disabled?: any;
  errors?: any;
  selectedIcon?: any;
}

const CustomSelect: React.FC<Props> = (props) => {
  let { field, selectedValue, label, data, disabled, errors } = props;

  const handleChange = (event) => {
    props.handleStateChange(field, event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          disabled={disabled}
          style={{
            fontFamily: "Oxanium",
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            padding: "0 10px",
            color: `${errors ? "#f44444" : "rgba(0, 0, 0, 0.87)"}`,
          }}
        >
          {label}
        </InputLabel>

        {data && data.length > 0 ? (
          <Select
            disabled={disabled}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            label={label}
            onChange={handleChange}
            style={{
              fontFamily: "Oxanium",
              // marginTop: 5,
              border: `1px solid ${errors ? "#f44444" : "#d8dbe0"}`,
              borderRadius: 5,
            }}
          >
                {data.map((option) => {
          let IconComponent = AiFillCheckSquare; // Default icon
          switch (option.icon) {
            case '/public/icons/exercise.png':
              IconComponent = TiBook;
              break;
            case '/public/icons/video.png':
              IconComponent = TiVideo;
              break;
            case '/public/icons/image.png':
              IconComponent = TiImage;
              break;
            case '/public/icons/pdf.png':
              IconComponent = TiDocument;
              break;
            case '/public/icons/assessment.png':
              IconComponent = MdNotes;
              break;
            default:
              break;
          }

          return (
            <MenuItem key={option.key} value={option.value}>
              <IconComponent style={{ marginRight: '8px' }} /> {/* Render the icon */}
              {option.label}
            </MenuItem>
          );
        })}
          </Select>
        ) : null}
        {errors}
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
