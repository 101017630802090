import CryptoJS from "crypto-js";
import { convertToUTCFormat } from "./global-helper";

export const getApiHeader = () => {
  try {
    let headerData: any = { token: null };
    let date = convertToUTCFormat();
    var key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_API_SECRET);
    var srcs = CryptoJS.enc.Utf8.parse(date.toString());
    var ciphertext = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    headerData = { token: ciphertext.toString() };
    return headerData;
  } catch (e: any) {
    console.log(e.message);
  }
};
