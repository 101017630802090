import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { makeStyles } from "@material-ui/core/styles";
import { DivWrapper } from "../../Components/Card/Card";
import DataTable from "../../Components/Table/dataTable";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import SearchInput from "../../Components/Input/SearchInput";
import { getUserByCustomFilter, initUser } from "../../redux/action/b2bUserAction";
import FormLoader from "../../Components/Loader/formLoader";
import { initBundle, initCourse } from "../../redux/action/courseAction";
let searchTimer;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
}));

interface props {
  url?: any;
  userReducer?: any;
}

const UserList: React.FC<props> = (props) => {
  let { url } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const { userLoader, formLoader } = useSelector((state: any) => state.loaderReducer);
  let { userList, totalItems, currentPage } = useSelector((state: props) => state.userReducer);
  if (userList && userList.length) {
    userList = userList.map((item) => {
      return { ...item, id: item.tdcuserId };
    });
  }

  useEffect(() => {
    getList(currentPage, "");
  }, [currentPage]);

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_ALL_USERS}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "emailId",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getUserByCustomFilter(uri, args, searchText));
  };

  const handleEdit = (Id) => {
    initUserFunction(true);
    let Title = Id.fullName;
    dispatch(HeaderBreadCrumbTitle(Title));
    let tdcuserId = Id.id;
    history.push(`${url}/${tdcuserId}`);
  };

  const initUserFunction = async (flag) => {
   await dispatch(initUser());
    await dispatch(initCourse());
    await dispatch(initBundle())

    dispatch(enableHeaderTab(flag));
  };

  const handleDelete = (selectItem: any) => {};

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };

  const handlePage = (page, search) => {
    getList(page, search);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "User Email",
      flex: 0.7,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 0.6,
    },
    {
      field: "isDisabled",
      headerName: "Disabled",
      flex: 0.35,
    },
    {
      field: "b2bflag",
      headerName: "B2B User",
      flex: 0.35,
    },
  ];

  const renderTable = () => {
    return (
      <div>
        <DataTable
          page={currentPage}
          data={userList}
          totalItems={totalItems}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePage={handlePage}
          loading={userLoader}
        />
      </div>
    );
  };
  return (
    <>
      {userLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={userLoader} />
        </div>
      )}
      {!userLoader && (
        <DivWrapper>
          <FormLoader visible={formLoader} />

          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.root}
          >
            <h1>Users</h1>
            <SearchInput handleSearch={handleSearch} search={search} />
            <CustomizedDialogs
              customeIcon={"add"}
              page={"user"}
              title={"Add New User"}
              rowData={"null"}
              label="User"
            ></CustomizedDialogs>
          </Grid>
          {renderTable()}
        </DivWrapper>
      )}
    </>
  );
};

export default UserList;
