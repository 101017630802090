import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormLabel } from "@material-ui/core";
import FileUploadIcon from "@mui/icons-material/FileUpload";

interface Props {
  artifactFile: any;
  handlePickPdf: any;
  pdf: any;
}

const useStyles = makeStyles(() => ({
  btnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
  },
  btn: {
    border: "2px solid gray",
    color: "gray",
    backgroundColor: "white",
    padding: "8px 20px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: "bold",
    width: "300px",
  },
}));

const PdfPicker: React.FC<Props> = (props) => {
  let { pdf, artifactFile }: any = props;
  const [editedFile, setEditedFile] = useState<any>(false);
  const classes = useStyles();
  let fileRef: any = React.createRef();

  const upload = (e) => {
    e.preventDefault();
    fileRef?.current.click();
  };

  const fileSelectHandler = (e) => {
    setEditedFile(true);
    props.handlePickPdf(e);
  };

  let preSignedUrl = artifactFile;
  if (artifactFile && !editedFile) {
    preSignedUrl = encodeURIComponent(artifactFile);
    if (preSignedUrl) {
      preSignedUrl = `https://docs.google.com/viewer?url=${preSignedUrl}&embedded=true`;
    }
  }

  return (
    <div className={classes.btnWrapper}>
      <div>
        <input
          accept=".pdf"
          ref={fileRef}
          id="pickPdfInput"
          type="file"
          name="myfile"
          hidden
          onChange={fileSelectHandler}
        />
        <button id="pickPdfButton" className={classes.btn} onClick={upload}>
          Choose PDF{" "}
          <span>
            <FileUploadIcon></FileUploadIcon>
          </span>
        </button>
      </div>

      <Grid style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        {artifactFile && !editedFile ? (
          <div>
            <FormLabel>Attached File:</FormLabel>
            <iframe
              title={artifactFile}
              src={preSignedUrl}
              height="500"
              width="100%"
              style={{ marginTop: 3, border: 0 }}
            />
          </div>
        ) : (
          <>
            {pdf && (
              <>
                <FormLabel>Attached File:</FormLabel>
                <embed src={pdf} type="application/pdf" height="300" width="100%" style={{ marginTop: 3 }} />
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default PdfPicker;
