import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { apiEndPoint } from "../../../constant/ApiEndPoint";
import { getCourseSectionArtifactByParent } from "../../../redux/action/courseAction";
import FormLoader from "../../../Components/Loader/formLoader";

interface Props {
  tdcartifactId?: any;
  courseReducer?: any;
  loaderReducer?: any;
}

const VideoPreview: React.FC<Props> = ({ tdcartifactId }) => {
  const dispatch = useDispatch();
  const { artifactVideos } = useSelector((state: Props) => state.courseReducer);
  const { ArtifactLoader } = useSelector((state: Props) => state.loaderReducer);
  useEffect(() => {
    if (tdcartifactId) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT_BY_PARENT}=${tdcartifactId}`;
      dispatch(getCourseSectionArtifactByParent(uri));
    }
  }, [tdcartifactId, dispatch]);

  let langArray = ["English", "Hindi", "Marathi"];
  if (artifactVideos && artifactVideos.length > 0) {
    return (
      <>
        <div style={{ display: "flex" }}>
          {artifactVideos.map((item, i) => (
            <Grid item xs={12} style={{ margin: 3 }}>
              <Card sx={{ maxWidth: "100%" }}>
                <CardMedia component="video" controls image={item.path} />
                <CardContent style={{ fontSize: 10, fontFamily: "Oxanium" }}>
                  <p style={{ fontWeight: "bold" }}>{item.name}</p>
                  <br />
                  <p style={{ marginTop: -10, marginBottom: -10, fontFamily: "Oxanium" }}>
                    {langArray[item.languageId - 1]}
                  </p>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </div>
      </>
    );
  }
  return <FormLoader visible={ArtifactLoader} />;
};

export default VideoPreview;
