
import { reduxConstants } from "../../constant/Redux";

export function userAuthentication(flag: any) {
       return (dispatch: any) => {
      dispatch({
        type: reduxConstants.AUTH_DETAILS,
        payload: flag,

      });
    };
  }

