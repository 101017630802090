import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLabel from "../../../Components/Label/CustomLabel";
import FormLoader from "../../../Components/Loader/formLoader";
import { apiEndPoint } from "../../../constant/ApiEndPoint";
import { getCourseSectionArtifact } from "../../../redux/action/courseAction";

interface Props {
  tdcartifactId?: any;
  courseReducer?: any;
  loaderReducer?: any;
}

const PdfPreview: React.FC<Props> = ({ tdcartifactId }) => {
  const dispatch = useDispatch();
  const { artifactFile } = useSelector((state: Props) => state.courseReducer);
  const { ArtifactLoader } = useSelector((state: Props) => state.loaderReducer);

  useEffect(() => {
    if (tdcartifactId) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT}=${tdcartifactId}`;
      dispatch(getCourseSectionArtifact(uri));
    }
  }, [tdcartifactId, dispatch]);

  let preSignedUrl = encodeURIComponent(artifactFile);
  if (preSignedUrl) {
    preSignedUrl = `https://docs.google.com/viewer?url=${preSignedUrl}&embedded=true`;
  }
  return (
    <div>
      <CustomLabel label="Attached File:" />
      <iframe title={artifactFile} src={preSignedUrl} height="300" width="100%" style={{ marginTop: 3 }} />
      <FormLoader visible={ArtifactLoader} />
    </div>
  );
};

export default PdfPreview;
