import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useFormik } from "formik";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import CustomInput from "../../Components/Input/CustomInput";
import { saveFaculty, getFacultyById, getFacultyAll } from "../../redux/action/facultyAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import { enableHeaderTab } from "../../redux/action/configAction";
import { USER_EMAIL_ID_LENGTH, USER_FIRST_NAME_LENGTH, USER_LAST_NAME_LENGTH } from "../../config/textLength";
import "./form.css";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
  },
  input: {
    marginTop: 30,
  },
  form: {
    width: "100%",
  },
}));

interface props {
  facultyReducer?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
}
const initialState: any = {
  tdcuserId: 0,
  firstName: "",
  lastName: "",
  emailId: "",
  password: "",
  phone: "",
  isDisabled: "N",
  hasFeatured: "N",
  profilePic: "",
  bannerPic: "",
};

const FacultyForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const history = useHistory();
  const dispatch = useDispatch();
  const classes: any = useStyles();
  const [inputField, setInputField] = useState(initialState);

  // const [dataBaseError, setDataBaseError] = useState("");
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let { id } = useParams();

  let { faculty, currentPage } = useSelector((state: props) => state.facultyReducer);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .strict(false)
      .matches(/^[a-zA-Z\s]+$/, "Numericals and Special Characters are not Allowed")
      .trim()
      .required("Please Enter First Name!")
      .test(
        "len",
        `Characters Length should  not be greater than ${USER_FIRST_NAME_LENGTH}`,
        (val: any) => val?.toString().length <= USER_FIRST_NAME_LENGTH
      ),
    lastName: yup
      .string()
      .strict(false)
      .matches(/^[a-zA-Z\s]+$/, "Numericals and Special Characters are not Allowed")
      .trim()
      .required("Please Enter Last Name!")
      .test(
        "len",
        `Characters Length should not be greater than ${USER_LAST_NAME_LENGTH}`,
        (val: any) => val?.toString().length <= USER_LAST_NAME_LENGTH
      ),
    emailId: yup
      .string()
      .strict(false)
      .trim()
      .email("Please Enter Valid Email Format!")
      .required("Please Enter Email!")
      .test(
        "len",
        `Characters Length should not be greater than ${USER_EMAIL_ID_LENGTH}`,
        (val: any) => val?.toString().length <= USER_EMAIL_ID_LENGTH
      ),
    password: yup.string().required("Please Enter Password!"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Please Enter Valid Phone Number!")
      .min(10, "Phone Number Is To Short!")
      .max(10, "Phone Number Is To Long!")
      .required("Please Enter Phone Number"),
  });

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }

    const addFacultyData = {
      tdcuserId: id ? id : 0,
      firstName: inputField.firstName,
      lastName: inputField.lastName,
      emailId: inputField.emailId,
      password: inputField.password,
      phone: inputField.phone,
      tdcuserRoleId: 2,
      isDisabled: inputField.isDisabled,
      hasFeatured: inputField.hasFeatured,
      profilePic: inputField.profilePic,
      bannerPic: inputField.bannerPic,
    };

    let uri = `${apiEndPoint.SAVE_FACULTY}`;
    let response: any = await dispatch(saveFaculty(uri, addFacultyData));
    let { result } = response;
    // setDataBaseError(result);
    if (response.status) {
      if (id) {
        history.push("/home/faculty");
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");

      setTimeout(() => {
        if (!id) {
          if (currentPage == 0) {
            fetchFacultyList(currentPage, "");
          }
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputField,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);
  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_FACULTY_BY_ID}?userId=${id}`;
      dispatch(getFacultyById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      let data = faculty;
      if (faculty && id) {
        setInputField({
          tdcuserId: id ? id : 0,
          firstName: data.firstName,
          lastName: data.lastName,
          emailId: data.emailId,
          password: "*****",
          phone: data.phone,
          isDisabled: data.isDisabled,
          hasFeatured: data.hasFeatured ? data.hasFeatured : "N",
          profilePic: data.profilePic ? data.profilePic : "",
          bannerPic: data.bannerPic ? data.bannerPic : "",
        });
      }
    }
  }, [id, faculty]);

  const handleInput = (name: any, value: any) => {
    setInputField((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleDisabled = (field: any, e: any) => {
    const bool = e.target.checked ? "Y" : "N";
    setInputField((prval) => {
      return {
        ...prval,
        [field]: bool,
      };
    });
  };

  const fetchFacultyList = (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_FACULTY_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "firstName",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }

    dispatch(getFacultyAll(uri, args, searchText));
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <>
      <FormLoader visible={formLoader} />

      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        <form>
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "grid",
              gap: 20,
              marginTop: 20,
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "auto",
            }}
          >
            <CustomSwitch
              field="isDisabled"
              handleStateChange={handleDisabled}
              checked={inputField?.isDisabled === "N" ? false : true}
              label="Disabled"
            />
            <CustomSwitch
              field="hasFeatured"
              handleStateChange={handleDisabled}
              checked={inputField?.hasFeatured === "N" ? false : true}
              label="Featured User"
            />
          </div>
          <CustomInput
            id="firstName"
            variant="outlined"
            fullWidth
            className={classes.input}
            label="Enter First Name"
            name="firstName"
            value={inputField.firstName}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.firstName}
            helperText={formik.errors.firstName}
          />

          <CustomInput
            id="lastName"
            variant="outlined"
            fullWidth
            className={classes.input}
            label="Enter Last Name"
            name="lastName"
            value={inputField.lastName}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.lastName}
            helperText={formik.errors.lastName}
          />

          <CustomInput
            id="emailId"
            variant="outlined"
            className={classes.input}
            disabled={id ? true : false}
            fullWidth
            label="Enter Email"
            name="emailId"
            value={inputField.emailId}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.emailId}
            helperText={formik.errors.emailId}
          />
          <CustomInput
            id="password"
            className={classes.input}
            fullWidth
            label="Enter Password"
            name="password"
            type={"password"}
            value={inputField.password}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.password}
            helperText={formik.errors.password}
          />
          <CustomInput
            id="phone"
            className={classes.input}
            fullWidth
            label="Enter Mobile Number"
            name="phone"
            value={inputField.phone}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.phone}
            helperText={formik.errors.phone}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value) || 0)
                .toString()
                .slice(0, 10);
            }}
          />

          {id ? (
            <Grid>
              <BtnSection>
                <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                  Update
                </SubmitBtn>
              </BtnSection>
            </Grid>
          ) : null}
        </form>
      </Grid>
    </>
  );
};

export default FacultyForm;
