import { reduxConstants } from "../../constant/Redux";
const initialState = {
    isLogIn: true,
};

export default function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case reduxConstants.AUTH_DETAILS:
            {
                return {
                    ...state,
                    isLogIn: action.payload,
                };
            };

        default:
            return state;
    }
}

