import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";
export function initReferralCode() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_REFERRAL_CODE,
    });
  };
}

export function getReferralCodeAll(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(contentLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(contentLoader(false));
        dispatch(success(data));
      } else {
        dispatch(contentLoader(false));
      }
    } catch (e: any) {
      dispatch(contentLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.REFERRAL_CODE_ALL,
      payload: data,
    };
  }
}

export function getReferralCodeById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
      } else {
        dispatch(formLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.REFERRAL_CODE_BY_ID,
      payload: data,
    };
  }
}

export function genReferralCode(uri: any, id: any) {
  return async (dispatch: any) => {
    try {
      const { httpCode, data, error } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(success(data[0]?.referralCode));
        if (id) {
          dispatch(getReferralCodeById(`/coursedata/cart/refCodeById?tdcRefCodeId=${id}`));
        }
        return { status: true, result: data[0]?.referralCode };
      } else {
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.GEN_REFERRAL_CODE,
      payload: data,
    };
  }
}

export function saveReferralCode(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
        return { status: true };
      } else {
        dispatch(formLoader(false));
        return { status: false };
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      console.log(e.message);
      return { status: false };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.SAVE_REFERRAL_CODE,
      payload: data,
    };
  }
}

export function getReferralCodeByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(contentLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(contentLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(contentLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(contentLoader(false));
      }
      return { status: false, result: e.message };
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.ALL_REFERRAL_CODE_LIST,
      payload: data,
    };
  }
}

export function deleteReferralCodeById(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(success(args));
    } catch (e: any) {}
  };
  function success(data: any) {
    return {
      type: reduxConstants.DELETE_REFERRAL_CODE_BY_ID,
      payload: data,
    };
  }
}
export function contentLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.CONTENT_LOADER,
      payload: flag,
    });
  };
}

export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}

export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}
