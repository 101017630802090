import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import ImagePicker from "../../Components/FilePicker/ImagePicker";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getNewsAll, getNewsDetailsById, uploadNewsPic } from "../../redux/action/newsAction";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

interface props {
  url?: any;
  newsReducer?: any;
  loaderReducer?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  cancel: {
    backgroundColor: "red",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  genCode: {
    margin: theme.spacing(1, 2, 2),
    color: "green",
  },
  submit: {
    backgroundColor: "blue",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
}));

const initialState: any = {
  picture: "",
};

const ProfilePic: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  let [updateProfile, setUpdateProfile] = useState(false);
  let [imagePreview, setImagePreview] = useState<any>(null);
  let [imagePath, setImagePath] = useState(null);
  let [inputData, setInputData] = useState<any>(initialState);

  const [fileSize, setFileSize] = useState(false);

  let { id } = useParams();
  let { newsDetails, currentPage } = useSelector((state: props) => state.newsReducer);
  let { formLoader } = useSelector((state: any) => state.loaderReducer);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_NEWS_BY_ID}?newsId=${id}`;
      dispatch(getNewsDetailsById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (newsDetails) {
      let { picture } = newsDetails;

      setInputData({
        picture,
      });
      setImagePreview(picture);
      if (picture) {
        let path = picture.split("/");
        path = path ? path[path.length - 1] : "";
        setImagePath(path);
      }
    }
  }, [newsDetails]);

  const fetchNewsList = (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_NEWS_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    dispatch(getNewsAll(uri, args, ""));
  };
  // var maxSize = 256;
  const handleProfileImage = (e) => {
    // setFileSize(false);
    setUpdateProfile(true);
    if (e.target.files && e.target.files[0]) {
      // var fsize = e.target.files[0].size / 1024;
      // if (fsize > maxSize) {
      //   setFileSize(true);
      //   return false;
      // }
      const formData = new FormData();
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImagePath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputData({ ...inputData, picture: formData });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (updateProfile) {
      createProfilePic(id);
    }
  };

  const createProfilePic = async (newsId) => {
    let picture = inputData.picture;
    if (picture) {
      let uri = `${apiEndPoint.UPLOAD_NEWS_PIC}=${newsId}&itemType=${"NEWS"}`;
      let response: any = await dispatch(uploadNewsPic(uri, picture));
      let { status, result } = response;
      if (status) {
        history.push("/home/news");
        Snackbar.show("Image Uploaded Successfully!", "success");
        // setTimeout(() => {
        //   fetchNewsList(currentPage, "");
        // }, 500);
      } else {
        Snackbar.show(result, "error");
      }
    }
  };

  return (
    <div className={classes.container}>
      <form>
        <div style={{ width: "50%" }}>
          <ImagePicker
            handlePickImage={handleProfileImage}
            image={imagePreview}
            imagePath={imagePath}
            buttonName="Upload Profile Pic"
          />
          {/* {fileSize ? (
            <div style={{ color: "red" }}>
              {" "}
              You are not able to choose image because image size must be less then 256kb
            </div>
          ) : null} */}
        </div>

        <Grid>
          <BtnSection>
            {/* <SubmitBtn className={classes.genCode} disabled={fileSize ? true : false} onClick={handleSubmit}> */}

            <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={handleSubmit}>
              Update
            </SubmitBtn>
          </BtnSection>
        </Grid>
      </form>
      <FormLoader visible={formLoader} />
    </div>
  );
};

export default ProfilePic;
