import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormLabel } from "@material-ui/core";

interface Props {
  handlePickImage: any;
  video: any;
  videoPath: any;
}

const useStyles = makeStyles(() => ({
  btnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
  },
  btn: {
    border: "2px solid gray",
    color: "gray",
    backgroundColor: "white",
    padding: "8px 20px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const VideoPicker: React.FC<Props> = (props) => {
  let { video, videoPath } = props;
  const classes = useStyles();
  let fileRef: any = React.createRef();

  const upload = (e) => {
    e.preventDefault();
    fileRef?.current.click();
  };

  const fileSelectHandler = (e) => {
    props.handlePickImage(e);
  };

  return (
    <div className={classes.btnWrapper}>
      <div>
        <input
          ref={fileRef}
          id="pickImageInput"
          type="file"
          accept="video/*"
          name="myfile"
          hidden
          onChange={(event) => {
            fileSelectHandler(event);
          }}
        />
        <button id="pickImageButton" className={classes.btn} onClick={upload}>
          Select Video
        </button>
      </div>
      {videoPath ? (
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <FormLabel>Attached File: {videoPath}</FormLabel>
          {video ? (
            <video width="100%" height="150" controls>
              <source src={URL.createObjectURL(video)} type="video/mp4" />
            </video>
          ) : (
            <></>
          )}
        </Grid>
      ) : null}
    </div>
  );
};

export default VideoPicker;
