import React, { useEffect, useState } from "react";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import CustomInput from "../../Components/Input/CustomInput";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomSwitch from "../../Components/Switches/CustomSwitch";

import { DivWrapper, BtnSection, SubmitBtn } from "../../Components/Card/Card";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import {
  genReferralCode,
  getReferralCodeAll,
  getReferralCodeByCustomFilter,
  getReferralCodeById,
  saveReferralCode,
} from "../../redux/action/referralCodeAction";
import EditorInput from "../../Components/Input/EditorInput";
import "./form.css";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  input: {
    margin: "0",
  },
  genCode: {
    margin: theme.spacing(1, 2, 2),
    color: "green",
  },
  cancel: {
    color: "red",
    margin: theme.spacing(4, 2, 2),
  },
  submit: {
    margin: theme.spacing(4, 2, 2),
  },
}));

let initialState: any = {
  tdcReferralCodeId: 0,
  title: "",
  details: "",
  referralCode: "",
  disabled: "N",
};

interface props {
  url?: any;
  referralCodeReducer?: any;
  togleEvent?: any;
  isModal?: any;
  closeModal?: any;
  loaderReducer?: any;
}

const ReferralCodeForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  let { id } = useParams();
  const [inputData, setInputData] = useState(initialState);
  const classes: any = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let [editorState, setEditorState] = useState<any>(initialState);

  let { referralCodeById, currentPage } = useSelector((state: props) => state.referralCodeReducer);
  let { formLoader } = useSelector((state: props) => state.loaderReducer);

  const validationSchema = yup.object({
    title: yup
      .string()
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .required("Please Enter Title!"),
    details: yup.string().required("Please Enter Details!"),
    referralCode: yup.string().required("Please Generate Referral Code!"),
  });

  let onSubmit: any = () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }

    addData();
  };

  let formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const fetchReferralCodeList = async () => {
    let uri = `${apiEndPoint.GET_REFERRAL_CODE_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: currentPage,
      },
    };
    await dispatch(getReferralCodeByCustomFilter(uri, args, ""));
  };

  const handleInput = (name: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };
  // const handleDisabled = (e) => {
  //   const { name, checked } = e.target;
  //   setInputData((preval) => {
  //     return {
  //       ...preval,
  //       [name]: checked === true ? "Y" : "N",
  //     };
  //   });
  // };
  const handleDisabled = (field: any, e: any) => {
    const bool = e.target.checked ? "Y" : "N";
    setInputData((prval) => {
      return {
        ...prval,
        [field]: bool,
      };
    });
  };

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_REFERRAL_CODE_BY_ID}`;
      dispatch(getReferralCodeById(`${uri}?tdcRefCodeId=${id}`));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (referralCodeById && referralCodeById.length > 0 && id > 0) {
      setInputData({
        tdcReferralCodeId: id !== 0 ? id : 0,
        title: referralCodeById[0]?.title,
        details: referralCodeById[0]?.details,
        referralCode: referralCodeById[0]?.referralCode,
        disabled: referralCodeById[0]?.disabled,
      });
    }
  }, [referralCodeById, id]);

  const addData = async () => {
    setInputData({
      tdcReferralCodeId: id !== 0 ? id : 0,
      title: inputData.title,
      details: inputData.details,
      referralCode: inputData.referralCode,
      disabled: inputData.disabled,
    });
    saveRef();
  };

  const saveRef = async () => {
    let uri = `${apiEndPoint.SAVE_REFERRAL_CODE}`;
    let saveResult: any = await dispatch(saveReferralCode(`${uri}`, inputData));
    if (saveResult.status) {
      if (id) {
        history.push("/home/referralCode");
      } else {
        props.closeModal(false);
      }
      // setInputData(initialState);
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");

      if (!id) {
        setTimeout(() => {
          if (currentPage === 0) {
            fetchReferralCodeList();
          }
        }, 500);
      }
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const generateReferralCode = async (e) => {
    e.preventDefault();
    let uri = `${apiEndPoint.GEN_REFERRAL_CODE}`;
    if (inputData?.title) {
      let resultGenerated: any = await dispatch(genReferralCode(`${uri}?title=${inputData?.title}`, id));
      if (resultGenerated.status === true) {
        setInputData((prval) => {
          return {
            ...prval,
            referralCode: resultGenerated.result,
          };
        });
        Snackbar.show("generate Successfully", "success");
      } else {
        Snackbar.show("Something Went Wrong.", "error");
      }
    }
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <DivWrapper>
      <FormLoader visible={formLoader} />
      <div style={{ margin: 10 }}>
        <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
          <CssBaseline />

          <form className={classes.form}>
            <Grid direction="row">
              {/* <FormControlLabel
                name="disabled"
                className={classes.input}
                style={{ width: "100%", marginBottom: "25px" }}
                control={<Switch checked={inputData?.disabled === "N" ? false : true} />}
                label="Disabled"
                value={inputData?.disabled}
                onChange={handleDisabled}
                id="dsble"
              /> */}
              <CustomSwitch
                field="disabled"
                handleStateChange={handleDisabled}
                checked={inputData?.disabled === "N" ? false : true}
                label="Disabled"
              />
              <CustomInput
                variant="outlined"
                className={classes.input}
                fullWidth
                required
                id="title"
                label="Title"
                name="title"
                value={inputData.title}
                handleState={(field: any, value: any) => {
                  handleInput(field, value);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                error={formik.errors.title}
                helperText={formik.errors.title}
                autoFocus
              />
              <>
                <EditorInput
                  title="Enter All Details *"
                  field="details"
                  value={inputData.details}
                  onBlur={() => handleBlurEditor("details")}
                  errorFound={formik.errors.details}
                  handleDataChange={handleEditorState}
                />
                {formik.errors.details ? <div className={classes.textDanger}>{formik.errors.details}</div> : null}
              </>
              <div style={{ marginTop: "40px" }}>
                <CustomInput
                  variant="outlined"
                  className={classes.input}
                  label="Referral Code"
                  required
                  type="text"
                  id="referralCode"
                  name="referralCode"
                  disabled={true}
                  value={inputData.referralCode}
                  handleState={(field: any, value: any) => {
                    handleInput(field, value);
                  }}
                  error={formik.errors.referralCode}
                  helperText={formik.errors.referralCode}
                />
              </div>
              <Button
                variant="contained"
                color="inherit"
                className={classes.genCode}
                disabled={id ? true : inputData?.title?.length <= 0 ? true : false}
                onClick={generateReferralCode}
              >
                Generate Code
              </Button>
            </Grid>
            {id ? (
              <Grid>
                <BtnSection>
                  <SubmitBtn
                    disabledColor={formLoader}
                    onClick={formik.handleSubmit}
                    disabled={
                      !formLoader || (inputData?.title && inputData?.referralCode && inputData?.details) ? false : true
                    }
                  >
                    {id > 0 ? "Update" : "Submit"}
                  </SubmitBtn>
                </BtnSection>
              </Grid>
            ) : null}
          </form>
        </Grid>
      </div>
    </DivWrapper>
  );
};

export default ReferralCodeForm;
