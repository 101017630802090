import { reduxConstants, reduxLoader } from "../../constant/Redux";
import Services from "../../service/Services";

export function initUser() {
  return (dispatch: any) => {
    dispatch({
      type: reduxConstants.INIT_USER,
    });
  };
}

export function getUserByCustomFilter(uri: any, args: any, searchText: any) {
  return async (dispatch: any) => {
    try {
      if (searchText) {
        dispatch(searchLoader(true));
      } else {
        dispatch(userLoader(true));
      }

      const { httpCode, data, error } = await Services.postApi(uri, args);

      if (httpCode === 200) {
        dispatch(success(data));
        if (searchText) {
          dispatch(searchLoader(false));
        } else {
          dispatch(userLoader(false));
        }
        return { status: true, result: data };
      } else {
        dispatch(userLoader(false));
        dispatch(searchLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      if (searchText) {
        dispatch(searchLoader(false));
      } else {
        dispatch(userLoader(false));
      }
      return { status: false, result: e.message };
    }
  };

  function success(data: any) {
    return {
      type: reduxConstants.ALL_USER_LIST,
      payload: data,
    };
  }
}

export function saveUser(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };

  function success(data: any) {
    return {
      type: reduxConstants.CREATE_USER,
      payload: data,
    };
  }
}

export function Buy_B2B(uri: any, args: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data, error } = await Services.postApi(uri, args);
      if (httpCode === 200) {
        dispatch(success(data));
        dispatch(formLoader(false));
        return { status: true, result: data };
      } else {
        dispatch(formLoader(false));
        return { status: false, result: error };
      }
    } catch (e: any) {
      console.log(e.message);
      dispatch(formLoader(false));
      return { status: false, result: e.message };
    }
  };

  function success(data: any) {
    return {
      type: reduxConstants.BUY_B2B_DATA,
      payload: data,
    };
  }
}

export function getUserById(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
      } else {
        dispatch(formLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      dispatch(userLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.USER_BY_ID,
      payload: data,
    };
  }
}

export function userLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.USER_LOADER,
      payload: flag,
    });
  };
}

export function getPurchasedData(uri: any) {
  return async (dispatch: any) => {
    try {
      dispatch(formLoader(true));
      const { httpCode, data } = await Services.getAll(uri);
      if (httpCode === 200) {
        dispatch(formLoader(false));
        dispatch(success(data));
      } else {
        dispatch(formLoader(false));
      }
    } catch (e: any) {
      dispatch(formLoader(false));
      dispatch(userLoader(false));
      console.log(e.message);
    }
  };
  function success(data: any) {
    return {
      type: reduxConstants.B2B_GET_PURCHASED_DATA,
      payload: data,
    };
  }
}

export function searchLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.SEARCH_LOADER,
      payload: flag,
    });
  };
}

export function formLoader(flag: any) {
  return (dispatch: any) => {
    dispatch({
      type: reduxLoader.FORM_LOADER,
      payload: flag,
    });
  };
}
