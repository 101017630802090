import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import { Grid } from "@material-ui/core";
import SelectionList from "../PromoCode/selectionList";
import { getAllBundles, getAllCourses } from "../../redux/action/courseAction";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import TablePreview from "./component/table";
import { getPurchasedData, Buy_B2B } from "../../redux/action/b2bUserAction";
import { useParams, useHistory } from "react-router-dom";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import FormLoader from "../../Components/Loader/formLoader";

interface props {
  url?: any;
  courseReducer?: any;
  userReducer?: any;
}

const AddContent: React.FC<props> = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const Snackbar = useSnackbar();
  const history = useHistory();

  const { formLoader } = useSelector((state: any) => state.loaderReducer);
  let { courseSelectionList, bundleSelectionList } = useSelector((state: props) => state.courseReducer);
  let { purchasedCourses, purchasedBundles } = useSelector((state: props) => state.userReducer);
  const [bundleList, setBundleList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [tempBundleList, setTempBundleList] = useState([]);
  const [tempCourseList, setTempCourseList] = useState([]);
  const [courseListVisible, setCourseListVisible] = useState(false);
  const [bundleListVisible, setBundleListVisible] = useState(false);
  let [selectedCourses, setSelectedCourses] = useState<any>([]);
  let [selectedBundles, setSelectedBundles] = useState<any>([]);

  useLayoutEffect(() => {
    getAllCoursesData();
    getAllBundlesData();
  }, []);

  useLayoutEffect(() => {
    let Selectionlist = courseSelectionList.filter((item: any) => item.disabled == "N");
    setCourseList(Selectionlist);
    setTempCourseList(Selectionlist);
  }, [courseSelectionList?.length]);

  useLayoutEffect(() => {
    setBundleList(bundleSelectionList);
    setTempBundleList(bundleSelectionList);
  }, [bundleSelectionList?.length]);

  useEffect(() => {
    getPurchasedSale();
  }, []);

  const getPurchasedSale = async () => {
    if (id) {
      let uri = `${apiEndPoint.GET_PURECHASAED_DATA}=${id}`;
      await dispatch(getPurchasedData(uri));
    }
  };

  const getAllCoursesData = async () => {
    let uri = `${apiEndPoint.GET_ALL_COURSES}`;
    const args = {
      pageCriteria: {
        pageSize: 100,
        pageNum: 0,
      },
    };
    await dispatch(getAllCourses(uri, args, ""));
  };

  const getAllBundlesData = async () => {
    let uri = `${apiEndPoint.GET_ALL_BUNDLES}`;
    await dispatch(getAllBundles(uri));
  };

  useEffect(() => {
    if (id && purchasedBundles.length && tempBundleList.length) {
      let newTempBundleList: any = [...tempBundleList];
      newTempBundleList.forEach((element: any, index: any) => {
        purchasedBundles.forEach((bundleItem) => {
          if (bundleItem.bundleId === element.courseBundleId) {
            newTempBundleList[index].checked = true;
            newTempBundleList[index].isPurchased = "Y";

            newTempBundleList[index] = {
              ...newTempBundleList[index],
            };
          }
        });
      });
      setBundleList(newTempBundleList);
      setTempBundleList(newTempBundleList);
      let selectedBundles = newTempBundleList.filter((item: any) => item.checked);
      setSelectedBundles(selectedBundles);
    }
  }, [purchasedBundles?.length, tempBundleList?.length]);

  useEffect(() => {
    if (id && purchasedCourses?.length && tempCourseList?.length) {
      let newTempCourseList: any = [...tempCourseList];
      newTempCourseList.forEach((element: any, index: any) => {
        purchasedCourses.forEach((courseItem) => {
          if (courseItem.courseId === element.courseId) {
            newTempCourseList[index].checked = courseItem.disabled !== "Y";
            newTempCourseList[index].isPurchased = "Y";

            newTempCourseList[index].disabled = courseItem.disabled;
            newTempCourseList[index] = {
              ...newTempCourseList[index],
            };
          }
        });
      });
      setCourseList(newTempCourseList);
      setTempCourseList(newTempCourseList);
      let selectedCourses = newTempCourseList.filter((item: any) => item.checked);
      setSelectedCourses(selectedCourses);
    }
  }, [purchasedCourses?.length, tempCourseList?.length]);

  const handleCourseList = (flag: any) => {
    setCourseListVisible(flag);
    if (flag) {
      window.scroll({ top: 150, left: 0, behavior: "smooth" });
    }
  };

  const handleBundleList = (flag: any) => {
    setBundleListVisible(flag);
    if (flag) {
      window.scroll({ top: 150, left: 0, behavior: "smooth" });
    }
  };

  const handleCancelCourseList = () => {
    handleCourseList(false);
    let selectedCourses = courseList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourses);
    setTempCourseList(courseList);
  };

  const handleCancelBundleList = () => {
    handleBundleList(false);
    let selectedCourses = bundleList.filter((item: any) => item.checked);
    setSelectedBundles(selectedCourses);
    setTempBundleList(bundleList);
  };

  const handleSaveCourseList = () => {
    setCourseList(tempCourseList);
    handleCourseList(false);
  };
  const handleSaveBundleList = () => {
    setBundleList(tempBundleList);
    handleBundleList(false);
  };

  const selectedCheckBoxCourse = (e: any, selectedItem: any) => {
    let newTempCourseList: any = [];
    newTempCourseList = tempCourseList.map((item: any, i) => {
      if (selectedItem.courseId === item.courseId) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setTempCourseList(newTempCourseList);
    let selectedCourse = newTempCourseList.filter((item: any) => item.checked);
    setSelectedCourses(selectedCourse);
  };

  const selectedCheckBoxBundle = (e: any, selectedItem: any) => {
    let newTempBundleList: any = [];
    newTempBundleList = tempBundleList.map((item: any, i) => {
      if (selectedItem.courseBundleId === item.courseBundleId) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setTempBundleList(newTempBundleList);
    let selectedCourse = newTempBundleList.filter((item: any) => item.checked);
    setSelectedBundles(selectedCourse);
  };

  const handlePurchase = async () => {
    let purchaseUri = `${apiEndPoint.BUY_B2B_SALES}=${id}&promoCode=4`;
    let arg: any = [];
    let selectedCourse = selectedCourses?.filter((item: any) => item.isPurchased == "N");
    let selectedBundle = selectedBundles?.filter((item: any) => item.isPurchased == "N");
    if (selectedCourse && selectedCourse.length && selectedBundle! >= 0) {
      let newCourseList = selectedCourse.map((item: any, i: any) => {
        return {
          cartItemId: 0,
          tdcCartId: 0,
          bundleId: -1,
          courseId: item?.courseId,
          price: item?.price,
          actualPrice: item?.actualPrice ? item?.actualPrice : item?.price,
          isDeleted: item?.disabled,
          isPurchased: "N",
        };
      });
      arg = newCourseList;
    } else if (selectedBundle && selectedBundle.length && selectedCourse! >= 0) {
      let newBundleList: any = selectedBundle.map((item: any, i: any) => {
        return {
          cartItemId: 0,
          tdcCartId: 0,
          bundleId: item?.courseBundleId,
          courseId: -1,
          price: item?.bundlePrice,
          actualPrice: item?.actualPrice ? item?.actualPrice : item?.price,
          isDeleted: "N",
          isPurchased: "N",
        };
      });
      arg = newBundleList;
    } else if (selectedCourse.length > 0 && selectedBundle.length > 0) {
      let list = [...selectedCourse, ...selectedBundle];
      let newArg: any = list.map((item: any, i: any) => {
        return {
          cartItemId: 0,
          tdcCartId: 0,
          courseId: item?.courseId ? item?.courseId : -1,
          bundleId: item?.courseBundleId ? item?.courseBundleId : -1,
          price: item?.bundlePrice ? item?.bundlePrice : item.price,
          actualPrice: item?.actualPrice ? item?.actualPrice : item?.price,
          isDeleted: item?.disabled ? item?.disabled : "N",
          isPurchased: "N",
        };
      });
      arg = newArg;
    }
    if (arg && arg.length >= 1) {
      let response: any = await dispatch(Buy_B2B(purchaseUri, arg));
      let { status, result } = response;
      if (status) {
        Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
        history.push("/home/user");
      } else {
        Snackbar.show(`${result}`, "error");
        getPurchasedSale();
      }
    }
  };

  const renderCourseList = () => {
    return <TablePreview dataList={courseList} listTitle="Selected Courses" />;
  };

  const renderBundleList = () => {
    return <TablePreview dataList={bundleList} listTitle="Selected Bundle" />;
  };

  return (
    <div>
      {formLoader && <FormLoader visible={formLoader} />}
      <div>
        <div
          style={{
            display: "grid",
            marginTop: 30,
            gap: 20,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
          className="cmn-grd"
        >
          <div>
            <input
              style={{
                width: "100%",
                height: 50,
                borderRadius: 5,
                borderWidth: 0.5,
                borderColor: "#ddd",
                outline: "none",
                fontSize: 16,
                fontFamily: "Oxanium",
              }}
              value={
                selectedCourses.length === 0
                  ? ""
                  : `${selectedCourses.length} Course${selectedCourses.length > 1 ? "s" : ""} Selected`
              }
              onFocus={() => handleCourseList(true)}
              placeholder="Select Courses"
            />

            {courseListVisible && (
              <SelectionList
                list={tempCourseList}
                selectedList={selectedCourses}
                title="Select Courses"
                handleCancel={handleCancelCourseList}
                handleConfirm={handleSaveCourseList}
                selectedCheckBox={selectedCheckBoxCourse}
                courseIndex={""}
              />
            )}
          </div>

          <div>
            <input
              style={{
                width: "100%",
                height: 50,
                borderRadius: 5,
                borderWidth: 0.5,
                borderColor: "#ddd",
                outline: "none",
                fontSize: 16,
                fontFamily: "Oxanium",
              }}
              value={
                selectedBundles.length === 0
                  ? ""
                  : `${selectedBundles.length} Bundle${selectedBundles.length > 1 ? "s" : ""} Selected`
              }
              onFocus={() => handleBundleList(true)}
              placeholder="Select Bundles"
            />
            {bundleListVisible ? (
              <SelectionList
                list={tempBundleList}
                selectedList={selectedBundles}
                title="Select Bundles"
                handleCancel={handleCancelBundleList}
                handleConfirm={handleSaveBundleList}
                selectedCheckBox={selectedCheckBoxBundle}
                courseIndex={""}
              />
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "grid",
            marginTop: 30,
            gap: 20,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
          className="cmn-grd"
        >
          {courseList && renderCourseList()}
          {bundleList && renderBundleList()}
        </div>
      </div>

      <Grid>
        <BtnSection>
          <SubmitBtn onClick={handlePurchase}>Update</SubmitBtn>
        </BtnSection>
      </Grid>
    </div>
  );
};

export default AddContent;
