import { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import CustomInput from "../../Components/Input/CustomInput";
import { SignInBtn } from "../../Components/Card/Card";
import { useDispatch } from 'react-redux';
import { userAuthentication } from "../../redux/action/authAction";
import "../../index.css";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://thedesignersclass.com/">
        TDC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(img/wallpaper2-min.PNG)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    paddingTop: "40px",
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface props { }

const SignIn: React.FC<props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [inputData, setInputData] = useState<any>({
    email: "",
    password: "",
    showPassword: false,
    remember: false,
  });

  useEffect(() => {
    let rememberMe: any = localStorage.getItem("remember");
    if (rememberMe) {
      rememberMe = JSON.parse(rememberMe);
      let { remember } = rememberMe;
      if (remember) {
        setInputData(rememberMe);
      }
    }
  }, []);

  const handleLogin = async () => {
    dispatch(userAuthentication(true));
    let userEmail = String(process.env.REACT_APP_USER_EMAIL);
    let userPassword = process.env.REACT_APP_USER_PASSWORD;
    let { email, password } = inputData;
    if (email.trim() === userEmail && password.trim() === userPassword) {
      setSuccessAlert(true);
      localStorage.setItem("userCredentials", JSON.stringify(inputData));
      localStorage.setItem("remember", JSON.stringify(inputData));
      setTimeout(() => {
        setSuccessAlert(false);
        history.push(`/home`);
        window.location.reload();
      }, 2000);
    } else {
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2000);
    }
  };

  const handleClickShowPassword = () => {
    setInputData({ ...inputData, showPassword: !inputData.showPassword });
  };
  const renderSnackbar = (message, visible, type) => {
    if (visible) {
      return <SnackbarAlert message={message} isOpen={visible} type={type} />;
    }
  };

  const onSubmit = () => {
    handleLogin();
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Please Enter Email Address!"),
    password: Yup.string().required("Please Enter Password!"),
  });
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const handleInput = (field, value) => {
    setInputData((prval) => {
      return {
        ...prval,
        [field]: value,
      };
    });
  };

  const handleRemember = (e) => {
    setInputData({ ...inputData, remember: e.target.checked });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {renderSnackbar("Login Successfully", successAlert, "success")}
      {renderSnackbar("Invalid Username or password", errorAlert, "error")}

      <Grid container justify="center" className={classes.image}>
        <Grid item xs={12} sm={8} md={5} component={Paper} direction="row" elevation={6} square>
          <Grid className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <div className="form-control">
                <CustomInput
                  field="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoFocus
                  handleState={(field: any, value: any) => {
                    handleInput(field, value);
                  }}
                  error={formik.errors.email}
                  helperText={formik.errors.email}
                  value={formik.values.email}
                />
              </div>
              <div className="form-control">
                <CustomInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  field="password"
                  name="password"
                  label="Password"
                  type={inputData.showPassword ? "text" : "password"}
                  id="password"
                  handleState={(field: any, value: any) => {
                    handleInput(field, value);
                  }}
                  error={formik.errors.password}
                  helperText={formik.errors.password}
                  value={inputData.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {inputData.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <FormControlLabel
                control={<Checkbox value={inputData.remember} color="primary" onChange={(e) => handleRemember(e)} />}
                label="Remember me"
              />

              <SignInBtn className={classes.submit} onClick={formik.handleSubmit}>
                Sign In
              </SignInBtn>

              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SignIn;
