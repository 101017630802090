import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLabel from "../../../Components/Label/CustomLabel";
import FormLoader from "../../../Components/Loader/formLoader";
import { apiEndPoint } from "../../../constant/ApiEndPoint";
import { getCourseSectionArtifact } from "../../../redux/action/courseAction";

interface Props {
  tdcartifactId?: any;
  courseSectionId?: any;
  activeIndex?: any;
  courseReducer?: any;
  loaderReducer?: any;
}

const ImagePreview: React.FC<Props> = ({ tdcartifactId, courseSectionId, activeIndex }) => {
  const dispatch = useDispatch();
  const { artifactFile } = useSelector((state: Props) => state.courseReducer);
  const { ArtifactLoader } = useSelector((state: Props) => state.loaderReducer);

  useEffect(() => {
    if (tdcartifactId) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT}=${tdcartifactId}`;
      dispatch(getCourseSectionArtifact(uri));
    }
  }, [tdcartifactId, courseSectionId, dispatch]);

  return (
    <div>
      <CustomLabel label="Attached File:" />
      <img key={activeIndex} src={artifactFile} alt="" height="300" width="100%" style={{ marginTop: 3 }} />
      <FormLoader visible={ArtifactLoader} />
    </div>
  );
};

export default ImagePreview;
