import { reduxLoader } from "./../../constant/Redux";

const initialState = {
  courseLoader: false,
  contentLoader: false,
  newsLoader: false,
  blogLoader: false,
  questionTagLoader: false,
  searchLoader: false,
  facultyLoader: false,
  formLoader: false,
  courseMediaLoader: false,
  sectionLoader: false,
  ArtifactLoader: false,
  questionLoader: false,
  bundleLoader: false,
  userLoader: false,
  invoiceLoader:false,
};

export default function loaderReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxLoader.COURSE_LOADER:
      return {
        ...state,
        courseLoader: action.payload,
      };

    case reduxLoader.USER_LOADER:
      return {
        ...state,
        userLoader: action.payload,
      };

    case reduxLoader.CONTENT_LOADER:
      return {
        ...state,
        contentLoader: action.payload,
      };
      case reduxLoader.OFFER_BANNER_LOADER:
        return {
          ...state,
          invoiceLoader: action.payload,
        };
    case reduxLoader.NEWS_LOADER:
      return {
        ...state,
        newsLoader: action.payload,
      };
    case reduxLoader.BUNDLE_LOADER:
      return {
        ...state,
        bundleLoader: action.payload,
      };
    case reduxLoader.BLOG_LOADER:
      return {
        ...state,
        blogLoader: action.payload,
      };

    case reduxLoader.QUESTION_TAG_LOADER:
      return {
        ...state,
        questionTagLoader: action.payload,
      };

    case reduxLoader.FACULTY_LOADER:
      return {
        ...state,
        facultyLoader: action.payload,
      };
    case reduxLoader.PROMO_CODE_LOADER:
      return {
        ...state,
        promoCodeLoader: action.payload,
      };
    case reduxLoader.SEARCH_LOADER:
      return {
        ...state,
        formLoader: action.payload,
      };

    case reduxLoader.COURSE_MEDIA_LOADER:
      return {
        ...state,
        courseMediaLoader: action.payload,
      };

    case reduxLoader.FORM_LOADER:
      return {
        ...state,
        formLoader: action.payload,
      };
    case reduxLoader.SECTION_LOADER:
      return {
        ...state,
        sectionLoader: action.payload,
      };
    case reduxLoader.ARTIFACT_LOADER:
      return {
        ...state,
        ArtifactLoader: action.payload,
      };
    case reduxLoader.QUESTION_LOADER:
      return {
        ...state,
        questionLoader: action.payload,
      };
    default:
      return state;
  }
}
