import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
//import { apiEndPoint } from "../../constant/ApiEndPoint";
import { apiEndPoint } from "../../../constant/ApiEndPoint";
import { getQuestionTagAll } from "../../../redux/action/questionTagAction";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    border: "1px solid!important",
    "& th": {
      border: "1px solid!important",
      textAlign: "center",
    },
    "& td": {
      border: "1px solid!important",
      textAlign: "center",
    },
  },
  tblData: {
    "& th": {},
    "& td": {},
    "&:td:last-child": {
      borderRight: "none",
    },
  },
  tblHead: {
    "& th": {},
  },
}));

interface Props {
  questionList?: any;
  questionTagReducer?: any;
}

const QuestionTablePreview: React.FC<Props> = (props) => {
  const classes = useStyles();
  let { questionTags } = useSelector((state: Props) => state.questionTagReducer);
  const dispatch = useDispatch();

  let { questionList } = props;
  useEffect(() => {
    let uri = `${apiEndPoint.GET_QUESTION_TAG_ALL}`;
    dispatch(getQuestionTagAll(uri));
  }, [dispatch]);

  useEffect(() => {
    if (questionTags && questionTags.length > 0) {
      questionList?.forEach((element: any, index: any) => {
        questionTags?.forEach((currElem: any) => {
          if (currElem.questionTagId === element.questionTagId) {
            questionList[index] = {
              ...questionList[index],
              tagname: currElem.tagName,
            };
          }
        });
      });
    }
  });

  return (
    <>
      <Table className={classes.table} aria-label="caption table">
        <TableHead className={classes.tblHead}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell width="400">Questions</TableCell>
            <TableCell>Option A:</TableCell>
            <TableCell>Option B:</TableCell>
            <TableCell>Option C:</TableCell>
            <TableCell>Option D:</TableCell>
            <TableCell>Answer:</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tblData}>
          {questionList?.map((d, idx) => (
            <TableRow key={idx}>
              <TableCell>{d.questionIndex} </TableCell>

              <TableCell width="400">
                {d.questionDetails}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {d.tagname ? (
                    <div
                      style={{
                        backgroundColor: "#D1C4E9",
                        borderRadius: "15px",
                        color: "white",
                        width: "fit-content",
                        padding: "5px",
                      }}
                    >
                      {d.tagname}
                    </div>
                  ) : null}
                </div>
              </TableCell>

              <TableCell>{d.choiceA}</TableCell>
              <TableCell>{d.choiceB}</TableCell>
              <TableCell>{d.choiceC}</TableCell>
              <TableCell>{d.choiceD}</TableCell>
              <TableCell>{d.answer}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default QuestionTablePreview;
