import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AlertMessage from "../../Components/Alert/AlertMessage";
import { DivWrapper } from "../../Components/Card/Card";
import SearchInput from "../../Components/Input/SearchInput";
import CustomizedDialogs from "../../Components/Modal/NewModal";
import DataTable from "../../Components/Table/dataTable";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { enableHeaderTab, HeaderBreadCrumbTitle } from "../../redux/action/configAction";
import { deleteCourseById, getAllCourses, initCourse } from "../../redux/action/courseAction";
import FormLoader from "../../Components/Loader/formLoader";
let searchTimer;
const useStyles = makeStyles((theme) => ({
  ctmBtn: {
    color: "#fff!important",
    backgroundColor: "#2196f3!important",
  },
  root: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    borderWidth: 0,
    "& svg": {
      margin: theme.spacing(0),
    },
    "& hr": {
      margin: theme.spacing(0, 0),
    },
  },
}));

interface Props {
  url?: any;
  courseReducer?: any;
  loaderReducer?: any;
}

const CourseList: React.FC<Props> = (props) => {
  const [deleteId, setDeleteId] = useState(0);
  const classes = useStyles();
  let { url } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  let { courses, currentPage, totalItems } = useSelector((state: Props) => state.courseReducer);
  const { courseLoader, formLoader } = useSelector((state: any) => state.loaderReducer);

  if (courses && courses.length) {
    courses = courses.map((item) => {
      return { ...item, id: item.courseId };
    });
  }

  const getList = async (pageNumber, searchText) => {
    let uri = `${apiEndPoint.GET_ALL_COURSES}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText.trim()}%`],
          },
        ],
      };
    }
    await dispatch(getAllCourses(uri, args, searchText));
  };

  useEffect(() => {
    getList(currentPage, "");
  }, [currentPage]);

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length === 0) {
      getList(0, "");
    } else {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (value.length > 2) {
          getList(currentPage, value);
        }
      }, 500);
    }
  };
  const handleEdit = (selectedItem) => {
    handleInitcourse(true);
    let Title = selectedItem.title;
    dispatch(HeaderBreadCrumbTitle(Title));
    history.push(`${url}/${selectedItem.id}`);
  };

  const handlePage = (page) => {
    // setPage(page);
    getList(page, search);
  };

  const handleInitcourse = async (flag) => {
    await dispatch(initCourse());
    dispatch(enableHeaderTab(flag));
  };

  const handleDelete = (selectedItem) => {
    setDeleteId(selectedItem.id);
    setVisibleAlert(true);
  };

  const handleCancelAlert = () => {
    setVisibleAlert(false);
  };

  const handleConfirmAlert = () => {
    let uri = "URL_FOR_DELETE_COURSE_HERE";
    dispatch(deleteCourseById(uri, deleteId));
    setVisibleAlert(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "fullname",
      headerName: "Faculty Name",
      flex: 0.7,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.4,
    },
    {
      field: "levelName",
      headerName: "Level",
      flex: 0.5,
    },
  ];

  // let sliceData = courses.slice(10 * page);

  const renderTable = () => {
    return (
      <DataTable
        // page={page}
        page={currentPage}
        totalItems={totalItems}
        // totalItems={courses?.length ? courses.length : 0}
        data={courses}
        columns={columns}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handlePage={handlePage}
      />
    );
  };

  const renderAlert = () => {
    if (visibleAlert) {
      return (
        <AlertMessage
          title="Delete Course"
          message="Are you sure you want to delete this item?"
          visibleAlert={visibleAlert}
          handleCancelAlert={handleCancelAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
      );
    }
  };
  const renderNotFound = () => {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  };

  return (
    <>
      {courseLoader && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <FormLoader visible={courseLoader} />
        </div>
      )}
      {!courseLoader && (
        <DivWrapper>
          <FormLoader visible={formLoader} />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.root}
          >
            <h1>Courses</h1>
            <SearchInput handleSearch={handleSearch} search={search} />
            <CustomizedDialogs
              customeIcon={"add"}
              page={"course"}
              title={"Add New Course"}
              rowData={null}
              label="Course"
              style={{ color: "#fff!important", backgroundColor: "#2196f3!important" }}
              className={classes.ctmBtn}
            ></CustomizedDialogs>
          </div>

          <>
            {courses && courses.length >= 1 ? renderTable() : renderNotFound()}
            {renderAlert()}
          </>
        </DivWrapper>
      )}
    </>
  );
};

export default CourseList;
