import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AssessmentForm from "./AssessmentForm";
import FinalAssessment from "./FinalAssessmentPicker";

interface Props {
  data: any;
  handleSaveQuestion?: any;
  handleEditQuestion?: any;
  assessmentList?: any;
  handleSelectedAssessment?: any;
  scrollTop?: any;
}

const FinalAssessmentSection: React.FC<Props> = (props) => {
  let { data, scrollTop } = props;
  let { courseQuestionList } = data;
  const [assessmentList, setAssessmentList] = useState(courseQuestionList ? [...courseQuestionList] : []);
  const [showAssessment, setShowAssessment] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState(null);

  const uploadQuestionList = (csvList) => {
    let { courseSection } = data;
    let courseAssignmentId = courseSection?.assignmentId || 0;
    let newCourseQuestionList = assessmentList ? [...assessmentList] : [];
    let newData: any = [];
    newData = [...newCourseQuestionList, ...csvList];
    newData = newData.map((item, i) => {
      return { ...item, questionIndex: i + 1, courseAssignmentId };
    });
    setAssessmentList(newData);
    props.handleSelectedAssessment(newData);
  };

  const handleEditQuestion = (questionData) => {
    let { questionIndex } = questionData;
    let newCourseQuestion = assessmentList ? [...assessmentList] : [];
    let indexIteration = newCourseQuestion[0]?.questionIndex ? 1 : 0;
    newCourseQuestion[questionIndex - indexIteration] = {
      ...newCourseQuestion[questionIndex - indexIteration],
      ...questionData,
    };
    setAssessmentList(newCourseQuestion);
    props.handleSelectedAssessment(newCourseQuestion);
    setShowAssessment(false);
  };

  const handleSaveAssessment = (questionData) => {
    let { courseSection } = data;
    let newCourseQuestionList = assessmentList ? [...assessmentList] : [];
    let courseAssignmentId = courseSection?.assignmentId || 0;
    let newData: any = [];
    let item: any = {
      courseQuestionId: 0,
      courseAssignmentId: courseAssignmentId,
      questionIndex: newCourseQuestionList.length,
      questionDetails: questionData.questionDetails,
      choiceA: questionData.choiceA,
      choiceB: questionData.choiceB,
      choiceC: questionData.choiceC,
      choiceD: questionData.choiceD,
      answer: questionData.answer,
      //questionTagId: questionData.questionTag,
      questionTagId: questionData.questionTagId,
    };
    newData = [...newCourseQuestionList, { ...item }];
    setAssessmentList(newData);
    handleVisibleAssessmentForm();
    props.handleSelectedAssessment(newData);
  };

  const handleEditQuestionForm = (item) => {
    setEditedQuestion(item);
    setShowAssessment(true);
  };

  const handleVisibleAssessmentForm = () => {
    if (showAssessment) {
      setEditedQuestion(null);
    }
    setShowAssessment(!showAssessment);
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="tab">
        <div className="title flex jc-btwn" onClick={() => {}}>
          <div style={{ alignItems: "center", flex: 1, display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center" }}
              onClick={handleVisibleAssessmentForm}
            >
              <span style={{ cursor: "pointer" }} className={showAssessment ? "minus" : "plus"}>
                {showAssessment ? "-" : "+"}
              </span>
              <span style={{ marginLeft: 10, cursor: "pointer" }}>Questions</span>
            </div>
            <Button variant="outlined" color="primary" onClick={handleVisibleAssessmentForm}>
              Add Final Assessment
            </Button>
          </div>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "row" }}></div>
        </div>
        <div className={showAssessment ? "content show" : "content hide"}>
          <AssessmentForm
            handleSaveQuestion={handleSaveAssessment}
            handleEditQuestion={handleEditQuestion}
            handleCancelQuestion={handleVisibleAssessmentForm}
            editedQuestion={editedQuestion}
          />
        </div>
      </div>
      <FinalAssessment
        optionName={"Final Assessment"}
        assessmentList={assessmentList}
        handleEditQuestionForm={handleEditQuestionForm}
        uploadQuestionList={uploadQuestionList}
        scrollTop={scrollTop}
      />
    </div>
  );
};

export default FinalAssessmentSection;
