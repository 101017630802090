import React from "react";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  search: string;
  handleSearch: any;
}

const useStyles = makeStyles((theme) => ({
  srchfieldinput: {
    height: "40px",
    margin: "24px",
  },
  srchanim: {
    width: "50%",

    "&:hover": {
      width: "50%",
      transition: "width .4s linear",
    },
  },
}));

const SearchInput: React.FC<Props> = (props) => {
  const classes = useStyles();
  let { search } = props;

  const handleSearch = (e) => {
    props.handleSearch(e.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.srchanim}>
      <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        className={classes.srchfieldinput}
        value={search}
        onChange={(e) => handleSearch(e)}
        startAdornment={<SearchRounded />}
        labelWidth={50}
        placeholder="Search"
      />
    </FormControl>
  );
};

export default SearchInput;
