import React from "react";
import { useSelector } from "react-redux";

import CourseOverViewVideo from "./courseOverViewVideo";

interface componentNameProps {
  courseReducer?: any;
}

const OverViewVideo = (props: componentNameProps) => {
  let { detailCourse } = useSelector((state: componentNameProps) => state.courseReducer);

  let parentArtifactId = detailCourse && detailCourse.length > 0 ? detailCourse[0]?.tdcArtifact?.tdcartifactId : null;
  return <CourseOverViewVideo parentId={parentArtifactId}></CourseOverViewVideo>;
};

export default OverViewVideo;
