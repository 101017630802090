import { reduxConstants } from "../../constant/Redux";

const initialState = {
  questionTags: [],
  questionTag: null,
  questionTagObj: {},
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export default function questionTagReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.INIT_QUESTION_TAG:
      return {
        ...state,
        questionTag: null,
      };

    case reduxConstants.QUESTION_TAG_ALL:
      let currentTags = action.payload && action.payload.length > 0 ? action.payload : [];
      if (currentTags && currentTags.length > 0) {
        currentTags = currentTags.map((element: any) => {
          return {
            ...element,
            label: `${element.tagName} `,
            value: element.questionTagId,
          };
        });
      }
      let tagObj = {};
      currentTags.forEach((item: any) => {
        tagObj = { ...tagObj, [item.tagName]: item.questionTagId };
      });
      return {
        ...state,
        questionTags: currentTags,
        questionTagObj: tagObj,
      };

    case reduxConstants.ALL_QUESTION_TAG_LIST: {
      return {
        ...state,
        currentPage: action.payload[0]?.currentPage,
        questionTags: action.payload[0]?.dtoResults,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };
    }
    case reduxConstants.SAVE_QUESTION_TAG:
      return {
        ...state,
        questionTags: [...state.questionTags, ...action.payload],
      };
    case reduxConstants.QUESTION_TAG_BY_ID:
      return {
        ...state,
        questionTag: action.payload,
      };

    case reduxConstants.DELETE_QUESTION_TAG: {
      let { questionTags }: any = state;
      let newQuestionTags: any = [];
      questionTags.forEach((item, i) => {
        if (item.questionTagId !== action.payload.questionTagId) {
          newQuestionTags = [...newQuestionTags, item];
        }
      });
      return {
        ...state,
        questionTag: action.payload[0],
        questionTags: newQuestionTags,
      };
    }
    default:
      return state;
  }
}
