import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SignIn from "./Page/Login/SignIn";
import store from "./redux/store";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import AppRouter from "./router/index";
import SnackbarProvider from "./Components/Snackbar/SnackarbarProvider";

interface Props {
  authReducer?: any;
}
const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const App: React.FC<Props> = (Props) => {
  const { isLogIn } = useSelector((state: any) => state.authReducer);

  const homeRoutes = () => {
    return (
      <Provider store={store}>
        <SnackbarProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router>
              <AppRouter />
            </Router>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </Provider>
    );
  };

  let loginRoutes = () => {
    return (
      <>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router>
              <Switch>
                <Route exact path="/login">
                  <SignIn />
                </Route>
                <Route exact path="/">
                  <SignIn />
                </Route>
                <Route exact path="/home">
                  <SignIn />
                </Route>
                <Redirect to="/" />
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </Provider>
      </>
    );
  };

  let userCredentials = localStorage.getItem("userCredentials");
  return <>{userCredentials && isLogIn ? homeRoutes() : loginRoutes()}</>;
};
export default AppWrapper;
