import { CircularProgress, Dialog, DialogTitle } from "@material-ui/core";

const QuestionLoader = ({ visible }) => {
  if (visible) {
    return (
      <Dialog open={true}>
        <DialogTitle style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
          Questions are uploading... Please Don't Press Any Key!
          <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
            <div className="spinner-border" role="status">
              <CircularProgress />
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    );
  }
  return <></>;
};

export default QuestionLoader;
