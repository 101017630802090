import React, { useState } from "react";
import { Button } from "@material-ui/core";
import QuestionForm from "./QuestionForm";
import ExerciseCsvPicker from "./ExerciseCsvPicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Props {
  id?: number;
  active?: number;
  data: any;
  handleSaveQuestion?: any;
  handleEditQuestion?: any;
  questionList?: any;
  handleSelectedQuestions?: any;
  scrollTop?: any;
}

const ExerciseSection: React.FC<Props> = (props) => {
  let { data, scrollTop } = props;
  let { courseQuestionList } = data;
  const [showQuestion, setShowQuestion] = useState(false);
  const [questionList, setQuestionList] = useState(courseQuestionList ? [...courseQuestionList] : []);
  const [editedQuestion, setEditedQuestion] = useState(null);

  const handleVisibleQuestionForm = () => {
    if (showQuestion) {
      setEditedQuestion(null);
    }
    setShowQuestion(!showQuestion);
  };
  const handleEditQuestionForm = (item) => {
    setEditedQuestion(item);
    setShowQuestion(true);
  };

  const uploadQuestionList = (csvList) => {
    let { courseSection } = data;
    let courseAssignmentId = courseSection?.assignmentId || 0;
    let newCourseQuestionList = questionList ? [...questionList] : [];
    let newData: any = [];
    newData = [...newCourseQuestionList, ...csvList];
    newData = newData.map((item, i) => {
      return { ...item, questionIndex: i + 1, courseAssignmentId };
    });
    setQuestionList(newData);
    props.handleSelectedQuestions(newData);
  };

  const handleSaveQuestion = (questionData) => {
    let { courseSection } = data;
    let newCourseQuestionList = questionList ? [...questionList] : [];
    let courseAssignmentId = courseSection?.assignmentId || 0;

    let newData: any = [];
    let item: any = {
      courseQuestionId: 0,
      courseAssignmentId: courseAssignmentId,
      questionIndex: newCourseQuestionList.length,
      questionDetails: questionData.questionDetails,
      choiceA: questionData.choiceA,
      choiceB: questionData.choiceB,
      choiceC: questionData.choiceC,
      choiceD: questionData.choiceD,
      answer: questionData.answer,
    };
    newData = [...newCourseQuestionList, { ...item }];
    setQuestionList(newData);
    props.handleSelectedQuestions(newData);
    handleVisibleQuestionForm();
  };

  const handleEditQuestion = (questionData) => {
    let { questionIndex } = questionData;
    let newCourseQuestion = questionList ? [...questionList] : [];
    let indexIteration = newCourseQuestion[0]?.questionIndex ? 1 : 0;
    newCourseQuestion[questionIndex - indexIteration] = {
      ...newCourseQuestion[questionIndex - indexIteration],
      ...questionData,
    };
    setQuestionList(newCourseQuestion);
    props.handleSelectedQuestions(newCourseQuestion);
    handleVisibleQuestionForm();
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="tab">
        <div className="title flex jc-btwn" onClick={() => {}}>
          <div style={{ alignItems: "center", display: "flex" }}>
            <span style={{ marginLeft: 10, cursor: "pointer" }}>Questions</span>
          </div>
          <div
            style={{ alignItems: "center", display: "flex", flexDirection: "row" }}
            onClick={handleVisibleQuestionForm}
          >
            <Button variant="outlined" color="primary" onClick={handleVisibleQuestionForm}>
              Add Question
            </Button>
            {showQuestion ? (
              <span style={{ cursor: "pointer", paddingLeft: "15px", paddingRight: "6px" }} className={"minus"}>
                <ArrowDropUpIcon></ArrowDropUpIcon>
              </span>
            ) : (
              <span style={{ cursor: "pointer", paddingLeft: "15px", paddingRight: "6px" }} className={"plus"}>
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </span>
            )}
          </div>
        </div>

        <div className={showQuestion ? "content show" : "content hide"}>
          <QuestionForm
            handleSaveQuestion={handleSaveQuestion}
            handleEditQuestion={handleEditQuestion}
            handleCancelQuestion={handleVisibleQuestionForm}
            editedQuestion={editedQuestion}
          />
        </div>
      </div>

      <ExerciseCsvPicker
        optionName={"excise"}
        questionList={questionList}
        handleEditQuestionForm={handleEditQuestionForm}
        uploadQuestionList={uploadQuestionList}
        scrollTop={scrollTop}
      />
    </div>
  );
};

export default ExerciseSection;
