import { combineReducers } from "redux";
import questionTagReducer from "./questionTagReducer";
import courseReducer from "./courseReducer";
import referralCodeReducer from "./referralCodeReducer";
import newsReducer from "./newsReducer";
import loaderReducer from "./loaderReducer";
import blogReducer from "./blogReducer";
import userReducer from "./userReducer";
import facultyReducer from "./facultyReducer";
import promoCodeReducer from "./promoCodeReducer";
import configReducer from "./configReducer";
import authReducer from "./authReducer";
import invoiceReducer from "./invoiceReducer";

export default combineReducers({
  referralCodeReducer,
  courseReducer,
  questionTagReducer,
  loaderReducer,
  newsReducer,
  blogReducer,
  facultyReducer,
  promoCodeReducer,
  configReducer,
  authReducer,
  userReducer,
  invoiceReducer,
});
